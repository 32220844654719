import { Button, Divider, HStack, Image, Spacer, Stack, Text } from '@chakra-ui/react'
import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { formatFrice } from '../../Utils/numberUtil'





function OrdersListDetailPage() {

  const param = useLocation()

  const navigate = useNavigate()


  const [linkRedirect, setLinkRedirect] = useState("")

  const dataOrders = param?.state



  const handlePay = () => {
    setLinkRedirect(dataOrders.ipaymu_details.payment_url,)
    window.open(dataOrders.ipaymu_details.payment_url, "_blank")
  }


  return (
    <Stack p={[1, 1, 5]}>


      <Stack gap={5}>
        <Stack shadow={'md'} p={[2, 2, 5]} borderRadius='md'>
          <Text fontSize={'lg'} fontWeight='bold'>Kontak Informasi</Text>
          <Divider />
          <Stack spacing={4}>
            <HStack>
              <Text fontSize={'sm'} color='gray.600'>Penerima: </Text>
              <Spacer />
              <Text fontSize={'sm'} color='gray.800' fontWeight={500}>{dataOrders?.contact_information?.email} </Text>
            </HStack>

            <HStack>
              <Text fontSize={'sm'} color='gray.600'>Nomor Telepon: </Text>
              <Spacer />
              <Text fontSize={'sm'} color='gray.800' fontWeight={500}>{dataOrders?.contact_information?.phone_number} </Text>
            </HStack>

            <HStack alignItems={'flex-start'}>
              <Text fontSize={'sm'} color='gray.600'>Alamat: </Text>
              <Spacer />
              <Stack>
                <Text fontSize={'sm'} color='gray.800' fontWeight={500} textAlign={'end'} textTransform={'capitalize'}>{dataOrders?.contact_information?.address?.street},  {dataOrders?.contact_information?.address?.city}, {dataOrders?.contact_information?.address?.state} </Text>
                <Text fontSize={'sm'} color='gray.800' fontWeight={500} textAlign={'end'} textTransform={'capitalize'}>{dataOrders?.contact_information?.address?.country},  {dataOrders?.contact_information?.address?.postal_code}</Text>
              </Stack>
            </HStack>

          </Stack>
        </Stack>

        <Stack shadow={'md'} p={[2, 2, 5]} borderRadius='md'>
          <Text fontSize={'lg'} fontWeight='bold'>Item</Text>
          <Divider />
          <Stack spacing={4}>
            {dataOrders?.transactions?.length > 0 && (
              dataOrders?.transactions?.map((x, index) => {
                return (
                  <Stack key={index} onClick={() => navigate(`/product/${x?.reseller_product}`)}>
                    <HStack spacing={5} bgColor='white' shadow={'md'} p={2} borderRadius='md'>
                      <Image src={x?.variant?.images[0] || 'https://picsum.photos/300/200'} w={'200px'} h='100px' objectFit={'cover'} borderRadius='md' />
                      <Stack>
                        <Text fontWeight={500} fontSize='sm' textTransform={'capitalize'}>{x?.variant?.title}</Text>
                        <Text fontSize={'sm'} color='gray.700'>Quantity : {x?.quantity}</Text>
                        <Text fontWeight={'bold'}>Rp. {formatFrice(parseFloat(x?.variant?.price || 0))}</Text>
                      </Stack>
                    </HStack>
                  </Stack>
                )
              })
            )}
          </Stack>
        </Stack>

        <Stack shadow={'md'} p={[2, 2, 5]} borderRadius='md'>
          <Text fontSize={'lg'} fontWeight='bold'>Detail</Text>
          <Divider />
          <Stack spacing={5}>
            <HStack>
              <Text fontSize={'sm'} color='gray.600'>Status Pembayaran: </Text>
              <Spacer />
              <Text fontSize={'sm'} color='gray.800' fontWeight={500}>{dataOrders?.payment_status} </Text>
            </HStack>

            <HStack>
              <Text fontSize={'sm'} color='gray.600'>Total Pembayaran: </Text>
              <Spacer />
              <Text fontSize={'sm'} color='gray.800' fontWeight={500}>Rp.{formatFrice(parseFloat(dataOrders?.total_amount || 0))} </Text>
            </HStack>

            {linkRedirect !== "" && (
              <HStack>
                <Text color={'red'} fontSize='sm'>Jika kamu tidak berpindah ke halaman pembayaran, </Text>
                <Text color='red' fontSize={"sm"} fontStyle='italic' fontWeight={"bold"} onClick={() => window.open(linkRedirect, "_blank")} cursor='pointer'>Klik Disini.</Text>
              </HStack>
            )}

            {dataOrders?.payment_status === "Pending" && (
              <HStack alignItems={'flex-end'} justifyContent='flex-end'>
                <Button onClick={() => handlePay()} size={'sm'} colorScheme='red'>Bayar Sekarang</Button>
              </HStack>
            )}



          </Stack>
        </Stack>


      </Stack>
    </Stack>
  )
}

export default OrdersListDetailPage