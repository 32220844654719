import { Button, HStack, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid, Spacer, Stack, Text, Textarea, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import axiosInstanceBarier from '../../Apis/axiosWithBarier';
import { UseAuthStore } from '../../Hooks/Zustand/store';
import RegionSelect from '../Select/RegionSelect';
import CardAddress from './CardAddress';
import Region from '../../Config/Json/Regencies.json'
import Provinsi from '../../Config/Json/Provinsi.json'
import ProvinceSelect from '../Select/ProvinceSelect';

function AddressIndex() {

    const globalState = UseAuthStore();


    const [dataAddress, setDataAddress] = useState([])
    const [modalAddress, setModalAddress] = useState(false)

    const [actionAddress, setActionAddress] = useState("")

    const toast = useToast()

    const [loading, setLoading] = useState(false)
    const [noData, setNoData] = useState(false);
    const [noMainAddress, setNoMainAddress] = useState(false);

    const [formData, setFormData] = useState({
        email: globalState?.user?.email,
        name: "",
        label: "",
        phone_number: "",
        address: {
            street: "",
            city: "",
            state: "",
            postal_code: "",
            country: ""
        }
    });

    const handleSubmit = async () => {
        setActionAddress("")

        setLoading(true);
        // You can submit your form data or perform any other action here.

        try {
            if (actionAddress === "") {
                await axiosInstanceBarier.post("/address/create", formData)
                toast({
                    title: 'dapatkomisi.com',
                    description: "success add new address",
                    status: 'success',
                    position:'top'

                });
            } else {
                await axiosInstanceBarier.put(`/address/update?address_id=${actionAddress}`, formData)

                toast({
                    title: 'dapatkomisi.com',
                    description: "success add new address",
                    status: 'success',
                    position:'top'

                });

            }
           

        } catch (error) {
            toast({
                title: 'DapetKomisi',
                description: error?.response?.data?.message,
                status: 'error',
                isClosable: true,
                position:'top'

            });
            console.log(error)
        } finally {
            setLoading(false);
            setModalAddress(false)
            getDataAddress()
            setFormData({
                email: globalState?.user?.email,
                name: "",
                label: "",
                phone_number: "",
                address: {
                    street: "",
                    city: "",
                    state: "",
                    postal_code: "",
                    country: ""
                }
            })
            setActionAddress("")
        }
    }

    const handleMainAddress = async (data) => {


        if(data?.address === undefined){
            return handleEditAddress(data)
        }

        try {
            await axiosInstanceBarier.post(`/address/set-main-address?address_id=${data._id}`)
            toast({
                title: 'dapatkomisi.com',
                description: "success add main address",
                status: 'success',
                position:'top'

            });
        } catch (error) {
            console.log(error, 'ini error')
            toast({
                title: 'DapetKomisi',
                description: error?.response?.data?.message,
                status: 'error',
                isClosable: true,
                position:'top'

            });
        } finally {
            getDataAddress()
        }
    }


    const getDataAddress = async () => {
        try {
            const res = await axiosInstanceBarier.get("/address");
            setDataAddress(res?.data)

            if (res?.data?.length === 0) {
                setNoData(true);
            } else {
                setNoData(false);
            }

            // Check if there's no main address
            const hasMainAddress = res?.data.some((address) => address.is_main_address);
            if (!hasMainAddress) {
                setNoMainAddress(true);
            } else {
                setNoMainAddress(false);
            }


        } catch (error) {
            console.log(error, 'ini error')
        }
    }

    const handleOpenModalAddress = () => {
        setModalAddress(true)
    }

    const handleCloseModalAddress = () => {
        setModalAddress(false)
        setFormData({
            email: globalState?.user?.email,
            name: "",
            label: "",
            phone_number: "",
            address: {
                street: "",
                city: "",
                state: "",
                postal_code: "",
                country: ""
            }
        })
        setActionAddress("")
    }

    useEffect(() => {
        getDataAddress()

        if (noData) {
            handleOpenModalAddress();
        }

        return () => {
            setDataAddress([])
        }
    }, [])

    const handleDeleteAddress = async (data) => {

        try {
            await axiosInstanceBarier.delete(`/address/delete?address_id=${data._id}`)
            toast({
                title: 'dapatkomisi.com',
                description: "success delete address",
                status: 'success',
                position:'top'

            });
        } catch (error) {
            console.log(error, 'ini error')
            toast({
                title: 'DapetKomisi',
                description: error?.response?.data?.message,
                status: 'error',
                isClosable: true,
                position:'top'

            });
        } finally {
            getDataAddress()
        }
    }

    const handleEditAddress = (data) => {
        setActionAddress(data._id)
        handleOpenModalAddress()
        setFormData({
            email: globalState?.user?.email,
            name: data?.name,
            label: data?.label,
            phone_number: data?.phone_number,
            address: {
                street: data?.address?.street,
                city: data?.address?.city,
                state: data?.address?.state,
                postal_code: data?.address?.postal_code,
                country: data?.address?.country
            }
        });
    }


    return (
        <Stack p={[2, 2, 5]} spacing={4}>
            <HStack>
                <Text fontSize={'lg'} fontWeight='bold'>Address</Text>
                <Spacer />
                <Button size={"sm"} onClick={handleOpenModalAddress} colorScheme="red">Create new Address</Button>
            </HStack>

            {noData && (
                <Text color={"red.500"} fontWeight={500} fontSize="sm">
                    You don't have any address in your profile. Please add an address.
                </Text>
            )}

            {noMainAddress && !noData && (
                <Text color={"red.500"} fontWeight={500} fontSize="sm">
                    You haven't set a main address. Please set a main address by clicking the checklist box icon on an address.
                </Text>
            )}

            <Stack overflowY={"scroll"} h="180px">
                {dataAddress.length > 0 ? (
                    <SimpleGrid columns={[1, 2, 2]} gap={3}>
                        {dataAddress.map((x, index) => {
                            return (
                                <Stack key={index} shadow="md" borderWidth={1} borderRadius="md" borderColor={x.is_main_address ? "red.400" : "transparent"}>
                                    <CardAddress data={x} handleMainAddress={() => handleMainAddress(x)} handleDeleteAddress={() => handleDeleteAddress(x)} handleEditAddress={() => handleEditAddress(x)} />
                                </Stack>
                            )
                        })}
                    </SimpleGrid>
                ) : null}
            </Stack>

            <Modal isOpen={modalAddress} onClose={handleCloseModalAddress} size={"xl"}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Setting Address</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Stack>
                            <SimpleGrid columns={[1, 2, 2]} gap={3}>
                                <Stack>
                                    <Text fontWeight={500} fontSize="sm" color={"gray.700"}>Receiver </Text>
                                    <Input
                                        placeholder="Full Name"
                                        value={formData.name}
                                        onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                    />
                                </Stack>

                                <Stack>
                                    <Text fontWeight={500} fontSize="sm" color={"gray.700"}>Label Address </Text>
                                    <Input
                                        placeholder="ex: home / apartement"
                                        value={formData.label}
                                        onChange={(e) => setFormData({ ...formData, label: e.target.value })}
                                    />
                                </Stack>

                                <Stack>
                                    <Text fontWeight={500} fontSize="sm" color={"gray.700"}>Phone Number Receiver </Text>
                                    <Input
                                        placeholder="ex: 085256123456"
                                        value={formData.phone_number}
                                        onChange={(e) => setFormData({ ...formData, phone_number: e.target.value })}
                                    />
                                </Stack>



                                <Stack>
                                    <Text fontWeight={500} fontSize="sm" color={"gray.700"}>City Address</Text>
                                    <RegionSelect formData={formData} setFormData={setFormData} Provinsi={Region} />

                                </Stack>

                                <Stack>
                                    <Text fontWeight={500} fontSize="sm" color={"gray.700"}>State Address</Text>
                                    <ProvinceSelect formData={formData} setFormData={setFormData} Provinsi={Provinsi} />

                                </Stack>

                                <Stack>
                                    <Text fontWeight={500} fontSize="sm" color={"gray.700"}>Country</Text>
                                    <Input
                                        placeholder="Ex: Indonesia"
                                        value={formData.address.country}
                                        onChange={(e) => setFormData({ ...formData, address: { ...formData.address, country: e.target.value } })}
                                    />
                                </Stack>


                                <Stack>
                                    <Text fontWeight={500} fontSize="sm" color={"gray.700"}>Street Address</Text>
                                    <Textarea
                                        placeholder="ex: Jl. Gardu"
                                        value={formData.address.street}
                                        onChange={(e) => setFormData({ ...formData, address: { ...formData.address, street: e.target.value } })}
                                    />
                                </Stack>



                                <Stack>
                                    <Text fontWeight={500} fontSize="sm" color={"gray.700"}>Postal Code</Text>
                                    <Input
                                        placeholder="Postal Code"
                                        value={formData.address.postal_code}
                                        onChange={(e) => setFormData({ ...formData, address: { ...formData.address, postal_code: e.target.value } })}
                                    />
                                </Stack>



                            </SimpleGrid>

                        </Stack>
                    </ModalBody>
                    <ModalFooter>
                        <HStack>
                            <Button colorScheme={"red"} onClick={handleCloseModalAddress}>Cancel</Button>
                            <Button
                                colorScheme="green"
                                onClick={handleSubmit}
                                isLoading={loading}
                                loadingText="Submitting"
                            >
                                Submit
                            </Button>
                        </HStack>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Stack >
    )
}

export default AddressIndex