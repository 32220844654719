import {
	AspectRatio,
	Box,
	Circle,
	HStack,
	IconButton,
	Image,
	Skeleton,
	useColorModeValue,
} from '@chakra-ui/react'
import { useState } from 'react'
import { IoChevronBackOutline, IoChevronForwardOutline } from 'react-icons/io5'
import { Link } from 'react-router-dom'
import { Carousel, CarouselSlide, useCarousel } from './Carousel'

export const Gallery = (props) => {
	// const { images, aspectRatio = 1 } = props
	const { images, aspectRatio } = props
	const [currentSlide, setCurrentSlide] = useState(0)
	const [ref, slider] = useCarousel({
		loop: true,
		duration: 1000,
		slideChanged: (slider) => setCurrentSlide(slider.track.details.rel),
	})
	const [isHovered, setIsHovered] = useState(false);
	const [position, setPosition] = useState({ x: 0, y: 0 });
  
	const handleMouseMove = (e) => {
	  setPosition({ x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY });
	};
  
	const handleMouseEnter = () => {
	  setIsHovered(true);
	};
  
	const handleMouseLeave = () => {
	  setIsHovered(false);
	};
  

	const hasPrevious = currentSlide !== 0
	const hasNext = currentSlide < images.length - 1
	return (
		// <Stack spacing="4" {...rootProps}>
		<Box
			bgColor='brand.1'
			w='full'
			position="relative"
			sx={{
				_hover: {
					'> button': {
						display: 'inline-flex',
					},
				},
			}}
		>
			<Carousel ref={ref} >
				{images?.map((image, i) => (
					 <Link to={image?.link || ''} key={i}>
					 <div
					   style={{ position: 'relative', display: 'inline-block' }}
					   onMouseEnter={handleMouseEnter}
					   onMouseLeave={handleMouseLeave}
					   onMouseMove={handleMouseMove}
					 >
					   <CarouselSlide>
						 <AspectRatio
						   ratio={aspectRatio}
						   transition="all 200ms"
						   opacity={currentSlide === i ? 1 : 0.4}
						   _hover={{
							 opacity: 1,
						   }}
						 >
						   <Image
							 src={image?.image ? image?.image : image}
							 objectFit="contain"
							 alt={'banner'}
							 fallback={<Skeleton />}
							 style={{
							   width: '100%',
							   height: '100%',
							   position: 'absolute',
							   top: 0,
							   left: 0,
							   transform: isHovered ? 'scale(1.5)' : 'scale(1)',
							   transformOrigin: `${position.x}px ${position.y}px`,
							   transition: 'transform 0.3s ease-in-out',
							 }}
						   />
						 </AspectRatio>
					   </CarouselSlide>
					   {isHovered && (
						 <div
						   style={{
							 position: 'absolute',
							 top: position.y,
							 left: position.x,
							 width: '200px', // Adjust the dimensions of the magnifying glass as needed
							 height: '200px',
							 border: '2px solid #ccc',
							 borderRadius: '50%',
							 pointerEvents: 'none',
							 transform: 'translate(-50%, -50%)',
							 cursor: 'zoom-in', // Set the cursor to a zoom-in icon
						   }}
						 ></div>
					   )}
					 </div>
				   </Link>

				))}
			</Carousel>
			{hasPrevious && (
				<CarouselIconButton
					pos="absolute"
					left="3"
					top="50%"
					transform="translateY(-50%)"
					onClick={() => slider.current?.prev()}
					icon={<IoChevronBackOutline />}
					aria-label="Previous Slide"
				/>
			)}

			{hasNext && (
				<CarouselIconButton
					pos="absolute"
					right="3"
					top="50%"
					transform="translateY(-50%)"
					onClick={() => slider.current?.next()}
					icon={<IoChevronForwardOutline />}
					aria-label="Next Slide"
				/>
			)}
			<HStack position="absolute" width="full" justify="center" bottom="0" py="4">
				{images.map((_, index) => (
					<Circle key={index} size="2" bg={currentSlide === index ? 'white' : 'whiteAlpha.400'} />
				))}
			</HStack>
		</Box>
		// </Stack>
	)
}
const CarouselIconButton = (props) => (
	<IconButton
		display="none"
		fontSize="lg"
		isRound
		boxShadow="base"
		bg={useColorModeValue('white', 'gray.800')}
		_hover={{
			bg: useColorModeValue('gray.100', 'gray.700'),
		}}
		_active={{
			bg: useColorModeValue('gray.200', 'gray.600'),
		}}
		_focus={{
			boxShadow: 'inerhit',
		}}
		_focusVisible={{
			boxShadow: 'outline',
		}}
		{...props}
	/>
)