/* eslint-disable react-hooks/exhaustive-deps */
import { useSearchParams } from 'react-router-dom'
import { Avatar, Button, Divider, Grid, Heading, HStack, Image, Input, SimpleGrid, Spacer, Stack, Text, useNumberInput, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { CarouselComponent } from '../../Components/Gallery/CarouselComponent'
import { formatFrice } from '../../Utils/numberUtil'
import { FaRegMoneyBillAlt } from 'react-icons/fa'
import axiosInstanceBarier from '../../Apis/axiosWithBarier'
import { UseAuthStore } from '../../Hooks/Zustand/store'
import ProductReccomended from '../../Components/Product/ProductReccomended'
import { AiFillStar } from 'react-icons/ai'

function AffiliatePage() {

    let [searchParams] = useSearchParams()
    const idAffiliate = searchParams.get("id")

    const param = useParams()

    const toast = useToast()
    const navigate = useNavigate()



    const [activeVariant, setActiveVariant] = useState({})
    const [reviewData, setReviewData] = useState([])






    const globalState = UseAuthStore();


    const [productData, setProductData] = useState({})

    const getProductDetail = async () => {
        globalState.setIsLoading(true)
        try {
            const res = await axiosInstanceBarier(`/affiliate?affiliate_id=${idAffiliate}`)
            console.log(res, 'ini res')
            setProductData(res?.product)
            setActiveVariant(res?.product?.variants[0])
            setReviewData(res?.reviewed)
        } catch (error) {
            console.log(error, 'ini error')
        }
        finally {
            globalState.setIsLoading(false)
        }
    }

    const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
        useNumberInput({
            min: 0,
            max: activeVariant?.stocks,
        })

    const inc = getIncrementButtonProps()
    const dec = getDecrementButtonProps()
    const input = getInputProps()

    const image_profile = [
        { id: "6566c368adcbf500398a32df", image: "https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/default%2FWhatsApp%20Image%202024-02-13%20at%2023.06.48.jpeg?alt=media&token=065b4b7c-7f20-463f-8e17-ae0a1fd5a3b7" },
        { id: "6581483c5ed71200b3c7a56d", image: "https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/WhatsApp%20Image%202023-12-19%20at%2014.58.21.jpeg?alt=media&token=2b76d43a-6f26-4c84-a5a7-95331958ed5a" },
      ]
    
    useEffect(() => {
        getProductDetail()

        return () => {
        }
    }, [param.id])



    const handleBuyNow = async () => {

        if (!activeVariant._id) {
          return toast({
            title: 'DapatKomisi',
            description: 'Silahkan pilih variant produk',
            status: 'warning',
            isClosable: true,
            duration: 9000,
            position: 'top'
    
          });
        }
    
    
        if (input?.value === '' || input?.value === 0 || input?.value === '0') {
          return toast({
            title: 'DapatKomisi',
            description: 'Silahkan masukan jumlah order produk',
            status: 'warning',
            isClosable: true,
            duration: 9000,
            position: 'top'
    
          });
        }
    
    
        const dataCart = {
          product: activeVariant,
          product_id: productData?._id,
          quantity: input?.value
        }
    
    
    
    
    
        navigate(`/orders-now?affiliateId=${idAffiliate}`, { state: dataCart })
      }


    return (
        <Stack p={[1, 1, 5]} w='full' spacing={4}>
        <SimpleGrid columns={[1, 1, 2]} gap={5} p={1}>
          <Stack>
            <CarouselComponent images={[activeVariant?.images]} />
            <HStack columns={[1, 1, 3]} gap={3} overflowX='auto' width={['350px', '400px', '500px']}>
              {productData?.variants?.map((x, index) => {
                return (
                  <Stack key={index} p={1} borderRadius='md' cursor={'pointer'} bgColor={activeVariant?._id === x._id ? 'gray.700' : 'gray.500'}  minW='150px' onClick={() => setActiveVariant(x)}>
                    <Stack alignItems={'center'} justifyContent='center'>
                      <Image src={x?.images || "https://picsum.photos/150/150"} w="150px" h={"150px"} objectFit="contain" />
                    </Stack>
                  </Stack>
                )
              })}
  
  
            </HStack>
          </Stack>
  
          <Stack alignItems={'flex-start'} justifyContent='flex-start' p={2} w={'full'}>
            <Stack spacing={3}>
              <HStack alignItems={'center'} justifyContent='space-between' spacing={4} >
                <Avatar size={'sm'} src={productData?.store?.image || image_profile.find((data) => data?._id === productData?.store?._id)?.image || 'https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/WhatsApp%20Image%202024-02-07%20at%2014.43.30.jpeg?alt=media&token=d23f664f-8996-4f0b-86de-cfc88422385a'} />
                <Text cursor={"pointer"} onClick={() => navigate(`/store/${productData?.store?._id}`, { state: productData?.store })} fontWeight={500} textTransform='capitalize'>{productData?.store?.name}</Text>
                <Spacer />
                {/* <Box>
                  <BsSuitHeart />
                </Box>
                <Box>
                  <BiShareAlt />
                </Box> */}
              </HStack>
  
              <Stack>
                <Text fontWeight={'bold'} fontSize="md" noOfLines={2} textTransform='capitalize'>{activeVariant?.title || "Silahkan memilih variant produk..."}</Text>
                {/* <Text fontSize={'sm'} color='gray.600'>{activeVariant?.description || "Description product"}</Text> */}
              </Stack>
  
              <Stack>
                <HStack>
                  <Heading fontWeight={"bold"} size='lg'>Rp. {formatFrice(activeVariant?.price || 0)}</Heading>
                  <Spacer />
                  <Text fontWeight={300} fontSize='sm'>Harga ini belum termasuk ongkir</Text>
  
                </HStack>
              </Stack>
  
              <Stack>
                <Text fontWeight={'bold'} fontSize='sm'>Varian produk</Text>
              </Stack>
  
              <Stack>
                {productData?.variants?.length > 0 && (
                  <Stack>
                    <HStack columns={[1, 1, 3]} gap={3} overflowX='scroll' width={['350px', '400px', '500px']}>
                      {productData?.variants?.map((x, index) => {
                        return (
                          <Stack p={2} maxW='200px' borderRadius='md' cursor={'pointer'} bgColor={activeVariant?._id === x?._id ? 'gray.700' : 'gray.500'} key={index} onClick={() => setActiveVariant(x)}>
                            <Stack alignItems={'center'} justifyContent='center'>
                              <Image src={x?.images || "https://picsum.photos/150/150"} w="150px" h={"150px"} objectFit="contain" />
                            </Stack>
                            <Divider />
                            <Stack spacing={0} w='170px'>
                              <Text fontSize={'sm'} color='white' fontWeight={500} textTransform='capitalize' noOfLines={1}>{x?.title}</Text>
                              <Text fontSize={'xs'} color='white' fontWeight={500} noOfLines={1}>{x?.description}</Text>
                              <Text fontSize={'xs'} color='white' fontWeight={500}>Rp.{formatFrice(parseFloat(x?.price || 0))}/Pcs</Text>
                              <Text fontSize={'xs'} color='white' fontWeight={500}>Stok Produk: {x?.stocks || 0}</Text>
                              <Text fontSize={'xs'} color='white' fontWeight={500}>Berat Produk: {x?.weight || 0}g</Text>
                            </Stack>
                          </Stack>
                        )
                      })}
  
  
                    </HStack>
  
                    <HStack spacing={3}>
                      <Button {...dec} colorScheme="red">-</Button>
                      <Input min={0} defaultValue={0} color="black" max={activeVariant?.stocks || 0}  {...input} />
                      <Button {...inc} colorScheme="red">+</Button>
                    </HStack>
                  </Stack>
                )}
  
  
              </Stack>
  
              <Stack>
                <SimpleGrid gap={3} columns={[1, 1, 2]}>
                
                  <Button onClick={() => handleBuyNow()} borderRadius='md' colorScheme={'red'} variant='solid' fontWeight={500} textTransform='capitalize'>
                    <HStack alignItems={'center'}>
                      <FaRegMoneyBillAlt />
                      <Text textTransform={'uppercase'} fontSize='sm' fontWeight={'bold'}>Beli sekarang</Text>
                    </HStack>
                  </Button>
                </SimpleGrid>
              </Stack>
            </Stack>
          </Stack>
        </SimpleGrid>
  
        <Stack p={[5]} bgColor='gray.200' borderRadius={'md'} spacing={5}>
          <Text fontWeight={'bold'}>Deskripsi</Text>
          {/* <Grid templateColumns={{ base: '1fr', md: '1fr 3fr' }}>
            <Text fontSize={'sm'} fontWeight={500}>Technical Details</Text>
            <Text fontSize={'sm'}>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Culpa consequuntur velit reiciendis architecto necessitatibus numquam non distinctio reprehenderit, explicabo, dignissimos veniam accusamus esse voluptas sit maxime, odio soluta molestiae temporibus.</Text>
          </Grid> */}
  
          <Grid templateColumns={{ base: '1fr', md: '1fr 3fr' }}>
            <Text fontSize={'sm'} fontWeight={500}>Deksripsi Produk</Text>
            <Text fontSize={'sm'}>{productData?.description || "Description product"}</Text>
          </Grid>
  
          <Grid templateColumns={{ base: '1fr', md: '1fr 3fr' }}>
            <Text fontSize={'sm'} fontWeight={500}>Lokasi Produk</Text>
            <Text fontSize={'sm'}>{productData?.store?.address?.address?.city || "Jakarta Selatan"}</Text>
          </Grid>
  
        
        </Stack>
  
        <Stack p={[5]} bgColor='gray.200' borderRadius={'md'} overflowY="scroll" h='300px'>
          <Text fontWeight={'bold'}>Review</Text>
          {reviewData?.length > 0 && (
            <SimpleGrid columns={[1, 1, 1]} gap={4}>
              {reviewData?.map((x, index) => {
                return (
                  <HStack spacing={4} key={index} bgColor={'white'} alignItems='flex-start' justifyContent={'flex-start'} p={4} h="100px" shadow="md" borderRadius={"md"}>
                    <Avatar size={"md"} src={x?.user?.email} name={x?.user?.name || "anonymous"} />
                    <Stack spacing={1}>
                      <HStack >
                        <Text textTransform={"capitalize"} fontWeight={500}>{x?.user?.name}</Text>
  
                      </HStack>
                      <Text noOfLines={2} fontSize="sm">{x?.description} </Text>
  
                    </Stack>
                    <Spacer />
                    <Stack>
                      <HStack>
                        <AiFillStar size={15} color='orange' />
                        <Text fontWeight={500} fontSize="xl">{x?.rating}</Text>
                      </HStack>
  
                    </Stack>
                  </HStack>
                )
              })}
  
            </SimpleGrid>
          )}
  
  
        </Stack>
  
        {productData?._id && (
        <ProductReccomended id={productData?._id} />

      )}
  
  
      </Stack>
    )
}

export default AffiliatePage