/* eslint-disable react-hooks/exhaustive-deps */
import { Avatar, AvatarGroup, Button, Grid, HStack, Heading, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spacer, Stack, Text, Tooltip } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import logopanjang from '../../Assets/Image/logokomisi.png'
import { UseAuthStore } from '../../Hooks/Zustand/store'
import axiosInstanceBarier from '../../Apis/axiosWithBarier'
import ResellerList from '../../Reseller/ResellerList'
import ProductStoreCard from '../../Components/Cards/ProductStoreCard'
import { FiArrowRightCircle } from 'react-icons/fi'



function ProdusenPage() {

    const navigate = useNavigate()
    const globalState = UseAuthStore();


    const [resellerList, setResellerList] = useState([])
    const [modalReseller, setModalReseller] = useState(false)



    let [searchParams] = useSearchParams()

    const idProdusen = searchParams.get("id")
    console.log(idProdusen, 'xxx')

    const [produsen, setProdusen] = useState({})
    const [product, setProduct] = useState([])

    const getProduct = async () => {
        globalState.setIsLoading(true)
        console.log('c')

        try {
            const res = await axiosInstanceBarier.get(`/store/recomended?type=produsen`);
            setProdusen(res?.store);
            setProduct(res?.product)
        } catch (error) {
            console.log(error, ' ini error')
        }
        finally {
            globalState.setIsLoading(false)
        }
    }

    const getProductNext = async () => {
        try {
            const res = await axiosInstanceBarier.get(`/store/recomended?type=produsen`);
            setProdusen(res?.store);
            setProduct(res?.product)
        } catch (error) {
            console.log(error, ' ini error')
        }
    }



    useEffect(() => {

        if(idProdusen !== null) return


        const intervalId = setInterval(() => {
            getProductNext();
        }, 20000);

        return () => clearInterval(intervalId);



    }, []);


    const getDataStore = async () => {
        globalState.setIsLoading(true)
        console.log('a')
        try {

            const resStore = await axiosInstanceBarier.get(`/store/detail?store_id=${idProdusen}`);
            setProdusen(resStore?.data)
        } catch (error) {
            console.log(error, ' ini error')
        }
        finally {
            globalState.setIsLoading(false)
        }
    }

    const getDataProduct = async () => {
        globalState.setIsLoading(true)
        console.log('b')

        try {
            const resProduct = await axiosInstanceBarier.get(`/product?store_id=${idProdusen}&produsen=true`);
            setProduct(resProduct?.data)
        } catch (error) {
            console.log(error, ' ini error')
        }
        finally {
            globalState.setIsLoading(false)
        }
    }

    useEffect(() => {

        if(idProdusen === undefined || idProdusen === null) {
            getProduct()

        }else{
            getDataStore()
            getDataProduct()
        }

        return () => {
            setProduct([])
        }
    }, [])

    const handleOpenModal = () => {
        setModalReseller(true)
    }

    const handleCloseModal = () => {
        setModalReseller(false)
    }

    const handleResellerList = async (product_id) => {
        try {
            const result = await axiosInstanceBarier.get(`/reseller?product_id=${product_id}`)
            setResellerList(result.reseller_store)
            handleOpenModal()
        } catch (error) {
            console.log(error, 'ini error')
        }

    }





    return (
        <Stack>

            <Stack alignItems={'center'} display={['none', 'flex', 'flex']} justifyContent='center' h={['60px', '80px', '100px']}>

                <Image onClick={() => navigate('/')} w={['200px', '150px', '250px']} cursor='pointer' src={logopanjang} />
            </Stack>

            <Stack alignItems={'center'} justifyContent='center' >



                <Stack w={'100%'}  py={5}>

                     <HStack >
                        <HStack>
                            <Tooltip label={produsen?.name} aria-label="Name">
                                <Avatar size={'md'} name={produsen?.name} src={produsen?.image || 'https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/default%2FWhatsApp%20Image%202024-02-13%20at%2023.06.48.jpeg?alt=media&token=065b4b7c-7f20-463f-8e17-ae0a1fd5a3b7'} />
                            </Tooltip>
                            <Heading size={'md'} textTransform='capitalize'>Produsen {produsen?.name}</Heading>
                        </HStack>
                        <Spacer />

                    </HStack>

                    <Grid templateColumns={{ base: '1fr', md: '1fr 2.5fr 0.1fr' }} gap={5}>
                        <Stack cursor={'pointer'} alignItems='start' justifyContent={'start'} >
                            <HStack w={'100%'} spacing={1} position='relative' alignItems={'center'} justifyContent={'center'}>
                                {produsen?.community?.length > 0 ? (
                                    <AvatarGroup
                                        cursor="pointer"
                                        size="sm"
                                        max={5}
                                        spacing={-2}
                                        position='absolute' top={2} left={2}
                                    >
                                        {produsen?.community.map((user, i) => {

                                            return (
                                                <Tooltip key={i} label={user?.name || user?.detail?.name} aria-label="Name">
                                                    <Avatar size={'md'} name={user?.name || user?.detail?.name} src={user?.image || user?.detail?.image || 'https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/default%2FWhatsApp%20Image%202024-02-13%20at%2022.59.30.jpeg?alt=media&token=30790f44-f8c6-4ef4-b7ad-e97749c5d6d3'} />
                                                </Tooltip>
                                            )

                                        })}
                                    </AvatarGroup>
                                )
                                    : (
                                        <Tooltip position='absolute' top={2} left={2} label={"admin"} aria-label="Name">
                                            <Avatar position='absolute' top={2} left={2} size={'md'} name={'admin'} src={'https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/default%2FWhatsApp%20Image%202024-02-13%20at%2022.59.30.jpeg?alt=media&token=30790f44-f8c6-4ef4-b7ad-e97749c5d6d3'} />
                                        </Tooltip>
                                    )
                                }


                                <Image onClick={() => navigate('/')} w='auto' h={['300px', '250px', '180px']} src={produsen?.image || 'https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/default%2FWhatsApp%20Image%202024-02-13%20at%2023.06.48.jpeg?alt=media&token=065b4b7c-7f20-463f-8e17-ae0a1fd5a3b7'} />


                            </HStack>



                            <Text fontSize='lg' fontWeight='500'>Kalau mau <b style={{ color: 'red' }}>Dapet komisi</b> ?</Text>
                            <Stack w={'100%'} spacing={1}>
                                <Button size={'sm'} colorScheme={'red'} onClick={() => navigate('/signup')}>Daftar Produsen</Button>

                                <Button size={'sm'} colorScheme={'red'} onClick={() => navigate('/signup')}>Produk Digital</Button>
                                <Button size={'sm'} colorScheme={'red'} onClick={() => navigate('/signup')}>Produk Non-Digital</Button>
                            </Stack>
                        </Stack>
                        <HStack gap={2} overflowX='scroll' maxW='100%'>
                            {product.length > 0 && (
                                product.map((x, index) => {
                                    return (
                                        <ProductStoreCard index={index} data={x} onClick={() => handleResellerList(x._id)} />
                                    )
                                })
                            )}
                        </HStack>

                        <Stack alignItems={"center"} py={2} justifyContent="center" bgColor={'red.600'} h={"auto"} display={["none", "flex", "flex"]}>
                            <FiArrowRightCircle size={17} color='white' />
                        </Stack>
                    </Grid>

                </Stack>
                <Modal isOpen={modalReseller} onClose={handleCloseModal} size='3xl'>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Daftar Reseller </ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            {resellerList?.length > 0 ? (
                                <ResellerList resellerList={resellerList} />

                            ) : (
                                <Stack>
                                    <Heading size={'md'}>Produk ini belum mempunyai reseller</Heading>
                                    <Text>Daftar sekarang untuk menjadi reseller, dengan klik tombol dibawah</Text>
                                </Stack>
                            )}
                        </ModalBody>

                        <ModalFooter>

                            <Button size={"sm"} colorScheme="red" mr={3} onClick={() => navigate('/signup')}>Daftar Reseller / Dropship</Button>
                            <Button size={"sm"} colorScheme="red" mr={3} onClick={handleCloseModal}>
                                Close
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>

            </Stack>


        </Stack>
    )
}

export default ProdusenPage