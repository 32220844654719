export function checkSignupData(dataAccount) {
  if (
    dataAccount.name === "" ||
    dataAccount.email === "" ||
    dataAccount.password === "" ||
    dataAccount.confirm_password === "" ||
    dataAccount.phone_number === ""
  )
    return {
      error: {
        title: "Warning",
        description: "Harap lengkapi data anda.",
        status: "warning",
      },
      success: false,
    };

  if (dataAccount.password !==  dataAccount.confirm_password)
    return {
      error: {
        title: "Warning",
        description: "Password dan confirm password kamu tidak sama, harap di periksa kembali.",
        status: "warning",
      },
      success: false,
    };

  return {
    success: true,
    error: null,
  };
}



export function checkLoginData(dataAccount) {
  if (
    dataAccount.email === "" ||
    dataAccount.password === ""
  )
    return {
      error: {
        title: "Warning",
        description: "Harap lengkapi data anda.",
        status: "warning",
      },
      success: false,
    };

  return {
    success: true,
    error: null,
  };
}





