/* eslint-disable jsx-a11y/iframe-has-title */
import { AspectRatio, Box, Button, Checkbox, Divider, Grid, HStack, Image, Input, Select, SimpleGrid, Spacer, Stack, Text, Textarea, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import axiosInstanceBarier from '../../Apis/axiosWithBarier';
import ProvinceSelect from '../../Components/Select/ProvinceSelect';
import { formatFrice } from '../../Utils/numberUtil'
import Provinsi from '../../Config/Json/Provinsi.json'
import Region from '../../Config/Json/Regencies.json'
import RegionSelect from '../../Components/Select/RegionSelect';

function OrdersNowPage() {

    const toast = useToast();
    const param = useLocation();
    const [data, setData] = useState({ items: [param?.state] } || {});


    const [itemData, setItemData] = useState(data?.items || []);
    const [priceProduct, setPriceProduct] = useState(0);
    const [priceTax, setPriceTax] = useState(0);
    const [priceInsurance, setPriceInsurance] = useState(0);
    const [priceShipment, setPriceShipment] = useState(0);
    const [loading, setLoading] = useState(false);
    const [linkRedirect, setLinkRedirect] = useState('');
    const [insuranceCheckboxes, setInsuranceCheckboxes] = useState([]);
    const [ urlLink, setUrlLink ] = useState('')
    let [searchParams, setSearchParams] = useSearchParams()


    const navigate = useNavigate()
    const idAffiliate = searchParams.get("affiliateId")


    useEffect(() => {
        handlePrice();

    }, [itemData]);



    const handleShipment = async (productId) => {
        setLoading(true)
        const dataShip = {
            contact_information: formData,
        }
        try {
            const res = await axiosInstanceBarier.post(`/shipping-cost?product_id=${productId}`, dataShip);
            return res.data
        } catch (error) {
            console.log(error, 'ini error');
            return null;
        } finally {
            setLoading(false)
        }
    }

    const updateShippingCosts = async () => {
        const updatedItems = [];

        for (let i = 0; i < data?.items?.length; i++) {
            const item = data?.items[i];
            const productId = item?.product?._id;
            const shippingInfo = await handleShipment(productId);

            if (shippingInfo === undefined) {
                return toast({
                    title: 'DapetKomisi',
                    description: 'kami tidak dapat mengenali alamat anda, silahkan isi alamat anda dengan benar.',
                    status: 'warning',
                    isClosable: true,
                    position: 'top'

                });
            }
            if (shippingInfo) {

               

                const foundItem = data?.items?.find((dataItem) => dataItem.product._id === productId);

                if (foundItem) {
                    const shippingOptions = shippingInfo.map((provider) => {
                        const services = provider.costs.map((service) => ({
                            name: service?.service || "",
                            cost: service?.cost[0]?.value || 0,
                            etd: service?.cost[0]?.etd || "",
                            description: service?.description || "",
                            provider: provider?.code || "",
                            note: service?.cost[0]?.note || "",
                        }));
                        return {
                            name: provider.name,
                            services: services,
                        };
                    });

                    const updatedItem = {
                        ...foundItem,
                        shippingOptions: shippingOptions,
                    };
                    updatedItems.push(updatedItem);
                    toast({
                        title: 'DapetKomisi',
                        description: 'Silahkan pilih pengiriman tiap produk diatas',
                        status: 'success',
                        isClosable: true,
                        position: 'top'
    
                    });
                }
            }
        }

        setData({ ...data, items: updatedItems });
    };



    useEffect(() => {
        handlePrice();

        const initialItemData = data?.items?.map(item => ({
            description: "terimakasih sudah menyediakan barang sebagus ini :)",
            selectedShipment: 0,
            shippingOptions: item?.shippingOptions || [],
        }));
        setItemData(initialItemData);

    }, []);

    const [formData, setFormData] = useState({
        email: '',
        name: '',
        // label: '',
        phone_number: '',
        address: {
            street: '',
            city: '',
            state: '',
            postal_code: '',
            country: '',
        },
    });

    const handlePrice = () => {
        let totalPrice = 0;
        let totalShipmentPrice = 0;
        let totalInsurancePrice = 0;

        data?.items?.forEach((item, index) => {
            const { product, quantity } = item;
            const { price } = product;

            totalPrice += quantity * price;

            if (itemData[index]?.insurance) {
                totalInsurancePrice += 5000
            }

            if (itemData[index]?.selectedShipment > 0) {
                const selectedShippingOption = item.shippingOptions.find(
                    option => option.services.some(service => service.cost === itemData[index].selectedShipment)
                );

                if (selectedShippingOption) {
                    const selectedService = selectedShippingOption.services.find(
                        service => service.cost === itemData[index].selectedShipment
                    );

                    if (selectedService) {
                        totalShipmentPrice += selectedService.cost;
                    }
                }
            }
        });

        setPriceProduct(totalPrice);
        setPriceInsurance(totalInsurancePrice);
        setPriceShipment(totalShipmentPrice);
    }


    const handlePay = async () => {

        let updateData = { items: [] };



        data?.items?.forEach((item, index) => {
            const selectedShipmentCost = item.shippingOptions.find(
                option => option.services.some(service => service.cost === itemData[index].selectedShipment)
            );
        
            if (selectedShipmentCost) {
                const selectedService = selectedShipmentCost.services.find(
                    service => service.cost === itemData[index].selectedShipment
                );
        
                if (selectedService) {
                    const shippingProvider = {
                        provider: selectedService?.provider || "",
                        service: selectedService?.name || "",
                        description: selectedService?.description || "",
                        value: selectedService?.cost || 0,
                        etd: selectedService?.etd || "",
                        note: selectedService?.note || ""
                    };
        
                    const itemToUpdate = {
                        variant_id: item?.product?._id,
                        product: item?.product_id, 
                        quantity: item?.quantity,
                        description: itemData[index].description,
                        shipping_provider: shippingProvider,
                    };
        
                    // Jika idAffiliate terdefinisi, tambahkan affiliate_id ke dalam objek itemToUpdate
                    if (idAffiliate) {
                        itemToUpdate.affiliate = idAffiliate;
                    } else {
                        // Jika idAffiliate tidak terdefinisi, tambahkan objek baru dengan affiliate_id undefined
                        updateData.items.push({
                            ...itemToUpdate,
                            affiliate: undefined
                        });
                    }
        
                    // Tambahkan objek ke updateData.items jika affiliate_id telah ditambahkan sebelumnya
                    if (idAffiliate) {
                        updateData.items.push(itemToUpdate);
                    }
                }
            }
        });


        const dataRes = {
            ...updateData,
            contact_information: formData,
        }

        

        setLoading(true);
        try {
            const res = await axiosInstanceBarier.post('/order/create-non-user', dataRes);
            if (res.order.status === 200) {
                setLinkRedirect(res?.order?.data?.url);
                setUrlLink(res?.order?.data?.url)
                window.open(res?.order?.data?.url, '_blank');
                toast({
                    title: 'DapetKomisi',
                    description: 'Berhasil membuat orderan baru.',
                    status: 'success',
                    isClosable: true,
                    position: 'top'

                });
                // navigate("/")
            }
        } catch (error) {
            console.log(error, 'ini error')
            toast({
                title: 'DapetKomisi',
                description: error?.response?.data?.message,
                status: 'error',
                isClosable: true,
                position: 'top'

            });
        } finally {
            setLoading(false);
        }

    };


    useEffect(() => {
        handlePrice();

        const initialItemData = data?.items?.map(item => ({
            description: "terimakasih sudah menyediakan barang sebagus ini :)",
            selectedShipment: 0,
            shippingOptions: item?.shippingOptions || [],
        }));
        setItemData(initialItemData);

    }, []);


    const handleDescriptionChange = (index, description) => {
        const updatedItemData = [...itemData];
        updatedItemData[index].description = description;
        setItemData(updatedItemData);
    };

    const handleShipmentSelect = (index, selectedShipment) => {
        const updatedItemData = [...itemData];
        updatedItemData[index].selectedShipment = Number(selectedShipment);
        setItemData(updatedItemData);
    };




    return (
        <Stack p={[1, 1, 5]} spacing={10}>
         

            <Stack spacing={5}>
                <Text fontWeight={'bold'}>Detail Pesanan</Text>
                <SimpleGrid columns={[1, 1, 2]} gap={5}>

                    <Stack p={5}>

                        <Grid templateColumns={{ base: '1fr', md: '1fr 3fr' }} alignItems='center' justifyContent={'center'}>
                            <Text fontWeight={500} fontSize="sm" color={"gray.700"}>Penerima </Text>
                            <Input
                                placeholder="Nama lengkap"
                                onChange={(e) => setFormData({ ...formData, name: e.target.value })}

                            />
                        </Grid>

                        <Grid templateColumns={{ base: '1fr', md: '1fr 3fr' }} alignItems='center' justifyContent={'center'}>
                            <Text fontWeight={500} fontSize="sm" color={"gray.700"}>Email </Text>
                            <Input
                                placeholder="Email"
                                onChange={(e) => setFormData({ ...formData, email: e.target.value })}

                            />
                        </Grid>


                        <Grid templateColumns={{ base: '1fr', md: '1fr 3fr' }} alignItems='center' justifyContent={'center'}>
                            <Text fontWeight={500} fontSize="sm" color={"gray.700"}>Nomor Telpon Penerima</Text>
                            <Input
                                placeholder="ex: 085256123456"
                                onChange={(e) => setFormData({ ...formData, phone_number: e.target.value })}


                            />
                        </Grid>

                        <Grid templateColumns={{ base: '1fr', md: '1fr 3fr' }} alignItems='center' justifyContent={'center'}>
                            <Text fontWeight={500} fontSize="sm" color={"gray.700"}>Kota</Text>

                            <RegionSelect formData={formData} setFormData={setFormData} Provinsi={Region} />

                            {/* <Input
                                placeholder="ex: Jakarta Selatan"
                                size={"sm"}
                                onChange={(e) => setFormData({ ...formData, address: { ...formData.address, city: e.target.value } })}
                            /> */}
                        </Grid>

                        <Grid templateColumns={{ base: '1fr', md: '1fr 3fr' }} alignItems='center' justifyContent={'center'}>
                            <Text fontWeight={500} fontSize="sm" color={"gray.700"}>Provinsi</Text>
                            <ProvinceSelect formData={formData} setFormData={setFormData} Provinsi={Provinsi} />


                            {/* <Input
                                placeholder="ex: DKI Jakarta"
                                size={"sm"}
                                onChange={(e) => setFormData({ ...formData, address: { ...formData.address, state: e.target.value } })}

                            /> */}
                        </Grid>

                        <Grid templateColumns={{ base: '1fr', md: '1fr 3fr' }} alignItems='center' justifyContent={'center'}>
                            <Text fontWeight={500} fontSize="sm" color={"gray.700"}>Negara</Text>
                            <Input
                                placeholder="Ex: Indonesia"
                                onChange={(e) => setFormData({ ...formData, address: { ...formData.address, country: e.target.value } })}

                            />
                        </Grid>

                        <Grid templateColumns={{ base: '1fr', md: '1fr 3fr' }} alignItems='center' justifyContent={'center'}>
                            <Text fontWeight={500} fontSize="sm" color={"gray.700"}>Kode Pos</Text>
                            <Input
                                placeholder="Kode pos"
                                onChange={(e) => setFormData({ ...formData, address: { ...formData.address, postal_code: e.target.value } })}

                            />
                        </Grid>

                        <Grid templateColumns={{ base: '1fr', md: '1fr 3fr' }} alignItems='center' justifyContent={'center'}>
                            <Text fontWeight={500} fontSize="sm" color={"gray.700"}>Alamat Lengkap</Text>
                            <Textarea
                                placeholder="ex: Jl. Gardu"
                                onChange={(e) => setFormData({ ...formData, address: { ...formData.address, street: e.target.value } })}

                            />

                        </Grid>




                    </Stack>

                    <Stack p={5} bgColor='red.600' color='white' borderRadius='md'>
                        <Stack >

                            <Text fontWeight={'bold'}>Detail</Text>

                            <Stack p={5} bgColor="red.600" color="white" borderRadius="md">
                                <Stack>
                                    <Text fontWeight="bold">Harga Detail</Text>
                                    <Divider />
                                    <Spacer />
                                    <Stack fontSize="sm">
                                        <HStack>
                                            <Text>Produk</Text>
                                            <Spacer />
                                            <Text>Rp. {formatFrice(priceProduct || 0)}</Text>
                                        </HStack>
                                        <HStack>
                                            <Text>Pajak</Text>
                                            <Spacer />
                                            <Text>Rp. {formatFrice(priceTax || 0)}</Text>
                                        </HStack>
                                        <HStack>
                                            <Text>Asuransi</Text>
                                            <Spacer />
                                            <Text>Rp. {formatFrice(priceInsurance || 0)}</Text>
                                        </HStack>
                                        <HStack>
                                            <Text>Pengiriman</Text>
                                            <Spacer />
                                            <Text>Rp. {formatFrice(priceShipment || 0)}</Text>
                                        </HStack>
                                        <Divider />
                                        <HStack>
                                            <Text fontWeight={500}>Total</Text>
                                            <Spacer />
                                            <Text fontWeight={500}>
                                                Rp. {formatFrice(priceProduct + priceTax + priceInsurance + priceShipment)}
                                            </Text>
                                        </HStack>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack>
                </SimpleGrid>
            </Stack>

            <Stack spacing={5}>
                <Text fontWeight="bold">Produk List</Text>
                <Stack>
                    {data?.items?.length > 0 &&
                        data?.items?.map((item, index) => (
                            <HStack
                                spacing={5}
                                bgColor="white"
                                shadow="md"
                                p={2}
                                borderRadius="md"
                                key={index}
                            >
                                <Image
                                    src={item?.product?.images || "https://picsum.photos/300/200"}
                                    w="200px"
                                    h="100px"
                                    objectFit="cover"
                                    borderRadius="md"
                                />
                                <Stack w="full" spacing={5} flexDirection={["column", "row", "row"]}>
                                    <Stack>
                                        <Text fontWeight={500} fontSize="sm" textTransform="capitalize">
                                            {item?.product?.title}
                                        </Text>
                                        <Text fontSize="sm" color="gray.700">
                                            Jumlah: {item?.quantity || 0}
                                        </Text>
                                        <Text fontWeight={500}>
                                            Rp. {formatFrice(parseFloat(item?.product?.price || 0))} / Pcs
                                        </Text>
                                        <Checkbox
                                            isChecked={insuranceCheckboxes[index] || false}
                                            onChange={() => {
                                                const updatedCheckboxes = [...insuranceCheckboxes];
                                                updatedCheckboxes[index] = !insuranceCheckboxes[index];
                                                setInsuranceCheckboxes(updatedCheckboxes);

                                                const updatedItemData = [...itemData];
                                                updatedItemData[index].insurance = updatedCheckboxes[index];
                                                setItemData(updatedItemData);
                                            }}
                                            size="sm"
                                            color="red.600"
                                            fontStyle="italic"
                                        >
                                            Pilih asuransi untuk melindungi produk kamu
                                        </Checkbox>
                                    </Stack>

                                    <Stack>
                                        {item?.shippingOptions && (
                                            <Select
                                                borderRadius="md"
                                                size="sm"
                                                placeholder="Pilih pengiriman..."
                                                value={itemData[index].selectedShipment || ''}
                                                onChange={(e) => handleShipmentSelect(index, e.target.value)}
                                            >
                                                {item?.shippingOptions && (
                                                    <>
                                                        {item.shippingOptions.map((provider, providerIndex) => (
                                                            <optgroup key={providerIndex} label={provider.name}>
                                                                {provider.services.map((service, serviceIndex) => (
                                                                    <option key={serviceIndex} value={service.cost}>
                                                                        {service.name} - Rp. {service.cost} - {service.etd}
                                                                    </option>
                                                                ))}
                                                            </optgroup>
                                                        ))}
                                                    </>
                                                )}
                                            </Select>
                                        )}

                                        <Textarea
                                            borderRadius="md"
                                            size="sm"
                                            placeholder="Catatan untuk penjual ..."
                                            onChange={(e) => handleDescriptionChange(index, e.target.value)}
                                        />
                                    </Stack>
                                </Stack>
                            </HStack>
                        ))}
                </Stack>
            </Stack>
            <Stack spacing={5} bgColor='blackAlpha.900' p={5} color='gray.200' borderRadius={'md'}>
                <Text fontWeight={'bold'}>Term & Conditions</Text>
                <HStack alignItems={'flex-start'} spacing={5}>
                    <Checkbox bgColor={'white'} />
                    <Stack>
                        <Text fontSize={'sm'}>Dengan melakukan pembayaran atas produk yang Anda pilih, Anda dianggap telah memahami dan menyetujui sepenuhnya syarat dan ketentuan yang berlaku di DapatKomisi.com. Syarat dan ketentuan ini merangkum berbagai aspek yang penting dalam transaksi, termasuk ketersediaan produk, harga yang berlaku, proses pembayaran, kebijakan pengiriman yang bisa bervariasi, serta tata cara pengembalian barang dan prosedur klaim. Kami ingin menekankan pentingnya untuk membaca dengan teliti setiap informasi terkait produk, kebijakan pengiriman, serta syarat dan ketentuan lain yang berlaku sebelum menyelesaikan pembayaran. Dalam kasus terjadi perubahan atau penyesuaian terhadap syarat dan ketentuan kami, kami akan memberitahukan Anda mengenai perubahan tersebut agar Anda tetap memahami dan menyetujui kondisi terbaru sebelum melanjutkan transaksi pembayaran.</Text>
                    </Stack>
                </HStack>
            </Stack>
            <Stack>
                {linkRedirect !== "" && (
                    <HStack>
                        <Text color={'red'} fontSize='sm'>Jika kamu tidak ter Redirect ke halaman pembayaran Ipaymu, </Text>
                        <Text color='red' fontSize={"sm"} fontStyle='italic' fontWeight={"bold"} onClick={() => window.open(linkRedirect, "_blank")} cursor='pointer'>Klik Disini.</Text>
                    </HStack>
                )}
            </Stack>
            {itemData[0].selectedShipment !== 0 ? (
                <Stack alignItems={'flex-end'} pb={5}>
                    <Button isLoading={loading} colorScheme={'red'} onClick={() => handlePay()} >
                        <Text fontSize={'sm'}>Bayar Sekarang</Text>
                    </Button>
                </Stack>
            ) : (
                <Stack alignItems={'flex-end'} pb={5}>
                    <Button isLoading={loading} colorScheme={'red'} onClick={() => updateShippingCosts()} >
                        <Text fontSize={'sm'}>Check Estimasi Harga</Text>
                    </Button>
                </Stack>
            )}
            {/* {urlLink !== '' && (
                <AspectRatio ratio={16 / 9}>
                <iframe
                  src={'https://sandbox.ipaymu.com/payment/done/eyJpdiI6Ik5IWEg5aDJqRkJpSHRISmhGOUpFT1E9PSIsInZhbHVlIjoieHUyUUFNd3Q5dlhhdGJlYmN1bzFFSVYyc3NlLzBiYk5Kb29ZS1pLQ1FpcklZZEs3T2RoVG5HUkpMdysyUEtHRiIsIm1hYyI6IjE3MDI0ZDc0NjQxYmQzYTgyNDcyOGM4Y2NhZTk1ZmY3NDNmYmZhZWVlZTdhYzRiZWZjMjcxZDEzYTEwMDZjM2UiLCJ0YWciOiIifQ=='}
                />
              </AspectRatio>
            )} */}


        </Stack>
    )
}

export default OrdersNowPage