import axios from "axios";

const config = {
  // baseURL: 'https://dev-api.importir.com', // Ubah ke API staging jika perlu
  baseURL: process.env.REACT_APP_DEV_API, // Ubah ke API production jika perlu
  timeout: 60 * 1000, // Timeout
  withCredentials: true, // Aktifkan kredensial
};

const axiosInstanceBarier = axios.create(config);

axiosInstanceBarier.defaults.headers.common["Access-Control-Allow-Origin"] =
  "*";

axiosInstanceBarier.interceptors.request.use(async (config) => {
  const dataToken = localStorage.getItem("tokens");
  const tokenAccess = JSON.parse(dataToken)?.access;

  if (tokenAccess && tokenAccess.expires) {
    const tokenExpire = new Date(tokenAccess.expires);

    if (tokenExpire > new Date()) {
      config.headers["Authorization"] = `Bearer ${tokenAccess.token}`;
    } else {
      try {
        const newAccessToken = await refreshToken();
        config.headers["Authorization"] = `Bearer ${newAccessToken}`;
      } catch (error) {
        console.log("Gagal dalam permintaan dengan token kadaluwarsa:", error);
        // Gantilah kode ini dengan tindakan yang sesuai

        const newAccessToken = await refreshToken();
        config.headers["Authorization"] = `Bearer ${newAccessToken}`;
      }
    }
  } else {
    // Token akses tidak ada
    // Lakukan sesuatu (misalnya, alihkan ke halaman login)
    console.log("Token akses tidak tersedia");
    // Gantilah kode ini dengan tindakan yang sesuai
  }

  return config;
});

const refreshToken = async () => {
  console.log('masuk sini')
  const dataToken = localStorage.getItem("tokens");
  const refreshToken = JSON.parse(dataToken)?.refresh?.token;
  console.log(refreshToken, 'xxx')

  // console.log(refreshToken, 'yyy')
  // console.log(`${process.env.REACT_APP_DEV_API}/auth/refresh-tokens`, 'yyy')
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_DEV_API}/auth/refresh-tokens`,
      { refresh_token: refreshToken }
    );
    // console.log(response, 'resposne')
    localStorage.setItem("tokens", JSON.stringify(response));


    const newAccessToken = response.access.token;
    // console.log(newAccessToken, "new acces token");

    // Perbarui token akses dan waktu kadaluwarsa di localStorage
    const tokens = JSON.parse(response);
    tokens.access.token = newAccessToken;
    tokens.access.expires = response.access.expires;

    return newAccessToken;
  } catch (error) {
    console.log("Gagal memperbarui token:", error);
    throw error;
  }
};

axiosInstanceBarier.interceptors.response.use(
  function (response) {
    // Lakukan sesuatu dengan data response
    response = typeof response.data !== "undefined" ? response.data : response;
    return response;
  },

  async function (error) {
    if (error?.response && error?.response?.status === 404) {
      // Jika terjadi error 404, alihkan ke halaman 404
      // return window.location.href = '/error/error-404'
      return Promise.reject(error);
    } else if (error?.response && error?.response?.status === 401) {
      // Jika terjadi error 401 (Unauthorized)

      // Lakukan penanganan untuk token expired di sini
      try {

        const newAccessToken = await refreshToken();
        // Gunakan token akses yang baru
        error.config.headers['Authorization'] = `Bearer ${newAccessToken}`;
        // Ulangi permintaan yang sebelumnya yang gagal karena token kedaluwarsa
        return axios(error.config);
      } catch (refreshError) {
        console.log('Gagal dalam permintaan dengan token kadaluwarsa:', refreshError);
        // Gantilah kode ini dengan tindakan yang sesuai, misalnya, alihkan ke halaman login
        // return window.location.href = '/error/error-401'
        return Promise.reject(refreshError);
      }
    }
    console.log(error);
    return Promise.reject(error);
  }
);

export default axiosInstanceBarier;

// EXAMPLE CALL API

export const getDataApiBearer = async (url) => {
  try {
    const res = await axiosInstanceBarier.get(url);
    console.log(res.data, "ini res");
    return res.data;
  } catch (error) {
    console.log(error, "ini error");
    throw error;
  }
};

// Example Get Data

// const getData = async () => {
//     try {
//         const response = await getDataApiBearer('/api/');
//         console.log(response);
//         // Lakukan sesuatu dengan data response
//       } catch (error) {
//         console.log(error);
//         // Tangani error dengan cara yang sesuai
//       }
// }

// Finish

export const postDataApiBearer = async (url, data) => {
  try {
    const res = await axiosInstanceBarier.post(url, data);
    console.log(res.data, "ini res");
    return res;
  } catch (error) {
    console.log(error, "ini error");
    throw error;
  }
};

// Example post data

// const postData = async () => {
//   const newData = { name: "John Doe", age: 30 };
//   try {
//     const response = await postDataApiBearer("/api/", newData);
//     console.log(response);
//     // Lakukan sesuatu dengan data response
//   } catch (error) {
//     console.log(error);
//     // Tangani error dengan cara yang sesuai
//   }
// };
// //Finish

export const deleteDataApiBearer = async (url, data) => {
  try {
    const res = await axiosInstanceBarier.delete(url, data);
    console.log(res.data, "ini res");
    return res.data;
  } catch (error) {
    console.log(error, "ini error");
    throw error;
  }
};

// Example Delete Data

// const deleteData = async () => {
//     const idToDelete = 123;
//     try {
//       const response = await deleteDataApiBearer(`/api/${idToDelete}`);
//       console.log(response);
//       // Lakukan sesuatu dengan data response
//     } catch (error) {
//       console.log(error);
//       // Tangani error dengan cara yang sesuai
//     }

// }

// Finish

export const updateDataApiBearer = async (url, data) => {
  try {
    const res = await axiosInstanceBarier.put(url, data);
    console.log(res.data, "ini res");
    return res.data;
  } catch (error) {
    console.log(error, "ini error");
    throw error;
  }
};

//Example Update Data

// const updateData = async () => {
//     const idToUpdate = 456;
//     const updatedData = { name: 'Jane Doe', age: 35 };
//     try {
//       const response = await updateDataApiBearer(`/api/${idToUpdate}`, updatedData);
//       console.log(response);
//       // Lakukan sesuatu dengan data response
//     } catch (error) {
//       console.log(error);
//       // Tangani error dengan cara yang sesuai
//     }

// }

// Finish
