import { useEffect, useState } from "react";
import { Box, Stack } from "@chakra-ui/react";
import Layout from "./Layouts";
import MainRouter from "./Router/MainRouter";
import AuthRouter from "./Router/AuthRouter";


import Loading from "./Components/Loading/Loading";
import { UseAuthStore } from "./Hooks/Zustand/store";
import Preloader from "./Components/Loading/Preloader";
import { useLocation } from "react-router-dom";

function App() {

  const location = useLocation();

  const globalState = UseAuthStore();

  const handleRefresh = () => {

    const isLoggedIn = localStorage.getItem("isLoggedIn");
    const tokens = localStorage.getItem("tokens");
    const user = localStorage.getItem("user");

    const newToken = JSON.parse(tokens);
    const newUser = JSON.parse(user);
    const newIsLoggedIn = JSON.parse(isLoggedIn);

    globalState.setTokens(newToken);
    globalState.setUser(newUser);
    globalState.setIsLoggedIn(newIsLoggedIn);
  };

  useEffect(() => {
    handleRefresh();

    return () => {};
  }, []);

  return (
    <Stack>
      <Preloader />

      {location.pathname !== "/login" &&
      location.pathname !== "/signup" &&
      location.pathname !== "/register" &&
      location.pathname !== "/reset-password" 
      ? (
        <Layout>
          <MainRouter />
        </Layout>
      ) : (
        <AuthRouter />
      )}
    </Stack>
  );
}

export default App;
