/* eslint-disable react-hooks/exhaustive-deps */
import { AspectRatio, Avatar, AvatarGroup, Button, Divider, Heading, HStack, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid, Spacer, Spinner, Stack, Text, Tooltip } from '@chakra-ui/react'
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { AiFillStar } from 'react-icons/ai';
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import axiosInstanceBarier from '../../Apis/axiosWithBarier';
import AppImageSlide from '../../Components/Carousel/AppImageSlide';
import { UseAuthStore } from '../../Hooks/Zustand/store';
import ProductStoreCard from '../../Components/Cards/ProductStoreCard';
import ResellerList from '../../Reseller/ResellerList';
import RegisterCard from '../../Components/Cards/RegisterCard';



function CommunityNotificationPage() {

    const navigate = useNavigate()

    const [product, setProduct] = useState([]);
    const params = useLocation()
    const param = useParams()


    const [resellerList, setResellerList] = useState([])
    const [modalReseller, setModalReseller] = useState(false)
    const [dataCommunity, setDataCommunity] = useState(params?.state || undefined)

    const globalState = UseAuthStore();



    const storeData = dataCommunity?.stores

    const loading = false

    const getData = async() => {
        if(dataCommunity !== undefined) return 
        globalState.setIsLoading(true)
        try {
          const res = await axiosInstanceBarier(`/community/detail?id=${param.id}`)
          setDataCommunity(res.data)
        } catch (error) {
          console.log(error, 'ini error')
        }
        finally {
          globalState.setIsLoading(false)
        }
    }

    useEffect(() => {
        getData()
    
      return () => {
      }
    }, [])
    


    let imageCarouselDashboard = []

    if (dataCommunity && dataCommunity?.banner?.length > 0) {
        dataCommunity?.banner.map((x) => {
            const newImage = { data: x, image: x };
            imageCarouselDashboard.push(newImage);
        }
        )
    }

    const imageDefault = { data: "xxxx", image: "https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/banner_web%2FScreen%20Shot%202024-02-10%20at%2019.22.49.png?alt=media&token=d0c7dd26-538c-4777-848d-4e0332827d2f" }
    imageCarouselDashboard.push(imageDefault)




    if (dataCommunity?._id === "654765c8b5d4d49a798b4f96") {
        dataCommunity.video = "https://www.youtube.com/embed/5ThtiH6g0MQ?si=ReExJTSE4NA5nT6S"
        dataCommunity.notif_member = "Bersama lebih 500 ribu anggota dari berbagai provinsi di Indonesia Setelah sukses memulainya di tahun 2017 dan bekerjasama dengan Pemerintah Provinsi DKI Jakarta dan menghasilkan puluhan ribu wirausaha baru serta menyerap 200 ribu lapangan kerja di DKI Jakarta. Kini OK OCE diterapkan ke seluruh Indonesia melalui lebih dari 197 komunitas penggerak"


        const newImage = { data: dataCommunity.name, image: 'https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/WhatsApp%20Image%202023-12-19%20at%2011.45.17.jpeg?alt=media&token=87ad2c44-a95e-4336-be56-0ab5f1274bf8' };
        imageCarouselDashboard.push(newImage);
        console.log(imageCarouselDashboard, 'xxx')
    }


    const handleWhatsapp = () => {
        const message = encodeURIComponent(`Hi Admin saya membutuhkan referal code ${dataCommunity?.name},\n\nSilahkan tinggalkan pesanmu, dan Team Support kami akan segera membalas 😊`);
        const source = encodeURIComponent(window.location.href);

        const phoneNumber = dataCommunity?.admin?.phone_number;
        const sanitizedPhoneNumber = phoneNumber ? phoneNumber.replace(/\+/g, '') : '';

        const url = `https://api.whatsapp.com/send?phone=${sanitizedPhoneNumber}&text=${message}%0A%0ASource:%20${source}`;
        window.open(url, '_blank');
    }

    const getDataProduct = async () => {

        globalState.setIsLoading(true)
        try {
            const res = await axiosInstanceBarier.get(`/product?page=1&community_id=${dataCommunity._id}&produsen=true`);
            console.log(res, 'ini res')
            setProduct(res?.data);

        } catch (error) {
            console.log(error, ' ini error')
        }
        finally {
            globalState.setIsLoading(false)
        }
    }

    useEffect(() => {
        getDataProduct()

        return () => {
        }
    }, [])

    const handleResellerList = async (product_id) => {
        try {
            const result = await axiosInstanceBarier.get(`/reseller?product_id=${product_id}`)
            setResellerList(result.reseller_store)
            console.log(result)
            handleOpenModal()
        } catch (error) {
            console.log(error, 'ini error')
        }

    }

    const handleOpenModal = () => {
        setModalReseller(true)
    }

    const handleCloseModal = () => {
        setModalReseller(false)
    }

    const dataRegister = [
        {
            title: 'Produsen',
            hook: 'Anda pemilik Produk dan ingin mendapatkan dukungan tenaga penjualan (reseller) yang menggurita.',
            description: 'JANGAN TUNDA lagi segera daftarkan diri sebagai PRODUSEN dalam komunitas ini ',
            link: '/signup'
        },
        {
            title: 'Reseller',
            hook: 'Anda pemilik kemampuan menjual namun tidak memiliki PRODUK?',
            description: 'Anda pemilik kemampuan menjual namun tidak memiliki PRODUK? JANGAN TUNDA lagi segera pilih produk dari produsen yang tersedia dan  daftarkan diri sebagai RESELLER dalam komunitas ini ',
            link: '/signup'
        }
    ]







    return (
        <Stack p={[1, 1, 5]}
            // alignItems={{ base: 'center', md: 'flex-start' }}
            justifyContent={{ base: 'center', md: 'flex-start' }}
            textAlign={"justify"}
        >
            <Stack alignItems='center' justifyContent={'center'}>
                <Text fontWeight={'bold'} fontSize='lg'>{dataCommunity?.name}</Text>
            </Stack>

            <Stack bgColor={'gray.200'} borderRadius='md' p={[2, 4, 10]} spacing={5} >
                <Stack>
                    {loading ? (
                        <Stack h='100%' w='100%' alignItems={'center'} justifyContent='center'>
                            <Spinner />
                        </Stack>
                    ) : (
                        imageCarouselDashboard && (
                            <Stack alignItems={'center'} justifyContent='center'>
                                <Stack w={'100%'} position='relative' >
                                    <AppImageSlide images={imageCarouselDashboard} />
                                </Stack>
                            </Stack>
                        )
                    )}
                </Stack>
                <Stack>
                    <Text fontWeight={'bold'} fontSize={'sm'}>Notifikasi keanggotaan</Text>
                    <Text fontSize={'sm'}>{dataCommunity?.notif_member || ''}</Text>
                </Stack>

                <Stack>
                    <Text fontWeight={'bold'} fontSize={'sm'}>Tentang komunitas</Text>
                    <Text fontSize={'sm'}>{dataCommunity?.desc || ''}</Text>
                </Stack>

                <Stack py={5}>
                    {dataCommunity?.video && (
                        <AspectRatio ratio={16 / 9}>
                            <iframe
                                title='youtube'
                                src={dataCommunity?.video || ""}
                                allowFullScreen
                            />
                        </AspectRatio>
                    )}

                </Stack>

                <Stack spacing={3}>
                    <Heading size={'sm'}>Daftar Produsen Tergabung</Heading>

                    <HStack gap={3} overflowX='scroll' width='100%' >

                        {storeData?.length > 0 && (
                            storeData
                                .map((x, index) => {


                                    return (
                                        <Stack key={index}>
                                            <Stack shadow='md' w={['200px']} h='350px' borderRadius='xl' spacing={2} bgColor='white' _hover={{ transform: 'scale(1.05)' }} transition='0.3s ease-in-out' onClick={() => navigate(`/produsen?id=${x._id}`, {state: x})}>
                                                <Stack alignItems='center' cursor='pointer' objectFit={'cover'} >
                                                    <Image
                                                        src={x.image || 'https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/default%2FWhatsApp%20Image%202024-02-13%20at%2023.06.48.jpeg?alt=media&token=065b4b7c-7f20-463f-8e17-ae0a1fd5a3b7'}
                                                        alt='img'
                                                        borderRadius='md'
                                                        style={{ width: '100% ', height: '180px', objectFit: 'cover' }}
                                                    // onLoad={() => setIsLoading(false)}
                                                    // onError={() => setIsLoading(false)} 
                                                    />
                                                </Stack>
                                                <Spacer />

                                                <Stack px={2}>


                                                    <Text textTransform='capitalize' fontWeight='bold' fontSize='sm' noOfLines={1}>
                                                        {x?.name}
                                                    </Text>
                                                </Stack>

                                                <Stack flexDirection={["row"]} spacing={0} px={2}>
                                                    <Stack spacing={1} alignItems={"flex-end"} justifyContent={'flex-end'}>

                                                        <HStack >
                                                            <HStack spacing={0}>
                                                                <AiFillStar size={10} color='orange' />
                                                                <AiFillStar size={10} color='orange' />
                                                                <AiFillStar size={10} color='orange' />
                                                                <AiFillStar size={10} color='orange' />
                                                                <AiFillStar size={10} color='orange' />
                                                            </HStack>
                                                            <Text fontSize={'xs'} color='gray.500'>({x.rating})</Text>
                                                        </HStack>
                                                        <Divider />
                                                        <Text fontSize={'xs'} color='gray.500'>Update : {moment(x.created_at).format('YYYY')}</Text>
                                                    </Stack>

                                                    <Spacer />

                                                    <Stack alignItems={"flex-end"} spacing={1} >
                                                        {x?.community?.length > 0 ? (
                                                            <AvatarGroup
                                                                cursor="pointer"
                                                                size="xs"
                                                                max={3}
                                                                spacing={-2}
                                                            >
                                                                {x?.community?.map((user, i) => {

                                                                    return (
                                                                        <Tooltip key={i} label={user?.name} aria-label="Name">
                                                                            <Avatar size={'xs'} name={user?.name} src={user?.image || user?.name} />
                                                                        </Tooltip>
                                                                    );
                                                                })}
                                                            </AvatarGroup>
                                                        ) : (
                                                            <AvatarGroup
                                                                cursor="pointer"
                                                                size="xs"
                                                                max={3}
                                                                spacing={-2}
                                                            >
                                                                <Tooltip label={'Admin'} aria-label="Name">
                                                                    <Avatar size={'xs'} name={'Admin'} src={'https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/default%2FWhatsApp%20Image%202024-02-13%20at%2022.59.30.jpeg?alt=media&token=30790f44-f8c6-4ef4-b7ad-e97749c5d6d3'} />
                                                                </Tooltip>
                                                            </AvatarGroup>
                                                        )}

                                                        <Divider />
                                                        <Text fontSize={"xs"} color="gray.500">Komunitas</Text>
                                                    </Stack>
                                                </Stack>
                                                <Stack p={3}>
                                                    <Button size='xs' bgColor='red.600' >
                                                        <Text color='white'>lihat Produk Produsen</Text>
                                                    </Button>
                                                </Stack>
                                            </Stack>
                                        </Stack>
                                    )
                                })
                        )}
                    </HStack>

                </Stack>

{/* 
                <Stack spacing={3}>
                    <Heading size={'sm'}>List Produk Komunitas</Heading>
                    <HStack gap={2} overflowX='scroll' width='100%'>
                        {product.length > 0 && (
                            product.map((x, index) => {
                                return (
                                    <ProductStoreCard index={index} data={x} onClick={() => handleResellerList(x._id)} />
                                )
                            })
                        )}
                    </HStack>
                </Stack> */}



                {dataCommunity && (
                    <Stack>

                        <Text fontSize={'sm'} fontWeight={500}>Untuk mendaftar pada komunitas {dataCommunity?.name} Klik tombol dibawah untuk menghubungi admin dan mendapatkan referal code, setelah itu lakukan pendaftaran dengan click link di bawah ini</Text>
                    </Stack>
                )}

                <Stack>
                    <SimpleGrid columns={[1, 1, 2]} gap={2}>
                    {dataRegister.map((x, index) =>{
                        return(
                            <Stack key={index}>
                                <RegisterCard data={x}/>
                            </Stack>
                        )
                    })}
                    </SimpleGrid>
                </Stack>


            </Stack>

            <Modal isOpen={modalReseller} onClose={handleCloseModal} size='3xl'>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Daftar Reseller </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {resellerList?.length > 0 ? (
                            <ResellerList resellerList={resellerList} />

                        ) : (
                            <Stack>
                                <Heading size={'md'}>Produk ini belum mempunyai reseller</Heading>
                                <Text>Daftar sekarang untuk menjadi reseller, dengan klik tombol dibawah</Text>
                            </Stack>
                        )}
                    </ModalBody>

                    <ModalFooter>

                        <Button size={"sm"} colorScheme="red" mr={3} onClick={() => navigate('/signup')}>Daftar Reseller / Dropship</Button>
                        <Button size={"sm"} colorScheme="red" mr={3} onClick={handleCloseModal}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Stack>
    )
}

export default CommunityNotificationPage