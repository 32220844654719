import { Button, SimpleGrid, Spinner, Stack, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import axiosInstanceBarier from '../../Apis/axiosWithBarier'
import ProductCard from '../../Components/Cards/ProductCard'
import { UseAuthStore } from '../../Hooks/Zustand/store'

function StoreDetailPage() {

    const param = useParams()
    const location = useLocation()

    const dataState = location?.state


    const globalState = UseAuthStore();

    const [currentPage, setCurrentPage] = useState(1);

    const [productList, setProductList] = useState([])
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);


    const getDataProductStore = async () => {
        globalState.setIsLoading(true);
        try {
            const res = await axiosInstanceBarier.get(`/store/product?store_id=${param?.id}&page=${currentPage}`)
            if (res?.data?.length > 0) {
                setProductList((prevProduct) => {
                    if (currentPage === 1) {
                        return res.data;
                    } else {
                        return [...prevProduct, ...res.data];
                    }
                });
                setCurrentPage((prevPage) => prevPage + 1);
            } else {
                setHasMore(false); 
            }
        } catch (error) {
            console.log(error);
        } finally {
            globalState.setIsLoading(false);
        }
    }

    useEffect(() => {
        getDataProductStore()

        return () => {
            setProductList([])
        }
    }, [param.id])

    return (
        <Stack p={[1, 1, 5]}>
            <Stack spacing={0}>
                <Stack alignItems={"center"} justifyContent="center" py={3}>
                    <Text fontWeight={500} fontSize='xl' textTransform={"capitalize"}>{dataState?.name}</Text>
                </Stack>
                <SimpleGrid columns={[1, 3, 4]} spacing={3} py={5}>
                    {productList.length > 0 && (
                        productList.map((x, index) => {
                            return (
                                <ProductCard index={index} data={x} />
                            )
                        })
                    )}
                </SimpleGrid>
            </Stack>

            <Stack py={5} alignItems='center' justifyContent={'center'}>
                {loading ? (
                    <Stack h='100%' w='100%' alignItems={'center'} justifyContent='center'>
                        <Spinner />
                    </Stack>
                ) : (
                    hasMore ? (
                        <Button
                            onClick={getDataProductStore}
                            bgColor='red.500'
                            color='white'
                            _hover={{ bgColor: 'red.600' }}
                            _active={{ bgColor: 'red.700' }}
                        >
                            Lihat lebih banyak
                        </Button>
                    ) : (
                        <Text fontWeight='bold' fontSize='lg'>Semua produk telah dimuat.</Text>
                    )
                )}
            </Stack>
        </Stack>
    )
}

export default StoreDetailPage