import { Gallery } from './Gallery'

export const CarouselComponent = (props) => {
	let images = []
	if (!props.images)
		images = [
			{
				id: '01',
				image: 'https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/banner_mobile%2FScreen%20Shot%202024-02-06%20at%2000.06.05.png?alt=media&token=c17ad99b-8b9f-4249-abc2-64ba0306344d',
				alt: ' ',
			},
			{
				id: '02',
				image: 'https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/banner_mobile%2FScreen%20Shot%202024-02-06%20at%2000.06.14.png?alt=media&token=d8516ba0-45de-4368-b144-96b8b1104d0d',
				alt: ' ',
			},
			{
				id: '03',
				image: 'https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/banner_mobile%2FScreen%20Shot%202024-02-06%20at%2000.06.20.png?alt=media&token=0167465b-233f-45e9-aece-8ee610078ee3',
				alt: ' ',
			},
			{
				id: '04',
				image: 'https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/banner_mobile%2FScreen%20Shot%202024-02-06%20at%2000.06.27.png?alt=media&token=d4c95527-d667-41a0-944b-e203c8560f7d',
				alt: ' ',
			},
			{
				id: '05',
				image: 'https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/banner_mobile%2FScreen%20Shot%202024-02-06%20at%2000.06.33.png?alt=media&token=8d3c2832-ced0-437c-8154-504156ef04d3',
				alt: ' ',
			},

		]
	else
		images = props.images

	return (
		<Gallery images={images} aspectRatio={props.aspectRatio} />
	)
}