import React from "react";
import PromoPage from "../Pages/Promo/PromoPage";

const PromoRouter = [
  {
    path: "/promo",
    element: (
      <PromoPage />
    ),
  },
];

export default PromoRouter;
