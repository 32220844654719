import React from "react";
import CategoryDetailPage from "../Pages/Category/CategoryDetailPage";
import CategoryPage from "../Pages/Category/CategoryPage";

const CategoryRouter = [
  {
    path: "/category",
    element: <CategoryPage />,
  },

  {
    path: "/category/:category",
    element: <CategoryDetailPage />,
  },

  {
    path: "/category/:category/:subcategory",
    element: <CategoryDetailPage />,
  },
];

export default CategoryRouter;
