import React from "react";
import { Route, Routes } from "react-router-dom";
import AffiliateRouter from "./AffiliateRouter";
import CartRouter from "./CartRouter";
import CategoryRouter from "./CategoryRouter";
import CommunityRouter from "./CommunityRouter";
import DashboardRouter from "./DashboardRouter";
import OrdersRouter from "./OrdersRouter";
import ProductRouter from "./ProductRouter";
import ProfileRouter from "./ProfileRouter";
import PromoRouter from "./PromoRouter";
import SearchRouter from "./SearchRouter";
import StoreRouter from "./StoreRouter";
import TransactionsRouter from "./TransactionsRouter";
import ProdusenRouter from "./ProdusenRouter";


function MainRouter() {
  const allRouter = [
    ...DashboardRouter,
    ...ProductRouter,
    ...StoreRouter,
    ...OrdersRouter,
    ...CommunityRouter,
    ...CategoryRouter,
    ...SearchRouter,
    ...CartRouter,
    ...AffiliateRouter,
    ...TransactionsRouter,
    ...ProfileRouter,
    ...PromoRouter,
    ...ProdusenRouter
  ];

  return (
    <Routes>
      {allRouter.map((item, index) => {
        return <Route key={index} path={item.path} element={item.element} />;
      })}
    </Routes>
  );
}

export default MainRouter;
