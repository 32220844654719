/* eslint-disable react-hooks/exhaustive-deps */
import { Avatar, AvatarGroup, Button, Divider, HStack, Image, SimpleGrid, Spacer, Spinner, Stack, Text, Tooltip } from '@chakra-ui/react'
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { AiFillStar } from 'react-icons/ai';
import { useNavigate, useSearchParams } from 'react-router-dom'
import axiosInstanceBarier from '../../Apis/axiosWithBarier'
import ProductCard from '../../Components/Cards/ProductCard';
import { UseAuthStore } from '../../Hooks/Zustand/store';

function SearchPage() {
    const globalState = UseAuthStore();

    let [searchParams] = useSearchParams()
    const detailSearchParams = searchParams.get("detail")

    const [product, setProduct] = useState([]);
    const [store, setStore] = useState([]);
    const [loading] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageStore, setCurrentPageStore] = useState(1);

    const [hasMore, setHasMore] = useState(true);

    const navigate = useNavigate()

    // const image_data = [
    //     { id: "6566c16dadcbf500398a32bc", image: "https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/WhatsApp%20Image%202023-11-29%20at%2014.57.45.jpeg?alt=media&token=d5898712-f326-4d34-9aa1-0d44870e4dde" },
    //     { id: "656484456b7d3e00380dd630", image: "https://static.promediateknologi.id/crop/0x0:0x0/750x500/webp/photo/ayobandung/images-bandung/post/articles/2019/12/04/72076/images_(1).png" },
    //     { id: "654765c8b5d4d49a798b4f96", image: "https://ecsys.okoce.net/assets/logo/logo_ok-oce-forever.jpg" },
    //     { id: "654765bdb5d4d49a798b4f93", image: "https://yt3.googleusercontent.com/CDHjw4x1ZdzKipkfsRw2VHfgojxreI_IbnqKH_U7L5rMs3hU7xJuo0uaPjGy_pVnJ2lZYTvvfrQ=s176-c-k-c0x00ffffff-no-rj" },
    //     { id: "65476540b5d4d49a798b4f8d", image: "https://upload.wikimedia.org/wikipedia/id/d/d4/TDA.png" },
    //     { id: "65673583adcbf500398a3d44", image: "https://play-lh.googleusercontent.com/mD10aGgL6MU_-r3YmOYC_nEqc4ihe84tA3mAO3fOPDVMd6I56tYNULDCFqVmf8wDfw" },
    //     { id: "65476673b5d4d49a798b522c", image: "https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/Screen%20Shot%202023-09-22%20at%2000.55.33.png?alt=media&token=27a5756e-eb66-42e6-9bd6-9e6e8ca5a20b" },
    //     { id: "6564c60beccee55e687b02ff", image: "https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/SjXJnxm1_400x400.jpeg?alt=media&token=78daded4-ee7f-40dd-a207-746eea2459cf" },

    // ]


    // const image_profile = [
    //     { id: "6566c368adcbf500398a32df", image: "https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/default%2FWhatsApp%20Image%202024-02-13%20at%2023.06.48.jpeg?alt=media&token=065b4b7c-7f20-463f-8e17-ae0a1fd5a3b7" },
    //     { id: "6581483c5ed71200b3c7a56d", image: "https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/WhatsApp%20Image%202023-12-19%20at%2014.58.21.jpeg?alt=media&token=2b76d43a-6f26-4c84-a5a7-95331958ed5a" },
    // ]


    const handleSearchParams = async () => {
        globalState.setIsLoading(true);
        try {
            const res = await axiosInstanceBarier.get(`/product?search=${detailSearchParams}&page=${currentPage}`)
            if (res?.data?.length > 0) {
                setProduct((prevProduct) => {
                    if (currentPage === 1) {
                        return res.data;
                    } else {
                        return [...prevProduct, ...res.data];
                    }
                });
                setCurrentPage((prevPage) => prevPage + 1);
            } else {
                setHasMore(false); // Tidak ada lagi produk untuk dimuat
            }

            const resStore = await axiosInstanceBarier.get(`/store?search=${detailSearchParams}&page=${currentPageStore}`)

            if (resStore?.data?.length > 0) {
                setStore((prevProductData) => {
                    if (currentPageStore === 1) {
                        return resStore.data;
                    } else {
                        return [...prevProductData, ...resStore.data];
                    }
                });
                setCurrentPageStore((prevPage) => prevPage + 1);
            } else {
                setHasMore(false); // Tidak ada lagi produk untuk dimuat
            }



        } catch (error) {
            console.log(error);
        } finally {
            globalState.setIsLoading(false);
        }
    }

    useEffect(() => {
        // Set `product` kembali ke array kosong ketika parameter pencarian berubah (halaman pertama).
        handleSearchParams();

    }, [detailSearchParams])

    return (
        <Stack p={[1, 1, 5]}>
            <Text fontWeight={'bold'} fontSize='lg'>Produsen</Text>
            <Stack>
                <SimpleGrid columns={[2, 3, 4]} spacing={3} py={5}>
                    {store?.length > 0 && (
                        store
                            .map((x, index) => {
                                console.log(x, 'ini xx')

                                return (
                                    <Stack key={index}>
                                        <Stack shadow='md' h='400px' w={['200px', "250px", "250px"]} borderRadius='xl' spacing={2} bgColor='white' _hover={{ transform: 'scale(1.05)' }} transition='0.3s ease-in-out'  onClick={() => navigate(`/produsen`)} >
                                            <Stack alignItems='center' cursor='pointer' objectFit={'cover'} onClick={() => console.log('product')}>
                                                    <Image
                                                        src={x?.image || 'https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/WhatsApp%20Image%202024-02-07%20at%2014.43.30.jpeg?alt=media&token=d23f664f-8996-4f0b-86de-cfc88422385a'}
                                                        alt='img'
                                                        borderRadius='md'
                                                        style={{ width: '100%', height: '180px', objectFit: 'cover' }}
                                                    // onLoad={() => setIsLoading(false)}
                                                    // onError={() => setIsLoading(false)} 
                                                    />

                                            </Stack>
                                            <Spacer />

                                            <Stack px={3}>

                                                <Stack alignItems={'end'}>
                                                    <Text fontSize={'xs'} color='gray.500'>Update : {moment(x.created_at).format('YYYY')}</Text>

                                                </Stack>

                                                <Text textTransform='capitalize' fontWeight='bold' fontSize='sm' noOfLines={1}>
                                                    {x?.name}
                                                </Text>
                                            </Stack>

                                            <Stack flexDirection={["column", "row", "row"]} spacing={0} px={3}>

                                                <HStack >
                                                    <HStack spacing={0}>
                                                        <AiFillStar size={10} color='orange' />
                                                        <AiFillStar size={10} color='orange' />
                                                        <AiFillStar size={10} color='orange' />
                                                        <AiFillStar size={10} color='orange' />
                                                        <AiFillStar size={10} color='orange' />
                                                    </HStack>
                                                    <Text fontSize={'xs'} color='gray.500'>({x.rating})</Text>
                                                </HStack>

                                                <Spacer />

                                                <Stack alignItems={"center"} spacing={1} >
                                                    {x?.community?.length > 0 ? (
                                                        <AvatarGroup
                                                            cursor="pointer"
                                                            size="xs"
                                                            max={3}
                                                            spacing={-2}
                                                        >
                                                            {x?.community?.map((user, i) => {

                                                                return (
                                                                    <Tooltip key={i} label={user?.name} aria-label="Name">
                                                                        <Avatar size={'xs'} name={user?.name} src={user?.image || user?.name} />
                                                                    </Tooltip>
                                                                );
                                                            })}
                                                        </AvatarGroup>
                                                    ) : (
                                                        <AvatarGroup
                                                            cursor="pointer"
                                                            size="xs"
                                                            max={3}
                                                            spacing={-2}
                                                        >
                                                            <Tooltip  label={'Admin'} aria-label="Name">
                                                                <Avatar size={'xs'} name={'Admin'} src={'https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/default%2FWhatsApp%20Image%202024-02-13%20at%2022.59.30.jpeg?alt=media&token=30790f44-f8c6-4ef4-b7ad-e97749c5d6d3'} />
                                                            </Tooltip>
                                                        </AvatarGroup>
                                                    )}

                                                    <Divider />
                                                    <Text fontSize={"xs"} color="gray.500">Komunitas</Text>
                                                </Stack>
                                            </Stack>
                                            <Stack p={3}>
                                                <Button size='sm' bgColor='red.600'>
                                                    <Text color='white'>lihat product</Text>
                                                </Button>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                )
                            })
                    )}
                </SimpleGrid>
                <Stack py={5} alignItems='center' justifyContent={'center'}>
                    {loading ? (
                        <Stack h='100%' w='100%' alignItems={'center'} justifyContent='center'>
                            <Spinner />
                        </Stack>
                    ) : (
                        hasMore && (
                            <Button
                                onClick={handleSearchParams}
                                bgColor='red.500'
                                color='white'
                                _hover={{ bgColor: 'red.600' }}
                                _active={{ bgColor: 'red.700' }}
                            >
                                Lihat lebih banyak
                            </Button>
                        )
                    )}
                </Stack>
            </Stack>
            <Divider />
            <Text fontWeight={'bold'} fontSize='lg'>Products</Text>
            <Stack>
                <SimpleGrid columns={[2, 3, 4]} spacing={3} py={5}>

                    {product.length > 0 && (
                        product.map((x, index) => {
                            return (
                                <ProductCard index={index} data={x} />
                            )
                        })
                    )}
                </SimpleGrid>
                <Stack py={5} alignItems='center' justifyContent={'center'}>
                    {loading ? (
                        <Stack h='100%' w='100%' alignItems={'center'} justifyContent='center'>
                            <Spinner />
                        </Stack>
                    ) : (
                        hasMore && (
                            <Button
                                onClick={handleSearchParams}
                                bgColor='red.500'
                                color='white'
                                _hover={{ bgColor: 'red.600' }}
                                _active={{ bgColor: 'red.700' }}
                            >
                                Lihat lebih banyak
                            </Button>
                        )
                    )}
                </Stack>
            </Stack>
        </Stack>
    )
}

export default SearchPage
