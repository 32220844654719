/* eslint-disable react-hooks/exhaustive-deps */
import { Avatar, AvatarGroup, Button, Divider, Heading, HStack, Image, SimpleGrid, Spacer, Spinner, Stack, Text, Tooltip } from '@chakra-ui/react'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AiFillStar } from 'react-icons/ai';
import { UseAuthStore } from "../../Hooks/Zustand/store";
import axiosInstanceBarier from '../../Apis/axiosWithBarier';




function StorePage() {

    const [store, setStore] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [adminStore, setAdminStore] = useState('')

    const [loading] = useState(false)




    const image_profile = [
        { id: "6566c368adcbf500398a32df", image: "https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/default%2FWhatsApp%20Image%202024-02-13%20at%2023.06.48.jpeg?alt=media&token=065b4b7c-7f20-463f-8e17-ae0a1fd5a3b7" },
        { id: "6581483c5ed71200b3c7a56d", image: "https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/WhatsApp%20Image%202023-12-19%20at%2014.58.21.jpeg?alt=media&token=2b76d43a-6f26-4c84-a5a7-95331958ed5a" },
    ]



    const globalState = UseAuthStore();

    const getDataStore = async (page) => {
        globalState.setIsLoading(true)
        try {
            const res = await axiosInstanceBarier.get(`/store?&page=${currentPage}&reseller=true`);


            if (page === 1) {
                setStore(res?.data || []); // Set data keranjang belanja pada halaman pertama
            } else {
                setStore((prevData) => [...prevData, ...(res?.data || [])]); // Tambahkan data baru ke data keranjang belanja sebelumnya
            }
            if (res?.data?.page >= res?.data?.total_pages) {
                setHasMore(false); // Tidak ada lagi produk untuk dimuat
            }
            setAdminStore(res?.admin_store)


        } catch (error) {
            console.log(error, ' ini error')
        }
        finally {
            globalState.setIsLoading(false)
        }
    }

    const handleLoadMore = () => {
        if (hasMore) {
            const nextPage = currentPage + 1;
            setCurrentPage(nextPage);
            getDataStore(nextPage);
        }
    }


    useEffect(() => {
        getDataStore()

        return () => {
            setStore([])
            setCurrentPage(1); // Reset currentPage ke halaman pertama saat drawer ditutup
            setHasMore(true);
        }
    }, [])




    const navigate = useNavigate()
    return (
        <Stack p={[1, 1, 5]} spacing={3}>
            <Heading size={'md'}>List Reseller</Heading>
            <SimpleGrid columns={[1, 2, 4]}  gap={5} width='100%' p={2}>
                <Stack >
                    <Stack shadow='md' w={['auto', "250px", "250px"]} h='400px' borderRadius='xl' spacing={2} bgColor='white' _hover={{ transform: 'scale(1.05)' }} transition='0.3s ease-in-out'  onClick={() => navigate(`/produsen`)} >
                        <Stack alignItems='center' cursor='pointer' onClick={() => console.log('product')}>
                            <Stack alignItems='center' cursor='pointer' objectFit='cover' onClick={() => console.log('product')}>
                                <Image
                                    src={adminStore?.image || 'https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/default%2FWhatsApp%20Image%202024-02-13%20at%2023.07.09.jpeg?alt=media&token=8ac65665-3b62-43e4-9179-2c3541ed4a14'}
                                    alt='img'
                                    borderRadius='md'
                                    style={{ width: '100%', height: '180px', objectFit: 'cover' }}
                                />
                            </Stack>

                        </Stack>
                        <Spacer />

                        <Stack px={3}>

                            <Stack alignItems={'end'}>
                                <Text fontSize={'xs'} color='gray.500'>Update : {moment(adminStore.created_at).format('YYYY')}</Text>

                            </Stack>

                            <Text textTransform='capitalize' fontWeight='bold' fontSize='sm' noOfLines={1}>
                                {adminStore?.name}
                            </Text>
                        </Stack>

                        <Stack flexDirection={["row", "row", "row"]} spacing={0} px={3}>

                            <HStack >
                                <HStack spacing={0}>
                                    <AiFillStar size={10} color='orange' />
                                    <AiFillStar size={10} color='orange' />
                                    <AiFillStar size={10} color='orange' />
                                    <AiFillStar size={10} color='orange' />
                                    <AiFillStar size={10} color='orange' />
                                </HStack>
                                <Text fontSize={'xs'} color='gray.500'>({adminStore?.rating})</Text>
                            </HStack>

                            <Spacer />

                            <Stack alignItems={"flex-end"} spacing={1} >
                                {adminStore?.community?.length > 0 ? (
                                    <AvatarGroup
                                        cursor="pointer"
                                        size="xs"
                                        max={3}
                                        spacing={-2}
                                    >
                                        {adminStore?.community?.map((user, i) => {
                                            return (
                                                <Tooltip key={i} label={user?.name} aria-label="Name">
                                                    <Avatar size={'xs'} name={user?.name} src={user?.image || user?.name} />
                                                </Tooltip>
                                            );
                                        })}
                                    </AvatarGroup>
                                ) : (
                                    <AvatarGroup
                                        cursor="pointer"
                                        size="xs"
                                        max={3}
                                        spacing={-2}
                                    >
                                        <Tooltip label={'Admin'} aria-label="Name">
                                            <Avatar size={'xs'} name={'Admin'} src={'https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/default%2FWhatsApp%20Image%202024-02-13%20at%2022.59.30.jpeg?alt=media&token=30790f44-f8c6-4ef4-b7ad-e97749c5d6d3'} />
                                        </Tooltip>
                                    </AvatarGroup>
                                )}

                                <Divider />
                                <Text fontSize={"xs"} color="gray.500">Komunitas</Text>
                            </Stack>
                        </Stack>
                        <Stack p={3}>
                            <Button size='sm' bgColor='red.600'>
                                <Text color='white'>lihat semua produsen</Text>
                            </Button>
                        </Stack>
                    </Stack>
                </Stack>
                {store.length > 0 && (
                    store
                        .map((x, index) => {
                            return (
                                <Stack key={index}>
                                    <Stack shadow='md' h='400px' w={['auto', "250px", "250px"]} borderRadius='xl' spacing={2} bgColor='white' _hover={{ transform: 'scale(1.05)' }} transition='0.3s ease-in-out'>
                                        <Stack alignItems='center' cursor='pointer' objectFit={'cover'} onClick={() => console.log('product')}>
                                            {/* {x.image ? ( */}
                                            <Image
                                                src={x.image || image_profile.find((data) => data.id === x._id)?.image || 'https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/WhatsApp%20Image%202024-02-07%20at%2014.43.30.jpeg?alt=media&token=d23f664f-8996-4f0b-86de-cfc88422385a'}
                                                alt='img'
                                                borderRadius='md'
                                                style={{ width: '100% ', height: '180px', objectFit: 'cover' }}
                                            // onLoad={() => setIsLoading(false)}
                                            // onError={() => setIsLoading(false)} 
                                            />
                                            {/* ) : (
                                        <Skeleton w='100%' h='180px' borderRadius='md' startColor='gray.100' endColor='gray.300' />
                                    )} */}
                                        </Stack>
                                        <Spacer />

                                        <Stack px={3}>


                                            <Stack alignItems={'end'}>
                                                <Text fontSize={'xs'} color='gray.500'>Update : {moment(x.created_at).format('YYYY')}</Text>

                                            </Stack>


                                            <Text textTransform='capitalize' fontWeight='bold' fontSize='sm' noOfLines={1}>
                                                {x.name}
                                            </Text>
                                        </Stack>

                                        <Stack flexDirection={["row", "row", "row"]} spacing={0} px={3}>

                                            <HStack >
                                                <HStack spacing={0}>
                                                    <AiFillStar size={10} color='orange' />
                                                    <AiFillStar size={10} color='orange' />
                                                    <AiFillStar size={10} color='orange' />
                                                    <AiFillStar size={10} color='orange' />
                                                    <AiFillStar size={10} color='orange' />
                                                </HStack>
                                                <Text fontSize={'xs'} color='gray.500'>({x.rating})</Text>
                                            </HStack>

                                            <Spacer />

                                            <Stack alignItems={"flex-end"} spacing={1} >
                                                {x?.community?.length > 0 ? (
                                                    <AvatarGroup
                                                        cursor="pointer"
                                                        size="xs"
                                                        max={3}
                                                        spacing={-2}
                                                    >
                                                        {x?.community.map((user, i) => {
                                                            return (
                                                                <Tooltip key={i} label={user?.name} aria-label="Name">
                                                                    <Avatar name={user?.name} size='xs' src={user?.image || user?.name} />
                                                                </Tooltip>
                                                            );

                                                        })}
                                                    </AvatarGroup>
                                                ) : (
                                                    <AvatarGroup
                                                        cursor="pointer"
                                                        size="xs"
                                                        max={3}
                                                        spacing={-2}
                                                    >
                                                        <Tooltip label={'Admin'} aria-label="Name">
                                                            <Avatar name={'Admin'} size='xs' src={'Admin'} />
                                                        </Tooltip>
                                                    </AvatarGroup>
                                                )}

                                                <Divider />
                                                <Text fontSize={"xs"} color="gray.500">Komunitas</Text>
                                            </Stack>
                                        </Stack>
                                        <Stack p={3}>
                                            <Button size='sm' bgColor='red.600' onClick={() => navigate(`/store/${x._id}`, { state: x })} >
                                                <Text color='white'>lihat product</Text>
                                            </Button>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            )
                        })
                )}
            </SimpleGrid>

            <Stack py={5} alignItems='center' justifyContent={'center'}>
                {loading ? (
                    <Stack h='100%' w='100%' alignItems={'center'} justifyContent='center'>
                        <Spinner />
                    </Stack>
                ) : (
                    hasMore ? (
                        <Button
                            onClick={handleLoadMore}
                            bgColor='red.500'
                            color='white'
                            _hover={{ bgColor: 'red.600' }}
                            _active={{ bgColor: 'red.700' }}
                        >
                            Lihat lebih banyak
                        </Button>
                    ) : (
                        <Text fontWeight='bold' fontSize='lg'>Semua produk telah dimuat.</Text>
                    )
                )}
            </Stack>
        </Stack>
    )
}

export default StorePage