import React, { ReactNode, useEffect, useState } from 'react';
import {
    IconButton,
    Avatar,
    Box,
    CloseButton,
    Flex,
    HStack,
    Icon,
    useColorModeValue,
    Link,
    Drawer,
    DrawerContent,
    Text,
    useDisclosure,
    BoxProps,
    FlexProps,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    Stack,
    Accordion,
    Image,
    Spacer,
    Button,
    Tooltip,
    AccordionItem,
    AccordionButton,
    AccordionIcon,
    AccordionPanel,
    useToast,
    DrawerOverlay,
    DrawerHeader,
    DrawerBody,
    Divider,
    DrawerFooter,
    Spinner,
    Checkbox,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
} from '@chakra-ui/react';
import {
    FiChevronDown,
} from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import logoSide from '../../Assets/Image/logokomisi.png'
import { BsCart3 } from 'react-icons/bs';
import { BiCategory } from 'react-icons/bi'

import { data } from './DataMenu';
import { UseAuthStore } from '../../Hooks/Zustand/store';

import AppSearchInput from '../Search/AppSearchInput';
import { AiOutlineClose } from 'react-icons/ai';
import { formatFrice } from '../../Utils/numberUtil';
import axiosInstanceBarier from '../../Apis/axiosWithBarier';
import { DeleteIcon } from '@chakra-ui/icons';
import { IoMdMenu } from "react-icons/io";





export default function AppSideBar({
    children,
}: {
    children: ReactNode;
}) {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const globalState = UseAuthStore();

    const toast = useToast()

    const navigate = useNavigate()



    const handleLogout = () => {
        localStorage.clear();
        globalState.setTokens({})
        globalState.setUser({})
        globalState.setIsLoggedIn(false)
        navigate("/")

        toast({
            title: 'DapatKomisi',
            description: 'Berhasil Logout',
            status: 'success',
            isClosable: true,
            duration: 9000,
            position: 'top'

        });
        window.location.reload()

    }






    return (
        <Box  >
            <Drawer
                autoFocus={false}
                isOpen={isOpen}
                placement="left"
                onClose={onClose}
                returnFocusOnClose={false}
                onOverlayClick={onClose}
                size="full">
                <DrawerContent>
                    <SidebarContent isDesktop onClose={onClose} logoSide={logoSide}
                    />
                </DrawerContent>
            </Drawer>
            {/* mobilenav */}
            <MobileNav isScrolled onOpen={onOpen} handleLogout={handleLogout} globalState={globalState} />
            <Stack zIndex={100} pt={[150, 70, 70]} >
                {children}
            </Stack>
        </Box>
    );
}

interface SidebarProps extends BoxProps {
    onClose: () => void;
}

const SidebarContent = ({ onClose, logoSide, isDesktop, ...rest }: SidebarProps) => {



    const navigate = useNavigate()

    const handleNavigate = (x) => {
        navigate(x?.link)
        onClose()
    }





    return (
        <Box
            transition="2s ease"
            bg={useColorModeValue('white', 'gray.900')}
            borderRight="1px"
            borderRightColor={useColorModeValue('gray.200', 'gray.700')}
            w={'full'}
            pos="fixed"
            h="full"
            overflowY='scroll'
            {...rest}>
            <HStack h="20" alignItems="center" mx="2" justifyContent="space-between">
                <Image onClick={() => {
                    navigate('/')
                    onClose()
                }} w={['160px']} cursor='pointer' src={logoSide} />
                <Spacer />
                <Stack onClick={() => {
                    navigate('/category')
                    onClose()
                }}
                    cursor="pointer"
                    alignItems='center'
                    justifyContent={'center'}
                >
                    <Button colorScheme={'red'} size={'sm'} leftIcon={<BiCategory size={20} />} >
                        <Text>
                            Kategori
                        </Text>
                    </Button>
                </Stack>



                <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
            </HStack>

            <Stack
                alignItems={"center"}
                w='full'
                p={5}
            >

                <Accordion allowToggle w={'full'}>

                    {data.map((x, i) => (
                        <AccordionItem
                            key={i}
                        >
                            <h2>
                                <AccordionButton onClick={() => handleNavigate(x)} >
                                    {x.name ?
                                        <HStack spacing={2} align={'center'}>
                                            {/* <Icon as={x.icon}
                                                boxSize={isDesktop ? 5 : 7}
                                            /> */}
                                            {isDesktop && (
                                                <>
                                                    <Text
                                                        fontWeight={500}
                                                        fontSize="sm"
                                                        noOfLines={1}
                                                    >
                                                        {x.name}
                                                    </Text>
                                                </>
                                            )}
                                        </HStack>
                                        :
                                        <HStack spacing={2} w='full'>
                                            <Icon as={x.icon} boxSize={isDesktop ? 5 : 7} />
                                            {isDesktop && (
                                                <Text
                                                    fontWeight={500}
                                                    fontSize="sm"
                                                    noOfLines={1}
                                                >
                                                    {x.name}
                                                </Text>
                                            )}
                                            <Spacer />
                                            <AccordionIcon />

                                        </HStack>
                                    }
                                </AccordionButton>
                            </h2>
                            {x.submenu ? (
                                <>
                                    <AccordionPanel>
                                        <Stack>
                                            {x.submenu?.map((subitem, i) => (
                                                <Link to={subitem.link} key={i}>
                                                    <HStack spacing="3">
                                                        <Icon
                                                            as={subitem.icon}
                                                            boxSize={5}
                                                        />
                                                        {isDesktop && (
                                                            <>
                                                                <Text
                                                                    pl={3}
                                                                    fontWeight={500}
                                                                    fontSize="sm"
                                                                    noOfLines={1}
                                                                >
                                                                    {subitem.name}
                                                                </Text>
                                                            </>
                                                        )}

                                                    </HStack>
                                                </Link>
                                            ))}
                                        </Stack>
                                    </AccordionPanel>
                                </>
                            ) : (
                                <>{null}</>
                            )}
                        </AccordionItem>
                    ))}
                </Accordion>
            </Stack>



        </Box>
    );
};

// interface NavItemProps extends FlexProps {
//     icon: IconType;
//     children: ReactText;
// }
// const NavItem = ({ icon, linklabel, children, navigate, ...rest }: NavItemProps) => {
//     return (
//         <Stack onClick={() => navigate(linklabel)} style={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }}>
//             <Flex
//                 align="center"
//                 p="4"
//                 borderRadius="lg"
//                 role="group"
//                 cursor="pointer"
//                 _hover={{
//                     bg: 'transparent',
//                     color: 'cyan.400',
//                 }}
//                 {...rest}>
//                 {icon && (
//                     <Icon
//                         mr="4"
//                         fontSize="16"
//                         _groupHover={{
//                             color: 'cyan.400',
//                         }}
//                         as={icon}
//                     />
//                 )}
//                 {children}
//             </Flex>
//         </Stack>
//     );
// };

interface MobileProps extends FlexProps {
    onOpen: () => void;
}
const MobileNav = ({ onOpen, isScrolled, handleLogout, globalState, ...rest }: MobileProps) => {

    const navigate = useNavigate()
    const toast = useToast()
    const [placement] = React.useState('right')
    const [drawerCart, setDrawerCart] = useState(false)
    const [cartsItem, setCartsItem] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [loading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [totalPrice, setTotalPrice] = useState(0)

    const [checkedItems, setCheckedItems] = useState([])

    const handleCheckboxChange = (id, price, quantity) => {
        setCheckedItems((prevItems) => {
            if (prevItems.includes(id)) {
                const updatedItems = prevItems.filter((item) => item !== id);
                setTotalPrice((prevTotal) => prevTotal - (price * quantity));
                return updatedItems;
            } else {
                const updatedItems = [...prevItems, id];
                setTotalPrice((prevTotal) => prevTotal + (price * quantity));
                return updatedItems;
            }
        });
    }

    const handleCheckout = async () => {

        if (checkedItems.length === 0) {
            return toast({
                title: 'DapatKomisi',
                description: 'Kamu harus memilih produk mana yang ingin di checkout',
                status: 'warning',
                isClosable: true,
                duration: 9000,
                position: 'top'

            });
        }


        const filteredCartItems = cartsItem.filter((item) => checkedItems.includes(item?.variant?._id));


        const data = {
            items: filteredCartItems,
            promo: "xxx"
        }

        console.log(data, 'ini data')

        navigate("/orders", { state: data })

        handleCloseDrawer()



    }


    const handleOpenDrawer = () => {
        getDataCart()
        setDrawerCart(true)
    }

    const handleCloseDrawer = () => {
        setDrawerCart(false)
    }

    const getDataCart = async (page) => {
        if (globalState.isLoggedIn) {
            try {
                const res = await axiosInstanceBarier.get(`/cart?page=${page}`);
                console.log(res, 'ini res')

                if (page === 1) {
                    setCartsItem(res?.data || []); // Set data keranjang belanja pada halaman pertama
                } else {
                    setCartsItem((prevData) => [...prevData, ...(res?.data || [])]); // Tambahkan data baru ke data keranjang belanja sebelumnya
                }

                if (res?.data?.page >= res?.data?.total_pages) {
                    setHasMore(false); // Tidak ada lagi produk untuk dimuat
                }
            } catch (error) {
                console.log(error, 'ini error');
            } finally {
                globalState.setIsLoading(false);
            }
        }
    };

    const handleLoadMore = () => {
        if (hasMore) {
            const nextPage = currentPage + 1;
            setCurrentPage(nextPage);
            getDataCart(nextPage);
        }
    }

    const handleDelete = async (data) => {
        try {
            await axiosInstanceBarier.delete(`/cart/remove-from-cart?cart_id=${data._id}`);
            toast({
                title: 'DapatKomisi',
                description: 'Berhasil menghapus produk cart',
                status: 'success',
                isClosable: true,
                duration: 9000,
                position: 'top'
            });

            // Setelah berhasil menghapus dari server, perbarui cartsItem lokal
            const updatedCartItems = cartsItem.filter((item) => item._id !== data._id);
            setCartsItem(updatedCartItems);

        } catch (error) {
            console.log(error, 'ini error');
        }
    };


    useEffect(() => {
        if (!drawerCart) {
            setCartsItem([]);
            setCurrentPage(1); // Reset currentPage ke halaman pertama saat drawer ditutup
            setHasMore(true); // Set hasMore ke true untuk mengizinkan muatan data lagi saat drawer dibuka kembali
        }
    }, [drawerCart]); //


    return (
        <Stack>


            <Stack
                color="black"
                zIndex={100}
                transition={'all 0.3s ease-in-out'}
                position={'fixed'}
                w={'100%'}
                h='auto'
                spacing={0}
                bgColor={'white'}
                {...rest}
            >

                <Alert status='error' h={["30px", "30px", "40px"]}>
                    <AlertIcon />
                    <AlertTitle fontSize={["xs", "sm", "sm"]}>Dapatkomisi.com</AlertTitle>
                    <AlertDescription fontSize={["xs", "sm", "sm"]}>Beta Version</AlertDescription>
                </Alert>

                <Stack h={'30px'} px={2} bgColor='red.600' w='100%' alignItems={['center', 'center', 'flex-end']} justifyContent='center'>

                    <HStack color='white' spacing={[5, 5, 10]} px={1} display={{ base: 'none', md: 'flex' }}>


                        {data.length > 0 && data.map((x, index) => {
                            return (
                                <Stack key={index} onClick={() => navigate(x?.link)} >
                                    <Text cursor={'pointer'} fontWeight={500} fontSize='sm'>{x.name}</Text>
                                </Stack>
                            )
                        })}
                    </HStack>

                </Stack>

                <HStack
                    bgColor={'white'}
                    justifyContent={'space-evenly'}
                    px={[null, null, 20]}
                    w={'100%'}
                    h='60px'
                    boxShadow={isScrolled ? '0px 0px 10px rgba(0, 0, 0, 0.2)' : 'none'}
                >

                    <IconButton
                        display={{ base: 'flex', md: 'none' }}
                        onClick={onOpen}
                        variant="solid"
                        aria-label="open menu"
                        icon={<IoMdMenu />}
                        colorScheme='red'
                        size={['sm', 'md', 'md']}
                        ml={2}
                    />


                    <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'start' }} >

                        <HStack alignItems={'center'} justifyContent='center' spacing={0} px={1}>
                            <Image onClick={() => navigate('/')} w={['160px']} cursor='pointer' src={logoSide} />

                        </HStack>

                        <Stack onClick={() => navigate('/category')}
                            cursor="pointer"
                            alignItems='center'
                            justifyContent={'center'}
                            display={{ base: 'none', md: 'flex' }}
                        >
                            <Button colorScheme={'red'} size={'sm'} leftIcon={<BiCategory size={20} />} >
                                <Text>
                                    Kategori
                                </Text>
                            </Button>
                        </Stack>




                        <HStack justifyContent={'center'} w={'100%'} spacing={10} px={1} display={{ base: 'none', md: 'flex' }}>
                            <AppSearchInput />
                        </HStack>


                    </Flex>

                    {/* <Button onClick={() => console.log(globalState, 'xxx')}>check</Button> */}



                    <Stack p={2}>
                        {globalState.isLoggedIn ? (
                            <Stack
                                flex={{ base: 1, md: 0 }}
                                justify={'center'}
                                justifyContent='center'
                                direction={'row'}
                                spacing={5}>

                                <HStack alignItems={'center'} spacing={[-1, 0, 1]} >


                                    <div className="cart">
                                        <Tooltip label='Cart' fontSize='md'>

                                            <Stack>
                                                <IconButton
                                                    size="lg"
                                                    variant="ghost"
                                                    aria-label="open menu"
                                                    icon={<BsCart3 size={20} />}
                                                    onClick={handleOpenDrawer}
                                                />

                                            </Stack>
                                        </Tooltip>
                                    </div>

                                    <div className='profile'>
                                        <Menu display={{ base: 'none', md: 'flex' }}>
                                            <MenuButton
                                                py={2}
                                                transition="all 0.3s"
                                                _focus={{ boxShadow: 'none' }}>
                                                <Stack >
                                                    <HStack>

                                                        <HStack
                                                            spacing={2}
                                                        >
                                                            <Avatar
                                                                size={'sm'}
                                                                src={`https://api.dicebear.com/7.x/adventurer/svg?seed=${globalState.user.email}`}
                                                                name={globalState.user.email}
                                                            />
                                                        </HStack>
                                                        <Box display={{ base: 'none', md: 'flex' }}>
                                                            <FiChevronDown />
                                                        </Box>
                                                    </HStack>
                                                </Stack>

                                            </MenuButton>



                                            <MenuList
                                                bg={'white'}
                                                borderColor={'gray.200'}
                                            >

                                                <HStack alignItems={'flex-start'} p={3} bgColor='red.600' color={'white'} >
                                                    <Stack spacing={1} px={1}>
                                                        <Text fontWeight={'bold'} fontSize='sm' textTransform={'capitalize'}>{globalState?.user ? globalState?.user?.name : 'Log in to your account'}</Text>
                                                        <Stack spacing={2}>
                                                            <Text textTransform={'capitalize'} fontSize='sm'>
                                                                {
                                                                    globalState?.user?.role === 1 ? "admin" :
                                                                        globalState?.user?.role === 2 ? "produsen" :
                                                                            globalState?.user?.role === 3 ? "dropship" :
                                                                                globalState?.user?.role === 4 ? "affiliate" :
                                                                                    globalState?.user?.role === 6 ? "community" : "user"}
                                                            </Text>


                                                            {globalState?.user?.role !== 7 && (
                                                                <Button bgColor={"black"} size="sm" onClick={() => window.open("https://admin.dapatkomisi.com", "_blank")}>
                                                                    <Text textAlign="center" color={"white"} fontWeight={500}>Masuk halaman admin</Text>
                                                                </Button>
                                                            )}

                                                        </Stack>

                                                    </Stack>
                                                </HStack>

                                                <MenuItem onClick={() => navigate('/profile')}>
                                                    <Text fontSize={'sm'} color='gray.900'>Profile</Text>
                                                </MenuItem>

                                                <MenuItem onClick={() => navigate('/orders-list')}>
                                                    <Text fontSize={'sm'} color='gray.900'>List Pesanan</Text>

                                                </MenuItem>

                                                <MenuItem onClick={() => navigate('/transactions-list')}>
                                                    <Text fontSize={'sm'} color='gray.900'>List Transaksi</Text>

                                                </MenuItem>

                                                {/* <MenuItem onClick={() => navigate('/contact')}>
                                                    <Text fontSize={'sm'} color='gray.900'>Contact Us</Text>
                                                </MenuItem> */}

                                                <MenuDivider />
                                                <MenuItem onClick={() => handleLogout()}>
                                                    <Text fontSize={'sm'} color='gray.900'>Keluar</Text>

                                                </MenuItem>
                                            </MenuList>
                                        </Menu>
                                    </div>
                                </HStack>
                            </Stack>
                        ) : (
                            <Stack
                                flex={{ base: 1, md: 0 }}
                                justify={'flex-end'}
                                direction={'row'}
                                spacing={1}>
                                <Button
                                    as={'a'}
                                    display={{ base: 'flex', md: 'inline-flex' }}
                                    fontSize={'sm'}
                                    color='red.600'
                                    fontWeight={600}
                                    borderColor='red.600'
                                    borderWidth={'2px'}
                                    bgColor='transparent'
                                    cursor={'pointer'}
                                    _hover={{
                                        bg: 'red.600',
                                        color: 'white'
                                    }}
                                    size={['sm', 'md', 'md']}
                                    onClick={() => navigate('/login')}>
                                    Masuk
                                </Button>
                                <Button
                                    as={'a'}
                                    onClick={() => navigate('/signup')}
                                    display={{ base: 'flex', md: 'inline-flex' }}
                                    fontSize={'sm'}
                                    fontWeight={600}
                                    color={'white'}
                                    cursor='pointer'
                                    bg={'red.600'}
                                    size={['sm', 'md', 'md']}
                                    _hover={{
                                        bg: 'red.800',
                                    }}>
                                    Daftar
                                </Button>
                            </Stack>
                        )}
                    </Stack>
                </HStack>

                <Stack justifyContent={'center'} w={'100%'} px={3} display={['flex', 'flex', 'none']}>
                    <AppSearchInput />
                </Stack>



                <Drawer placement={placement} onClose={handleCloseDrawer} size="sm" isOpen={drawerCart}>
                    <DrawerOverlay />
                    <DrawerContent>
                        <DrawerHeader borderBottomWidth='1px'>
                            <HStack p={3}>
                                <Text>Cart</Text>
                                <Spacer />
                                <AiOutlineClose size={20} cursor={'pointer'} onClick={handleCloseDrawer} />

                            </HStack>
                        </DrawerHeader>
                        <DrawerBody>
                            <Stack spacing={5} w='auto'>
                                <Stack>
                                    {cartsItem?.length > 0 && (
                                        cartsItem?.map((x, index) => {
                                            return (
                                                <HStack key={index} spacing={5} bgColor='white' shadow={'lg'} p={2} borderRadius='md' m={1}>
                                                    <Stack>
                                                        <Checkbox
                                                            onChange={() => handleCheckboxChange(x?.variant?._id, x?.variant?.price, x?.quantity)}
                                                            size="lg"
                                                            bgColor={'gray.300'}
                                                            isChecked={checkedItems?.includes(x?.variant?._id)}
                                                        />
                                                    </Stack>
                                                    <Flex flexDirection={['column', 'row', 'row']} gap={3}>
                                                        <Image src={x?.variant?.images?.length > 0 ? x?.variant?.images[0] : 'https://picsum.photos/300/200'} w={'150px'} h='100px' objectFit={'cover'} borderRadius='md' />
                                                        <Stack>
                                                            <Text fontWeight={500} fontSize='sm' textTransform={'capitalize'}>{x?.variant?.title}</Text>
                                                            <Text fontSize={'sm'} color='gray.700'>Jumlah : {x?.quantity}</Text>
                                                            <Text fontWeight={'bold'}>Rp. {formatFrice(parseFloat(x?.variant?.price) || 0)} / pcs</Text>
                                                        </Stack>
                                                    </Flex>
                                                    <Spacer />
                                                    <Stack>
                                                        <DeleteIcon cursor={'pointer'} onClick={() => handleDelete(x)} />
                                                    </Stack>
                                                </HStack>
                                            )
                                        })
                                    )}

                                </Stack>

                                <Stack py={5} alignItems='center' justifyContent={'center'}>
                                    {loading ? (
                                        <Stack h='100%' w='100%' alignItems={'center'} justifyContent='center'>
                                            <Spinner />
                                        </Stack>
                                    ) : (
                                        hasMore ? (
                                            <Button
                                                onClick={handleLoadMore}
                                                bgColor='red.500'
                                                color='white'
                                                _hover={{ bgColor: 'red.600' }}
                                                _active={{ bgColor: 'red.700' }}
                                            >
                                                Lihat lebih banyak
                                            </Button>
                                        ) : (
                                            <Text fontWeight='bold' fontSize='lg'>Semua produk telah dimuat.</Text>
                                        )
                                    )}
                                </Stack>

                            </Stack>
                        </DrawerBody>


                        <Divider />


                        <DrawerFooter outline={0.5}>
                            <Stack w={'full'}>
                                <Stack>
                                    <Button variant={'outline'}>Makin hemat pakai promo</Button>
                                </Stack>
                                <Stack spacing={5}>
                                    <HStack>
                                        <Text textTransform={'uppercase'}>Subtotal</Text>
                                        <Spacer />
                                        <Text fontWeight={500}>IDR {formatFrice(parseFloat(totalPrice))}</Text>
                                    </HStack>
                                    <Text textAlign='center' fontSize={'xs'} color='gray.500'>Shipping, taxes, and discount codes calculated at checkout.
                                    </Text>
                                    <Button bgColor={'black'} color='white' onClick={() => handleCheckout()}>Checkout</Button>
                                </Stack>
                            </Stack>
                        </DrawerFooter>
                    </DrawerContent>

                </Drawer>


            </Stack>
        </Stack>
    );
};