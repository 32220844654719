import React from "react";
import PricingPage from "../Pages/Pricing/PricingPage";

const FrontRouter = [
  {
    path: "/pricing",
    element: <PricingPage />,
  },


];

export default FrontRouter;
