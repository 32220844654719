/* eslint-disable react-hooks/exhaustive-deps */
import {  Heading, HStack, SimpleGrid, Spacer, Stack, Text } from '@chakra-ui/react'

import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import ProductCard from '../../Components/Cards/ProductCard';
import axiosInstanceBarier from '../../Apis/axiosWithBarier';
import BreadCrumbComponent from '../../Components/BreadCrumbs/BreadCrumbComponent';
import { UseAuthStore } from '../../Hooks/Zustand/store';

function CategoryDetailPage() {


    const [categoryType, setCategoryType] = useState("")

    const [product, setProduct] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);


    const [categoryDetail, setCategoryDetail] = useState({})
    const globalState = UseAuthStore();


    const location = useLocation()
    const navigate = useNavigate()

    const categoryData = location?.state?._id



    const getDataCategory = async () => {
        if (!categoryData) return navigate("/category")
        try {
            const res = await axiosInstanceBarier.get(`/category/detail?category=${categoryData}`)
            setCategoryDetail(res.data)
        } catch (error) {
            console.log(error)
        }
    }


    const getProduct = async () => {
        globalState.setIsLoading(true)
        try {
            const res = await axiosInstanceBarier.get(`/product?page=${currentPage}&category=${categoryData}`);
            if (res?.data?.length > 0) {
                setProduct((prevProduct) => [...prevProduct, ...res.data]);
                setCurrentPage((prevPage) => prevPage + 1);
            } else {
                setHasMore(false); // Tidak ada lagi produk untuk dimuat
            }
        } catch (error) {
            console.log(error, ' ini error')
        }
        finally {
            globalState.setIsLoading(false)
        }
    }

    useEffect(() => {
        getProduct()

        return () => {
            setProduct([])
            setCurrentPage(1)
        }
    }, [])

    useEffect(() => {
        getDataCategory()

        return () => {
        }
    }, [])





    // const product = [
    //     { id: 1, title: "Altera Kaos Tshirt Getlost - S", price: "500000", rates: 100, location: "Jakarta Selatan", sold: 246 },
    //     { id: 2, title: "Altera Kaos Tshirt Shine - S", price: "203000", rates: 70, location: "Jakarta Selatan", sold: 246 },
    //     { id: 3, title: "Sticker Timbul Tutup Dop Velg BRABUS Big 4 Pcs", price: "20000", rates: 90, location: "Bekasi", sold: 246 },
    //     { id: 4, title: "APPLE WATCH SERIES 4 / 5 / 6 / 7 SECOND UNIT", price: "35000", rates: 100, location: "Depok", sold: 246 },
    //     { id: 5, title: "Shopatvelvet - Deus Shoulder T-shirt Black", price: "15000", rates: 80, location: "Jakarta Selatan", sold: 246 },
    //     { id: 6, title: "DMNT Jersey Oversize ", price: "202000", rates: 80, location: "Tangerang Selatan", sold: 246 },
    //     { id: 7, title: "Apple Watch series 7 41mm second", price: "159000", rates: 80, location: "depok", sold: 246 },
    //     { id: 8, title: "Animous Kaos Tshirt Chikapale", price: "299000", rates: 80, location: "bekasi", sold: 246 },
    // ]

    // const categoryList = [
    //     { name: 'fashion muslim' },
    //     { name: 'fashion pria' },
    //     { name: 'fashion wanita' },
    //     { name: 'film & musik' },
    //     { name: 'buku' },
    //     { name: 'dapur' },
    //     { name: 'kecantikan' },
    //     { name: 'kesehatan' },
    //     { name: 'komputer & aksesoris' },
    //     { name: 'laptop & aksesoris' },
    //     { name: 'ibu & bayi' },
    //     { name: 'kamera' },
    // ]






    return (
        <Stack spacing={5}>
            <SimpleGrid columns={[1, 1, 2]} gap={3}>
                <Stack color={categoryType === "digital" ? "red.100" : "white"}
                    width={['auto', 'full']}
                    backgroundImage="url('https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/Black%20White%20Modern%20Photography%20Tips%20YouTube%20Thumbnail.png?alt=media&token=7780fdee-5d7d-4698-8322-a03370ea98df')"
                    backgroundSize="cover"
                    backgroundPosition="center"
                    h="200px" cursor={"pointer"} onClick={() => setCategoryType("digital")} p={5} borderRadius="md">
                    <Heading >Digital</Heading>
                </Stack>
                <Stack color={categoryType === "non-digital" ? "red.100" : "white"}
                    width={['auto', 'full']}
                    backgroundImage="url('https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/Promotion%20Fashion%20Boutique%20Instagram%20Post.png?alt=media&token=109b7906-95a7-4865-b07c-851aabceec69')"
                    backgroundSize="cover"
                    backgroundPosition="center"
                    h="200px" cursor={"pointer"} onClick={() => setCategoryType("non-digital")} p={3} borderRadius="md">
                    <Heading >Non-digital</Heading>
                </Stack>
            </SimpleGrid>
            <Stack spacing={5}>
                <HStack>
                    <BreadCrumbComponent />
                    <Spacer />
                    <Text textTransform={'capitalize'} fontWeight={"bold"} fontSize='xl'>Category {categoryDetail.name}</Text>

                </HStack>

                <Text fontWeight={500} fontSize='xl'>Product List</Text>

            </Stack>
            {/* <Grid templateColumns={{ base: '1fr', md: '1fr 3fr' }} >
                <Stack cursor={'pointer'} p={2} alignItems='center' justifyContent={'start'}>
                    <Image borderRadius='md' objectFit={'cover'} w={'300px'} height='300px' src='https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/Screen%20Shot%202023-09-22%20at%2000.55.33.png?alt=media&token=27a5756e-eb66-42e6-9bd6-9e6e8ca5a20b' />
                </Stack>
                <Stack alignItems={'center'} justifyContent='center' overflowX="scroll" width="100%" p={2}>
                    <Flex flexDirection="row" flexWrap="wrap" gap={5}>
                        {categoryList.length > 0 &&
                            categoryList.map((x, index) => {
                                return (
                                    <Stack
                                        key={index}
                                        borderRadius={'md'}
                                        p={2}
                                        borderWidth={0.5}
                                        borderColor='gray.300'
                                        bgColor={'white'}
                                        w='80px'
                                        h={'auto'}
                                        shadow='md'
                                        alignItems={'center'}
                                        justifyContent='flex-start'
                                        mr={2}
                                        cursor='pointer'
                                        mb={2}
                                        onClick={() => navigate(`${x.name}`, { state: x })}
                                    >
                                        <FcLock size={50} />
                                        <Text fontSize={'xx-small'} textAlign='center' fontWeight={500} textTransform='capitalize'>{x.name}</Text>
                                    </Stack>
                                );
                            })}
                    </Flex>
                </Stack>

            </Grid> */}

            <Stack spacing={0}>
                <SimpleGrid columns={[2, 3, 4]} spacing={3} py={5}>
                    {product.length > 0 && (
                        product.map((x, index) => {
                            return (
                                <ProductCard index={index} data={x} />
                            )
                        })
                    )}
                </SimpleGrid>
            </Stack>




        </Stack>

    )
}

export default CategoryDetailPage