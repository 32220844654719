import React from "react";
import HomePage from "../Pages/Home/HomePage";

const DashboardRouter = [
  {
    path: "/",
    element: (
        <HomePage />
    ),
  },
];

export default DashboardRouter;
