/* eslint-disable react-hooks/exhaustive-deps */
import { HStack, Image, Stack } from '@chakra-ui/react'

import React, { useEffect, useState } from 'react'

import { UseAuthStore } from "../../Hooks/Zustand/store";
import axiosInstanceBarier from '../../Apis/axiosWithBarier';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../Apis/axiosWithNoBarier';




function ListCommunity() {

    const [community, setCommunity] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);

    const [store, setStore] = useState([])
    const [adminStore, setAdminStore] = useState('')


    const globalState = UseAuthStore();

    const image_data = [
        { id: "6566c16dadcbf500398a32bc", image: "https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/WhatsApp%20Image%202023-11-29%20at%2014.57.45.jpeg?alt=media&token=d5898712-f326-4d34-9aa1-0d44870e4dde" },
        { id: "656484456b7d3e00380dd630", image: "https://sahitya.id/wp-content/uploads/2022/10/PLN-2.jpg" },
        { id: "654765c8b5d4d49a798b4f96", image: "https://ecsys.okoce.net/assets/logo/logo_ok-oce-forever.jpg" },
        { id: "654765bdb5d4d49a798b4f93", image: "https://yt3.googleusercontent.com/CDHjw4x1ZdzKipkfsRw2VHfgojxreI_IbnqKH_U7L5rMs3hU7xJuo0uaPjGy_pVnJ2lZYTvvfrQ=s176-c-k-c0x00ffffff-no-rj" },
        { id: "65476540b5d4d49a798b4f8d", image: "https://upload.wikimedia.org/wikipedia/id/d/d4/TDA.png" },
        { id: "65673583adcbf500398a3d44", image: "https://play-lh.googleusercontent.com/mD10aGgL6MU_-r3YmOYC_nEqc4ihe84tA3mAO3fOPDVMd6I56tYNULDCFqVmf8wDfw" },
        { id: "65476673b5d4d49a798b522c", image: "https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/Screen%20Shot%202023-09-22%20at%2000.55.33.png?alt=media&token=27a5756e-eb66-42e6-9bd6-9e6e8ca5a20b" },
        { id: "6564c60beccee55e687b02ff", image: "https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/SjXJnxm1_400x400.jpeg?alt=media&token=78daded4-ee7f-40dd-a207-746eea2459cf" },

    ]


    const getCommunity = async () => {
        globalState.setIsLoading(true)
        try {
            const res = await axiosInstance.get(`/community/list?page=${currentPage}`);
            if (res?.data?.length > 0) {
                setCommunity((prevProduct) => [...prevProduct, ...res.data]);
                setCurrentPage((prevPage) => prevPage + 1);
            }
        } catch (error) {
            console.log(error, ' ini error')
        }
        finally {
            globalState.setIsLoading(false)
        }
    }




    const getDataStore = async () => {
        globalState.setIsLoading(true)
        try {
            const res = await axiosInstanceBarier.get(`/store?&page=1&reseller=true`);
            console.log(res, 'ini ress')
            if (res?.data?.length > 0) {
                setAdminStore(res.admin_store)
                setStore(res?.data);
            }
        } catch (error) {
            console.log(error, ' ini error')
        }
        finally {
            globalState.setIsLoading(false)
        }
    }


    useEffect(() => {
        getCommunity()
        getDataStore()

        return () => {
        }
    }, [])



    return (
            <Stack flex={1}>
                <HStack gap={3}  width='100%' overflowX={'scroll'} >
                    {community?.length > 0 && (
                        community?.slice().sort((a, b) => {
                            // Pindahkan item dengan id tertentu ke depan
                            if (a._id === '6566c16dadcbf500398a32bc') return -1; // return -1 untuk memindahkan ke depan
                            return 0;
                        })
                            .map((x, index) => {
                                return (
                                    <Stack key={index} alignItems={'center'} >
                                        <Stack alignItems={'center'} justifyContent={'center'} shadow='md' w={['50px']} h={'50px'} borderRadius='xl' spacing={2} bgColor='white' >
                                                <Image
                                                    src={x.image || image_data.find((data) => data.id === x._id)?.image || 'https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/WhatsApp%20Image%202024-02-07%20at%2014.43.30.jpeg?alt=media&token=d23f664f-8996-4f0b-86de-cfc88422385a'}
                                                    alt='img'
                                                    borderRadius='md'
                                                    style={{ width: '100% ', objectFit: 'contain', h: '50px' }}
                                                // onLoad={() => setIsLoading(false)}
                                                // onError={() => setIsLoading(false)} 
                                                />

                                            </Stack>
                                    </Stack>
                                )
                            })
                    )}
                </HStack>
            </Stack>

    )
}

export default ListCommunity