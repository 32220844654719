import { Container, Flex, Stack } from "@chakra-ui/react";
import { Fragment } from "react";
import AppFooter from "../Components/Footers/AppFooter";
import AppSideBar from "../Components/Sidebar/AppSideBar";
import themeConfig from "../Config/themeConfig";

function Layout({ children }) {


  return (
    <Fragment>
      <Stack height="100vh" w={"full"}>
        <AppSideBar />

        <Stack maxW={"full"} pt={"12"}>
          <Container  minH={"95vh"} maxW={['full', 'full', '6xl']}>
            {children}
          </Container>
        </Stack>

        <AppFooter />
      </Stack>
    </Fragment>
  );
}

export default Layout;
