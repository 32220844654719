import { Box, Button, Heading, Image, Stack, Text } from '@chakra-ui/react'
import React from 'react'
import { useNavigate } from 'react-router-dom'

function RegisterCard({ data }) {
    const navigate = useNavigate()
    return (
        <Stack p={2} bgColor={'white'} borderRadius={'md'}>
            <Image src={data.image} w={'full'} />
            <Heading size={'md'}>{data?.title}</Heading>
            <Text fontSize={'sm'}>{data.hook}</Text>
            <Text fontSize={('sm')}>{data.description}</Text>
            <Stack alignItems={'center'} justifyContent={'center'}>
                <Box>
                    <Button size={'sm'} colorScheme='red' onClick={() => navigate(data.link)}>Daftar</Button>
                </Box>
            </Stack>
        </Stack>
    )
}

export default RegisterCard