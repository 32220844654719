import { Divider, HStack, Image, SimpleGrid, Spacer, Stack, Tag, Text } from '@chakra-ui/react'
import React, { useState } from 'react'
import { AiFillStar } from 'react-icons/ai'
import { FcShipped } from 'react-icons/fc';
import { useNavigate } from 'react-router-dom';
import { formatFrice } from "../../Utils/numberUtil";


function ProductCard({ data, index }) {
  const navigate = useNavigate()
  const [displayText, setDisplayText] = useState((data?.store?.address?.address?.city)?.toLowerCase() || "Jakarta Selatan");




  const handleMouseEnter = () => {
    // Ketika kursor masuk ke dalam teks, ganti teks yang akan ditampilkan
    setDisplayText(data?.store?.name || "UMKM Indonesia");
  };

  const handleMouseLeave = () => {
    // Ketika kursor meninggalkan teks, kembalikan teks yang akan ditampilkan
    setDisplayText((data?.store?.address?.address?.city)?.toLowerCase() || "jakarta selatan");
  };

  return (
    <Stack key={index} onClick={() => navigate(`/product/${data?._id}`)} >
      <Stack shadow='md' w={["250px"]} h='400px' borderRadius='xl' spacing={2} bgColor='white' _hover={{ transform: 'scale(1.05)' }} transition='0.3s ease-in-out'>
        {data && data.variants && data.variants[0] && data.variants[0].images && data.variants[0].images.length > 0 ? (
          <Stack alignItems='center' cursor='pointer' objectFit='cover' onClick={() => console.log('product')}>
            <Image
              src={data.variants[0].images[0] || ''}
              alt='img'
              borderRadius='md'
              style={{ width: '100%', height: '180px', objectFit: 'cover' }}
            />
          </Stack>
        ) : (
          <Stack alignItems='center' cursor='pointer' objectFit='cover' onClick={() => console.log('product')}>
            <Image
              src={'https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/default%2FWhatsApp%20Image%202024-02-13%20at%2023.06.48.jpeg?alt=media&token=065b4b7c-7f20-463f-8e17-ae0a1fd5a3b7'}
              alt='img'
              borderRadius='md'
              style={{ width: '100%', height: '180px', objectFit: 'cover' }}
            />
          </Stack>
        )}


        <Stack px={3}>

          <SimpleGrid columns={[1, 2, 2]} >

            <Stack alignItems={'start'} >
              <Tag size='sm' textTransform={'capitalize'} textAlign={'center'} colorScheme='green'>Terlaris </Tag>
            </Stack>




          </SimpleGrid>


          <Text textTransform='capitalize' fontWeight={500} fontSize='sm' noOfLines={2}>
            {data?.title || ""}
          </Text>


          <Stack >
            {data?.variants?.length > 0 && (
              <Text fontSize={'sm'} fontWeight='bold'>Rp. {formatFrice(parseFloat(data?.variants[0]?.price || 0))}</Text>

            )}

          </Stack>



          <Stack >
            <HStack
              alignItems={'center'}
              spacing={2}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <FcShipped />
              <Text
                fontSize={'sm'}
                color='gray.400'
                fontWeight={500}
                textTransform='capitalize'
              >
                {displayText}
              </Text>
            </HStack>

          </Stack>
        </Stack>

        <Spacer />


        <HStack alignItems={'center'} px={3} justifyContent='flex-end'>
          <HStack spacing={0}>
            <AiFillStar size={10} color='orange' />
          </HStack>
          <Text fontSize={'xs'} color='gray.500'>{data?.rating}|</Text>
          <Text fontSize={'xs'} color='gray.500'>{data?.sold || 246} ++</Text>
        </HStack>

        <Stack p={3}>
          <Divider />
          <Text fontSize={'xx-small'} color={'gray.400'} fontWeight={"bold"}>Masih Tersedia</Text>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default ProductCard