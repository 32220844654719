import React from "react";
import ProdusenPage from "../Pages/Produsen/ProdusenPage";

const ProdusenRouter = [

  {
    path: "/produsen",
    element: <ProdusenPage />,
  },

];

export default ProdusenRouter;
