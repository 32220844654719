import React from "react";
import ProductDetailPage from "../Pages/Products/ProductDetailPage";

const ProductRouter = [

  {
    path: "/product/:id",
    element: <ProductDetailPage />,
  },

];

export default ProductRouter;
