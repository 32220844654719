import { Box, Stack, Input, SimpleGrid, Text, Textarea, Select, Button, useToast, Image, HStack, Heading, Avatar, Spacer } from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import { MdOutlineMail, MdOutlineMap, MdSmartphone } from 'react-icons/md';
import Occupation from "../../Config/Json/Occupation.json"
import { FaRegAddressCard } from 'react-icons/fa';
import { GiSandsOfTime } from 'react-icons/gi';
import { ImDropbox } from 'react-icons/im';
import AddressIndex from '../Address/AddressIndex';

function FormBiodataProfile({ dataUser }) {
    const [city, setCity] = useState('');
    const [cityArr, setCityArr] = useState([]);
    const [formData, setFormData] = useState({
        name: dataUser?.name,
        phone: dataUser?.phone_number,
        email: dataUser?.email,
        role: dataUser?.role,
        address: '',
        city: '',
        occupation: '',
        date_of_birth: '',
        ktp_image: '',
    });




    const toast = useToast();

    const getData = async () => {
        try {
            // const res = await _axios.get('api/me');
            const res = ""
            if (res.status === true) {
                setFormData({
                    ...formData,
                    name: res.data.name,
                    email: res.data.email,
                    phone: res.data.phone,
                    ktp_image: res.data.detail.ktp_image,
                    ktp_number: res.data.detail.ktp_number,
                    npwp_number: res.data.detail.npwp_number,
                    npwp: res.data.detail.npwp_image,
                    address: res.data.detail.address,
                    date_of_birth: res.data.detail.date_of_birth,
                    occupation: res.data.detail.occupation,
                    city: res.data.detail.city,
                });
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleChangeForm = (e) => {
        if (e.target.name) {
            const { name, value } = e.target;
            setFormData({ ...formData, [name]: value });
        }
        if (e.target.files) {
            const uploadFile = e.target.files[0];
            onChangeKtpFile(uploadFile);
            onChangeNpwpFile(uploadFile);
        }
    };

    const getDataSearch = async () => {
        try {
            // const res = await _axios.get(`/api/address/search?keyword=${city}`);
            setCityArr("");
        } catch (err) {
            console.error(err);
        }
    };

    const handleSubmitForm = async () => {
        try {
            // const response = await _axios.put('api/update-user', formData);
            // if (response.status === true) {
            //     toast({
            //         title: 'importir.com',
            //         description: response.message,
            //         status: 'success',
            //     });
            //     getData();
            // }
        } catch (error) {
            toast({
                title: 'Dapatkomisi.com',
                description: error,
                status: 'error',
            });
        }
    };

    const onChangeKtpFile = async (event) => {
        try {
            const params = new FormData();
            params.append('file', event);

            // const response = await _axios.post('api/storage', params);
            // if (response.status === true) {
            //     setFormData({ ...formData, ktp_image: response.data });
            // }
        } catch (error) {
            console.log(error);
            throw error;
        }
    };

    const onChangeNpwpFile = async (event) => {
        try {
            const params = new FormData();
            params.append('file', event);

            // const response = await _axios.post('api/storage', params);
            // if (response.status === true) {
            //     setFormData({ ...formData, npwp: response.data });
            // }
        } catch (error) {
            console.log(error);
            throw error;
        }
    };

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        getDataSearch();
        return () => {
            setCityArr([]);
        };
    }, [city]);


    return (
        dataUser === undefined ?
            <Stack>
                <SimpleGrid columns={2} spacing={10}>
                    <Box>
                        <Text mb='8px'>Phone Number</Text>
                        <Input placeholder='Input No Telepon' name='phone' defaultValue={formData.phone} onChange={handleChangeForm} />
                    </Box>
                    <Box>
                        <Text mb='8px'>Name</Text>
                        <Input placeholder='Input Nama' name="name" defaultValue={formData.name} onChange={handleChangeForm} />
                    </Box>
                    <Box>
                        <Text mb='8px'>Email</Text>
                        <Input placeholder='Input Email' defaultValue={formData.email} disabled />
                    </Box>
                    <Box>
                        <Text mb='8px'>City/Disctrict</Text>
                        <Input
                            id="city"
                            onChange={(e) => setCity(e.target.value)}
                            placeholder="City/District. Ex: Jakarta"
                            name='city'
                            defaultValue={formData.city}
                        />
                    </Box>
                    {city?.length > 0 && (
                        <Box>
                            <Text>Choose Address</Text>
                            <Select fontSize='md' bgColor={'white'} placeholder="Pilih alamat" onChange={handleChangeForm} name='city'>
                                {cityArr.map((x, index) => (
                                    <option value={x} key={index}>{x}</option>
                                ))}
                            </Select>
                        </Box>
                    )}
                    <Box>
                        <Text mb='8px'>Date/Birth</Text>
                        <Input type={"date"} name="date_of_birth" defaultValue={formData.date_of_birth} onChange={handleChangeForm} />
                    </Box>
                    <Box>
                        <Text mb='8px'>Job</Text>
                        <Select placeholder='Pilih Pekerjaan' value={formData.occupation} onChange={handleChangeForm} name='occupation'>
                            {Occupation.map((item, index) => {
                                return (
                                    <option key={index} value={item.value}>{item.text}</option>
                                )
                            })

                            }
                        </Select>
                    </Box>
                    <Box>
                        <Text mb='8px'>No. KTP</Text>
                        <Input placeholder='Basic usage' name='ktp_number' defaultValue={formData.ktp_number} onChange={handleChangeForm} />
                    </Box>
                    <Box>
                        <Text mb='8px'>No. NPWP (opsional)</Text>
                        <Input placeholder='Basic usage' name='npwp_number' defaultValue={formData.npwp_number} onChange={handleChangeForm} />
                    </Box>
                    <Box>
                        <Text mb='8px'>Image KTP</Text>
                        <Input type='file' name='ktp_image' id='ktp' keyLabel="ktp" onChange={handleChangeForm} />
                        {
                            formData.ktp_image !== "" ?
                                <Image src={formData.ktp_image} boxSize='150px' objectFit='cover' mt={5} />
                                :
                                ""
                        }
                    </Box>
                    <Box>
                        <Text mb='8px'>Image NPWP (opsional)</Text>
                        <Input type='file' name='npwp' id='npwp' keyLabel="npwp" defaultValue={formData.npwp} onChange={onChangeNpwpFile} />
                        {
                            formData.npwp !== "" ?
                                <Image src={formData.npwp} boxSize='150px' objectFit='cover' mt={5} alt='image not found' />
                                :
                                ""
                        }
                    </Box>
                </SimpleGrid>
                <SimpleGrid columns={1} spacing={10}>
                    <Box mt={5}>
                        <Text mb="8px">Address</Text>
                        <Textarea
                            placeholder="Input Alamat"
                            name="address"
                            value={formData.address}
                            onChange={handleChangeForm}
                        />
                    </Box>
                </SimpleGrid>

                <Button onClick={() => handleSubmitForm()}>Submit</Button>
            </Stack>
            :
            <Stack bgColor={'white'} borderRadius='md' shadow={'md'} w='100%'>
                <Stack spacing={5} p={[1, 1, 5]}>

                    <HStack>
                        <Text fontSize={'xl'} fontWeight='bold'>{formData?.name}</Text>
                    </HStack>

                    <HStack alignItems={'center'}>
                        <MdOutlineMail size={20} />
                        <Text>{formData?.email}</Text>
                    </HStack>

                    <HStack alignItems={'center'}>
                        <MdSmartphone size={20} />
                        <Text>{formData?.phone}</Text>

                    </HStack>

                    <HStack alignItems={'center'}>
                        <ImDropbox size={20} />
                        <Text textTransform={"capitalize"}>{
                        dataUser?.role === 1 ? "admin" : 
                        dataUser?.role === 2 ? "produsen" : 
                        dataUser?.role === 3 ? "dropship" : 
                        dataUser?.role === 4 ? "affiliate" : 
                        dataUser?.role === 6 ? "community" : "user"}</Text>
                    </HStack>

                    <HStack alignItems={'center'}>
                        <FaRegAddressCard size={20} />
                        <Text>{dataUser?.marking_code}</Text>
                    </HStack>

                    <HStack alignItems={'center'}>
                        <MdOutlineMap size={20} />
                        <Text>---</Text>
                    </HStack>

                    <HStack alignItems={'center'}>
                        <GiSandsOfTime size={20} />
                        <Text>{dataUser?.package?.expired_at}</Text>
                    </HStack>


                </Stack>

                <Stack >
                    <AddressIndex />
                </Stack>

            </Stack>
    )
}

export default FormBiodataProfile