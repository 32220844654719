import {
  Box,
  Button,
  Heading,
  HStack,
  Icon,
  Image,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  Spacer,
  Spinner,
  Stack,
  Text,
  useBreakpointValue,
  useToast,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import logo from "../../Assets/Image/logo2.png";
import {
  MdEmail,
  MdVisibility,
  MdVisibilityOff,
  MdVpnKey,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { UseAuthStore } from "../../Hooks/Zustand/store";
import { checkLoginData } from "../../Hooks/Middleware/UserMiddleWare";
import axiosInstance from "../../Apis/axiosWithNoBarier";
// import { LoginUser } from "../../Hooks/Contexts";

const Blur = (props: IconProps) => {
  return (
    <Icon
      width={useBreakpointValue({ base: '100%', md: '40vw', lg: '30vw' })}
      zIndex={useBreakpointValue({ base: -1, md: -1, lg: 0 })}
      height="560px"
      viewBox="0 0 528 560"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <circle cx="71" cy="61" r="111" fill="#F56565" />
      <circle cx="244" cy="106" r="139" fill="#ED64A6" />
      <circle cy="291" r="139" fill="#ED64A6" />
      <circle cx="80.5" cy="189.5" r="101.5" fill="#ED8936" />
      <circle cx="196.5" cy="317.5" r="101.5" fill="#ECC94B" />
      <circle cx="70.5" cy="458.5" r="101.5" fill="#48BB78" />
      <circle cx="426.5" cy="-0.5" r="101.5" fill="#4299E1" />
    </Icon>
  )
}


function LoginPage() {

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [dataAccount, setDataAccount] = useState({
    email: "",
    password: "",
  })

  const globalState = UseAuthStore();





  const toast = useToast();
  const navigate = useNavigate();

  if (globalState?.isLoggedIn) {
    return navigate('/')
  }

  const handleAddData = (e) => {
    const { id, value } = e.target
    setDataAccount({ ...dataAccount, [id]: value })
  }


  const handleLogin = async () => {

    const validationResult = checkLoginData(dataAccount);
    if (!validationResult.success) {
      toast(validationResult.error);
      return;
    }

    localStorage.clear();

    setLoading(true)
    try {
      const res = await axiosInstance.post('/auth/login', dataAccount)
      if (res) {


        localStorage.setItem("tokens", JSON.stringify(res.tokens));
        localStorage.setItem("user", JSON.stringify(res.user));
        localStorage.setItem("isLoggedIn", true);


        globalState.setTokens(res.tokens)
        globalState.setUser(res.user)
        globalState.setIsLoggedIn(true)



        toast({
          title: 'DapatKomisi',
          description: 'Berhasil login',
          position:'top',
          status: 'success',
          isClosable: true,
          duration: 9000
        });




        navigate('/')

      }


    } catch (error) {
      console.log(error)

      toast({
        title: 'DapatKomisi',
        description: error?.response?.data?.message,
        status: 'error',
        isClosable: true,
        duration: 9000,
        position:'top'

      });

    }
    finally {
      setLoading(false)
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };



  const height = window.innerHeight;

  return (
    <>
      <Stack
        spacing={10}
        pt={20}
        minH={height}
        bgColor='red.500'
        bgSize="cover"
        alignItems={"center"}
        justifyContent="center"
      >
        <Blur position={'absolute'} top={-10} left={-10} style={{ filter: 'blur(70px)' }} />

        <Stack>
          <Stack alignItems={"center"} justifyContent="center">
            <Stack
              justifyContent="center"
              alignItems="center"
              spacing={5}
              pb={10}
              bg={'white'}
              p={10}
              borderRadius="xl"
            >
              <Box>
                <Image
                  w="200px"
                  borderRadius={20}
                  src={logo}
                  alt="Alternate Text"
                />
              </Box>
              <Spacer />
              <Stack alignItems="center">
                <InputGroup
                  w={{
                    base: "100%",
                    md: "285",
                  }}
                >
                  <InputLeftAddon
                    shadow={"md"}
                    children={<MdEmail name="email" size={24} color="black" />}
                  />
                  <Input
                    w={{
                      base: "100%",
                      md: "100%",
                    }}
                    placeholder="email"
                    fontSize={"md"}
                    shadow={3}
                    bgColor={"white"}
                    color={"black"}
                    id='email'
                    onChange={(e) => handleAddData(e)}
                  />
                </InputGroup>
              </Stack>
              <Stack alignItems="center">
                <InputGroup
                  w={{
                    base: "92%",
                    md: "92%",
                  }}
                >
                  <InputLeftAddon
                    shadow={"md"}
                    children={<MdVpnKey size={24} color="black" />}
                  />
                  <Input
                    w={{
                      base: "100%",
                      md: "100%",
                    }}
                    placeholder="password"
                    fontSize={"md"}
                    shadow={"md"}
                    type={showPassword ? "text" : "password"}
                    bgColor={"white"}
                    color={"black"}
                    id='password'
                    onChange={(e) => handleAddData(e)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleLogin();
                      }
                    }}
                  />
                  <InputRightElement>
                    {showPassword ? (
                      <MdVisibilityOff
                        size={20}
                        color="black"
                        onClick={togglePasswordVisibility}
                        style={{ cursor: "pointer" }}
                      />
                    ) : (
                      <MdVisibility
                        size={20}
                        color="black"
                        onClick={togglePasswordVisibility}
                        style={{ cursor: "pointer" }}
                      />
                    )}
                  </InputRightElement>
                </InputGroup>
              </Stack>

              <Stack pt={5}>
                {loading ? (
                  <Spinner size={"sm"} />
                ) : (
                  <HStack>
                    <Button
                      fontFamily={'heading'}
                      mt={8}
                      w={'full'}
                      bgGradient="linear(to-r, red.400,pink.400)"
                      color={'white'}
                      _hover={{
                        bgGradient: 'linear(to-r, red.400,pink.400)',
                        boxShadow: 'xl',
                      }}
                      onClick={() => navigate("/reset-password")}
                      px={10}
                    >
                      <Text color={"white"} fontSize="xs" fontWeight="bold">
                        LUPA PASSWORD
                      </Text>
                    </Button>
                    <Button
                      fontFamily={'heading'}
                      mt={8}
                      w={'full'}
                      bgGradient="linear(to-r, red.400,pink.400)"
                      color={'white'}
                      _hover={{
                        bgGradient: 'linear(to-r, red.400,pink.400)',
                        boxShadow: 'xl',
                      }}
                      onClick={() => handleLogin()}
                    >
                      <Text color={"white"} fontSize="xs" fontWeight="bold">
                        MASUK
                      </Text>
                    </Button>
                  </HStack>
                )}
              </Stack>

              <Spacer />

              <HStack space={1}>
                <Text color={"gray.700"} fontSize={["xs", null, "sm"]}>
                  Belum punya akun ?
                </Text>
                <Text
                  color={"gray.700"}
                  fontSize={["xs", null, "sm"]}
                  cursor="pointer"
                  fontWeight="bold"
                  onClick={() => navigate("/signup")}
                >
                  Daftar sekarang !
                </Text>
              </HStack>
            </Stack>
          </Stack>
          <Spacer />

          <Stack>{/* <AppSponsor /> */}</Stack>
        </Stack>
      </Stack>

    </>
  );
}

export default LoginPage;
