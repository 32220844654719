import React from "react";
import StoreDetailPage from "../Pages/Store/StoreDetailPage";
import StorePage from "../Pages/Store/StorePage";

const StoreRouter = [

  {
    path: "/store",
    element: <StorePage />,
  },

  {
    path: "/store/:id",
    element: <StoreDetailPage />,
  },

];

export default StoreRouter;
