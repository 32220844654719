import { onAuthStateChanged } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { auth } from "../../Config/firebase";
import { create } from "zustand";


export const UseAuthStore = create((set, get) => ({
  isLoggedIn: false,
  user : {},
  tokens : {},
  isLoading: false,
  

  setTokens: (data) => {
    set({tokens: data});
  },

  setUser: (data) => {
    set({user: data});
  },

  setIsLoggedIn: (data) => {
    set({isLoggedIn : data});
  },

  setIsLoading: (data) => {
    set({isLoading : data});
  },
  
}));
