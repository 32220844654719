import { HStack, SimpleGrid, Stack, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import axiosInstanceBarier from '../../Apis/axiosWithBarier'
import ProductCard from '../Cards/ProductCard'

function ProductReccomended({ id }) {

    const [dataRecommended, setDataRecommended] = useState([])



    const getDataRecommended = async () => {
        try {
            const res = await axiosInstanceBarier(`/product/recomended?id=${id}`)
            setDataRecommended(res?.data)
        } catch (error) {
            console.log(error, 'ini error')
        }
    }

    useEffect(() => {
        getDataRecommended()

        return () => {
            setDataRecommended([])
        }
    }, [id])


    return (
        <Stack p={[5]} bgColor='gray.200' borderRadius={'md'} minH='300px'>
            <Text fontWeight={'bold'}>Produk rekomendasi produsen ini</Text>
            <HStack w={"auto"} overflowX="scroll" spacing={3} py={5}>
                {dataRecommended?.length > 0 && (
                    dataRecommended.map((x, index) => {
                        return (
                            <ProductCard index={index} data={x} />
                        )
                    })
                )}
            </HStack>
        </Stack>
    )
}

export default ProductReccomended