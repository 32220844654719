/* eslint-disable react-hooks/exhaustive-deps */
import { Avatar, AvatarGroup, Button, Grid, Heading, HStack, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spacer, Stack, Text, Tooltip } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import axiosInstanceBarier from '../../Apis/axiosWithBarier';
import { UseAuthStore } from '../../Hooks/Zustand/store';
import ProductStoreCard from '../Cards/ProductStoreCard';
// import logoSide from '../../Assets/Image/logopngkomisi.png'
import ResellerList from '../../Reseller/ResellerList';
import { FiArrowRightCircle } from 'react-icons/fi';


function StoreHome() {


    const [product, setProduct] = useState([]);
    const [store, setStore] = useState({});


    const [resellerList, setResellerList] = useState([])
    const [modalReseller, setModalReseller] = useState(false)



    const navigate = useNavigate()


    const globalState = UseAuthStore();





    const getProduct = async () => {
        globalState.setIsLoading(true)
        try {
            const res = await axiosInstanceBarier.get(`/store/recomended?type=reseller`);
            console.log(res, 'ini res pertama');
            setStore(res?.store);
            setProduct(res?.product)
        } catch (error) {
            console.log(error, ' ini error')
        }
        finally {
            globalState.setIsLoading(false)
        }
    }

    const getProductNext = async () => {
        try {
            const res = await axiosInstanceBarier.get(`/store/recomended?type=reseller`);
            console.log(res, 'ini res selanjutnya');
            setStore(res?.store);
            setProduct(res?.product)
        } catch (error) {
            console.log(error, ' ini error')
        }
    }


    useEffect(() => {
        const intervalId = setInterval(() => {
            getProductNext();
        }, 20000);

        return () => clearInterval(intervalId);



    }, []);

    // const handleResellerList = async (product_id) => {
    //     try {
    //         const result = await axiosInstanceBarier.get(`/reseller?product_id=${product_id}`)
    //         setResellerList(result.reseller_store)
    //         console.log(result)
    //         handleOpenModal()
    //     } catch (error) {
    //         console.log(error, 'ini error')
    //     }

    // }

    // const handleOpenModal = () => {
    //     setModalReseller(true)
    // }

    const handleCloseModal = () => {
        setModalReseller(false)
    }



    useEffect(() => {
        getProduct()

        return () => {
            setProduct([])
        }
    }, [])



    return (
        <Stack alignItems={'center'} justifyContent='center' >



            <Stack w={'100%'}>

                <HStack >
                    <HStack>
                        <Tooltip label={store?.name} aria-label="Name">
                            <Avatar size={'md'} name={store?.name} src={store?.image || 'https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/default%2FWhatsApp%20Image%202024-02-13%20at%2023.07.09.jpeg?alt=media&token=8ac65665-3b62-43e4-9179-2c3541ed4a14'} />
                        </Tooltip>
                        <Heading size={'md'} textTransform='capitalize'>Toko {store?.name}</Heading>
                    </HStack>
                    <Spacer />

                </HStack>

                <Grid templateColumns={{ base: '1fr', md: '1fr 2.5fr 0.1fr' }} gap={3}>
                    <Stack cursor={'pointer'} alignItems='start' justifyContent={'start'}>
                        <HStack w={'100%'} spacing={1} position='relative' alignItems={'center'} justifyContent={'center'}>
                            {store?.community?.length > 0 ? (
                                <AvatarGroup
                                    cursor="pointer"
                                    size="sm"
                                    max={5}
                                    spacing={-2}
                                    position='absolute' top={2} left={2}
                                >
                                    {store?.community.map((user, i) => {

                                        return (
                                            <Tooltip key={i} label={user?.name} aria-label="Name">
                                                <Avatar size={'md'} name={user?.name} src={user?.image || user?.name} />
                                            </Tooltip>
                                        )

                                    })}
                                </AvatarGroup>
                            )
                                : (
                                    <Tooltip position='absolute' top={2} left={2} label={"admin"} aria-label="Name">
                                        <Avatar position='absolute' top={2} left={2} size={'md'} name={'admin'} src={'https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/default%2FWhatsApp%20Image%202024-02-13%20at%2022.59.30.jpeg?alt=media&token=30790f44-f8c6-4ef4-b7ad-e97749c5d6d3'} />
                                    </Tooltip>
                                )
                            }


                            <Image onClick={() => navigate('/')} w='auto' h={['300px', '250px', '250px']} src={store?.image || 'https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/default%2FWhatsApp%20Image%202024-02-13%20at%2023.07.09.jpeg?alt=media&token=8ac65665-3b62-43e4-9179-2c3541ed4a14'} />


                        </HStack>



                        <Text fontSize='lg' fontWeight='500'>Kalau mau <b style={{ color: 'red' }}>Dapet komisi</b> ?</Text>
                        <Stack w={'100%'} spacing={1}>
                            <Button size={'sm'} colorScheme={'red'} onClick={() => navigate('/signup')}>Daftar Dropshipper</Button>

                            {/* <Button size={'sm'} colorScheme={'red'} onClick={() => navigate('/signup')}>Produk Digital</Button>
                            <Button size={'sm'} colorScheme={'red'} onClick={() => navigate('/signup')}>Produk Non-Digital</Button> */}
                        </Stack>
                    </Stack>
                    <HStack gap={2} overflowX='scroll' maxW='100%'>
                        {product.length > 0 && (
                            product.map((x, index) => {
                                return (
                                    <ProductStoreCard index={index} data={x} onClick={() => navigate(`/product/${x._id}`)} />
                                )
                            })
                        )}
                    </HStack>

                    <Stack alignItems={"center"} py={2} justifyContent="center" bgColor={'red.600'} h={"auto"} display={["none", "flex", "flex"]}>
                        <FiArrowRightCircle size={17} color='white' />
                    </Stack>
                </Grid>

            </Stack>
            <Modal isOpen={modalReseller} onClose={handleCloseModal} size='3xl'>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Daftar Reseller </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {resellerList?.length > 0 ? (
                            <ResellerList resellerList={resellerList} />

                        ) : (
                            <Stack>
                                <Heading size={'md'}>Produk ini belum mempunyai reseller</Heading>
                                <Text>Daftar sekarang untuk menjadi reseller, dengan klik tombol dibawah</Text>
                            </Stack>
                        )}
                    </ModalBody>

                    <ModalFooter>

                        <Button size={"sm"} colorScheme="red" mr={3} onClick={() => navigate('/signup')}>Daftar Reseller / Dropship</Button>
                        <Button size={"sm"} colorScheme="red" mr={3} onClick={handleCloseModal}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

        </Stack>
    )
}

export default StoreHome