import React from "react";
import CommunityNotificationPage from "../Pages/Community/CommunityNotificationPage";
import CommunityPage from "../Pages/Community/CommunityPage";


const CommunityRouter = [
    {
        path: "/community",
        element: <CommunityPage />,
    },
    {
        path: "/community/:id",
        element: <CommunityNotificationPage />,
    },
];

export default CommunityRouter;
