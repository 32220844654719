'use client'

import {
  Box,
  Stack,
  Heading,
  Text,
  Container,
  Input,
  Button,
  SimpleGrid,
  useBreakpointValue,
  IconProps,
  Icon,
  useToast,
  Select,
  InputGroup,
  InputRightElement,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from '@chakra-ui/react'
import { useState } from 'react'
import { MdVisibility, MdVisibilityOff } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import axiosInstance from '../../Apis/axiosWithNoBarier'
import { checkSignupData } from '../../Hooks/Middleware/UserMiddleWare'
import ConditionPage from './ConditionPage'

const Blur = (props: IconProps) => {
  return (
    <Icon

      width={useBreakpointValue({ base: '100%', md: '40vw', lg: '30vw' })}
      zIndex={useBreakpointValue({ base: -1, md: -1, lg: 0 })}
      height="560px"
      viewBox="0 0 528 560"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <circle cx="71" cy="61" r="111" fill="#F56565" />
      <circle cx="244" cy="106" r="139" fill="#ED64A6" />
      <circle cy="291" r="139" fill="#ED64A6" />
      <circle cx="80.5" cy="189.5" r="101.5" fill="#ED8936" />
      <circle cx="196.5" cy="317.5" r="101.5" fill="#ECC94B" />
      <circle cx="70.5" cy="458.5" r="101.5" fill="#48BB78" />
      <circle cx="426.5" cy="-0.5" r="101.5" fill="#4299E1" />
    </Icon>
  )
}

export default function JoinOurTeam() {

  const toast = useToast()
  const navigate = useNavigate()

  const [modalCondition, setModalCondition] = useState(false)

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);


  const [loading, setLoading] = useState(false)

  const [dataAccount, setDataAccount] = useState({
    name: "",
    email: "",
    phone_number: "",
    role: "user",
    password: "",
    confirm_password: ""
  })

  const handleAddData = (e) => {
    const { id, value } = e.target;
    if (id === "role" && value !== "dropship") {
      const { referral_code, ...restData } = dataAccount;
      setDataAccount({ ...restData, [id]: value });
    } else {
      setDataAccount({ ...dataAccount, [id]: value });
    }
  }

  const handleSubmit = async () => {


    const validationResult = checkSignupData(dataAccount);
    if (!validationResult.success) {
      toast(validationResult.error);
      return;
    }

    setLoading(true)
    try {
      const res = await axiosInstance.post('/auth/register', dataAccount)
      if (res) {
        toast({
          title: 'DapatKomisi',
          description: `Anda berhasil mendaftar sebagai ${dataAccount?.role}. ${dataAccount.role === "produsen" || dataAccount.role === "reseller" || dataAccount.role === "dropship" ? 'Silahkan lanjutkan ke halaman admin' : 'Selamat berbelanja'}`,
          status: 'success',
          isClosable: true,
          duration: 9000,
          position: 'top'

        });

        if (dataAccount.role === "produsen" || dataAccount.role === "reseller" || dataAccount.role === "dropship") {
          window.open("https://admin.dapatkomisi.com", "_blank")
        }

        navigate('/login')

      }
    } catch (error) {
      console.log(error)
      toast({
        title: 'DapatKomisi',
        description: error.response.data.message,
        status: 'error',
        isClosable: true,
        duration: 9000,
        position: 'top'

      });

    }
    finally {
      setLoading(false)
    }
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const togglePasswordConfirmVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleOpenModal = () => {
    setModalCondition(true)
  }

  const handleCloseModal = () => {
    setModalCondition(false)
  }



  return (
    <Stack position={'relative'} h='100vh' alignItems={'center'} justifyContent='center' bgColor='red.500'>
      <Container
        as={SimpleGrid}
        maxW={'7xl'}
        columns={{ base: 1, md: 2 }}
        spacing={{ base: 10, lg: 32 }}
        py={{ base: 10, sm: 20, lg: 32 }}>
        <Stack spacing={{ base: 10, md: 20 }} zIndex={1} color='gray.100'>
          {/* ... */}
        </Stack>
        <Stack
          bg={'gray.100'}
          rounded={'xl'}
          p={{ base: 4, sm: 6, md: 8 }}
          spacing={{ base: 8 }}
          maxW={{ lg: 'lg' }}>
          <Stack spacing={4}>
            <Heading
              color={'gray.800'}
              lineHeight={1.1}
              fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}>
              Bergabung sekarang
              <Text as={'span'} bgGradient="linear(to-r, red.400,pink.400)" bgClip="text">
                !
              </Text>
            </Heading>
            <Text color={'gray.500'} fontSize={{ base: 'sm', sm: 'md' }}>
              belanja darimana saja, kami kirim kemana saja!
            </Text>
          </Stack>
          <Box as={'form'} mt={10}>
            <Stack spacing={4}>
              <Input
                placeholder="Nama"
                fontSize={"md"}
                shadow={"md"}
                bgColor={"white"}
                color={"black"}
                _placeholder={{
                  color: 'gray.500',
                }}
                id='name'
                onChange={(e) => handleAddData(e)}
              />
              <Input
                placeholder="Email"
                fontSize={"md"}
                shadow={"md"}
                bgColor={"white"}
                color={"black"}
                _placeholder={{
                  color: 'gray.500',
                }}
                id='email'
                onChange={(e) => handleAddData(e)}
              />
              <Input
                placeholder="Contoh: 0812346562616"
                fontSize={"md"}
                shadow={"md"}
                bgColor={"white"}
                color={"black"}
                _placeholder={{
                  color: 'gray.500',
                }}
                type='number'
                id='phone_number'
                onChange={(e) => handleAddData(e)}
              />
              <Select
                onChange={(e) => handleAddData(e)}
                variant="outline"
                fontSize={"md"}
                shadow={"md"}
                bgColor={"white"}
                color={"black"}
                _placeholder={{
                  color: 'gray.500',
                }}
                id='role'
                placeholder='Silahkan pilih role'
              >
                <option value="produsen">Produsen</option>
                <option value="reseller">Reseller</option>
                <option value="dropship">Dropship</option>
              </Select>
              {/* {dataAccount.role !== "dropship" && (
                <Input
                  placeholder="Referral Code"
                  fontSize={"md"}
                  shadow={"md"}
                  bgColor={"white"}
                  color={"black"}
                  _placeholder={{
                    color: 'gray.500',
                  }}
                  id='referral_code'
                  onChange={(e) => handleAddData(e)}
                />
              )} */}
              <InputGroup
                w={{
                  base: "100%",
                  md: "100%",
                }}
              >

                <Input
                  w={{
                    base: "100%",
                    md: "100%",
                  }}
                  placeholder="password"
                  fontSize={"md"}
                  shadow={"md"}
                  type={showPassword ? "text" : "password"}
                  bgColor={"white"}
                  color={"black"}
                  id='password'
                  onChange={(e) => handleAddData(e)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSubmit();
                    }
                  }}
                />
                <InputRightElement>
                  {showPassword ? (
                    <MdVisibilityOff
                      size={20}
                      color="black"
                      onClick={togglePasswordVisibility}
                      style={{ cursor: "pointer" }}
                    />
                  ) : (
                    <MdVisibility
                      size={20}
                      color="black"
                      onClick={togglePasswordVisibility}
                      style={{ cursor: "pointer" }}
                    />
                  )}
                </InputRightElement>
              </InputGroup>

              <InputGroup
                w={{
                  base: "100%",
                  md: "100%",
                }}
              >

                <Input
                  w={{
                    base: "100%",
                    md: "100%",
                  }}
                  placeholder="Konfirmasi password"
                  fontSize={"md"}
                  shadow={"md"}
                  bgColor={"white"}
                  color={"black"}
                  type={showConfirmPassword ? "text" : "password"}
                  id='confirm_password'
                  onChange={(e) => handleAddData(e)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSubmit();
                    }
                  }}
                />
                <InputRightElement>
                  {showConfirmPassword ? (
                    <MdVisibilityOff
                      size={20}
                      color="black"
                      onClick={togglePasswordConfirmVisibility}
                      style={{ cursor: "pointer" }}
                    />
                  ) : (
                    <MdVisibility
                      size={20}
                      color="black"
                      onClick={togglePasswordConfirmVisibility}
                      style={{ cursor: "pointer" }}
                    />
                  )}
                </InputRightElement>
              </InputGroup>

            </Stack>
            <Box p={2}>
              <Text cursor={'pointer'} fontStyle={"italic"} color={"blue.500"} fontWeight={"medium"} onClick={handleOpenModal}>Syarat dan ketentuan</Text>
            </Box>
            <Button
              fontFamily={'heading'}
              mt={8}
              isLoading={loading}
              w={'full'}
              bgGradient="linear(to-r, red.400,pink.400)"
              color={'white'}
              onClick={() => handleSubmit()}
              _hover={{
                bgGradient: 'linear(to-r, red.400,pink.400)',
                boxShadow: 'xl',
              }}>
              Daftar
            </Button>
          </Box>
        </Stack>
      </Container>
      <Blur position={'absolute'} top={-10} left={-10} style={{ filter: 'blur(70px)' }} />

      <Modal isOpen={modalCondition} onClose={handleCloseModal} size={"xl"}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Syarat dan Ketentuan</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                      <ConditionPage/>
                    </ModalBody>
                </ModalContent>
            </Modal>
    </Stack>
  )
}