/* eslint-disable react-hooks/exhaustive-deps */
import { Avatar, AvatarGroup, Button, Divider, Heading, HStack, Image, SimpleGrid, Spacer, Stack, Text, Tooltip } from '@chakra-ui/react'
import moment from 'moment'
import React, { } from 'react'
import { useNavigate } from 'react-router-dom'
import { AiFillStar } from 'react-icons/ai';




function ResellerList({ resellerList }) {








    const navigate = useNavigate()
    return (
        <Stack p={3} spacing={3}>
            <SimpleGrid columns={[1, 2, 3]} gap={5} width='100%' >

                {resellerList.length > 0 && (
                    resellerList
                        .map((x, index) => {
                            return (
                                <Stack key={index}>
                                    <Stack shadow='md' h='400px' w={['230px', "230px", "230px"]} borderRadius='xl' spacing={2} bgColor='white' _hover={{ transform: 'scale(1.05)' }} transition='0.3s ease-in-out'>
                                        <Stack alignItems='center' cursor='pointer' objectFit={'cover'} onClick={() => navigate(`/product/${x._id}`)}>
                                            {/* {x.image ? ( */}
                                            <Image
                                                src={x?.store?.image || 'https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/WhatsApp%20Image%202024-02-07%20at%2014.43.30.jpeg?alt=media&token=d23f664f-8996-4f0b-86de-cfc88422385a'}
                                                alt='img'
                                                borderRadius='md'
                                                style={{ width: '100% ', height: '180px', objectFit: 'cover' }}
                                            // onLoad={() => setIsLoading(false)}
                                            // onError={() => setIsLoading(false)} 
                                            />
                                            {/* ) : (
                                        <Skeleton w='100%' h='180px' borderRadius='md' startColor='gray.100' endColor='gray.300' />
                                    )} */}
                                        </Stack>
                                        <Spacer />

                                        <Stack px={3}>

                                            <Text textTransform='capitalize' fontWeight='bold' fontSize='sm' noOfLines={1}>
                                                {x?.store?.name}
                                            </Text>
                                        </Stack>

                                        <Stack flexDirection={["row"]} spacing={0} px={3}>
                                            <Stack spacing={1} alignItems={"flex-end"} justifyContent={'flex-end'}>

                                                <HStack >
                                                    <HStack spacing={0}>
                                                        <AiFillStar size={10} color='orange' />
                                                        <AiFillStar size={10} color='orange' />
                                                        <AiFillStar size={10} color='orange' />
                                                        <AiFillStar size={10} color='orange' />
                                                        <AiFillStar size={10} color='orange' />
                                                    </HStack>
                                                    <Text fontSize={'xs'} color='gray.500'>({x?.store?.rating})</Text>
                                                </HStack>
                                                <Divider />
                                                <Text fontSize={'xs'} color='gray.500'>Update : {moment(x.created_at).format('YYYY')}</Text>
                                            </Stack>

                                            <Spacer />

                                            <Stack alignItems={"flex-end"} spacing={1} >
                                                {x?.community?.length > 0 ? (
                                                    <AvatarGroup
                                                        cursor="pointer"
                                                        size="xs"
                                                        max={3}
                                                        spacing={-2}
                                                    >
                                                        {x?.community?.map((user, i) => {

                                                            return (
                                                                <Tooltip key={i} label={user?.name} aria-label="Name">
                                                                    <Avatar size={'xs'} name={user?.name} src={user?.image || user?.name} />
                                                                </Tooltip>
                                                            );
                                                        })}
                                                    </AvatarGroup>
                                                ) : (
                                                    <AvatarGroup
                                                        cursor="pointer"
                                                        size="xs"
                                                        max={3}
                                                        spacing={-2}
                                                    >
                                                        <Tooltip label={'Admin'} aria-label="Name">
                                                            <Avatar size={'xs'} name={'Admin'} src={'https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/default%2FWhatsApp%20Image%202024-02-13%20at%2022.59.30.jpeg?alt=media&token=30790f44-f8c6-4ef4-b7ad-e97749c5d6d3'} />
                                                        </Tooltip>
                                                    </AvatarGroup>
                                                )}

                                                <Divider />
                                                <Text fontSize={"xs"} color="gray.500">Komunitas</Text>
                                            </Stack>
                                        </Stack>
                                        <Stack p={3}>
                                            <Button size='sm' bgColor='red.600' onClick={() => navigate(`/product/${x._id}`, { state: x })} >
                                                <Text color='white'>lihat product</Text>
                                            </Button>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            )
                        })
                )}
            </SimpleGrid>


        </Stack>
    )
}

export default ResellerList