import { Divider, HStack, Spacer, Stack, Text } from '@chakra-ui/react'
import React from 'react'
import { BsPatchCheck } from 'react-icons/bs'
import { FiDelete, FiEdit } from 'react-icons/fi'

function CardAddress({ data, handleMainAddress, handleDeleteAddress, handleEditAddress }) {
    return (
        <Stack p={2} spacing={1}>
            <HStack>
                <Stack>
                    <HStack>
                        <Text fontWeight={"bold"} textTransform="capitalize">{data.label}</Text>
                        <Text fontSize={"sm"} textTransform="capitalize" color={"gray.800"}>- {data.name}</Text>
                    </HStack>
                    <Text fontSize={"sm"} color={"gray.800"}>{data.phone_number}</Text>


                </Stack>
                <Spacer />
                <HStack spacing={4}>
                    <FiEdit cursor={"pointer"} size={20} onClick={handleEditAddress} />
                    <FiDelete cursor={"pointer"} size={20} onClick={handleDeleteAddress} />
                    <BsPatchCheck cursor={"pointer"} size={20} onClick={handleMainAddress} />
                </HStack>
            </HStack>

            <Divider />
            {data?.address ? (
                <Stack>
                    <Text fontSize={"sm"} noOfLines={1}>{data?.address?.street}</Text>
                    <Text fontSize={"sm"}>{data?.address?.city}, {data?.address?.state}, {data?.address?.country}, {data?.address?.postal_code}</Text>
                </Stack>
            ) : (
                <Stack>
                   <Text color={"red.500"} fontWeight={500} fontSize="sm">
                    You haven't set address. Please set a main address by clicking the edit box icon on an address.
                </Text>
                </Stack>
            )}
        </Stack>
    )
}

export default CardAddress