import OrdersPage from "../Pages/Orders/OrdersPage";
import OrdersListPage from "../Pages/Orders/OrdersListPage";
import OrdersListDetailPage from "../Pages/Orders/OrdersListDetailPage";
import OrdersNowPage from "../Pages/Orders/OrdersNowPage";

const OrdersRouter = [
     {
          path: '/orders',
          element: <OrdersPage />
     },

     {
          path: '/orders-now',
          element: <OrdersNowPage />
     },

     {
          path: '/orders-list',
          element: <OrdersListPage />
     },
     {
          path: '/orders-list/:id',
          element: <OrdersListDetailPage />
     }

]

export default OrdersRouter