import React from "react";
import CartPage from "../Pages/Cart/CartPage";


const CartRouter = [
    {
        path: "/cart",
        element: <CartPage />,
    },
];

export default CartRouter;
