/* eslint-disable react-hooks/exhaustive-deps */
import { Avatar, AvatarGroup, Button, Divider, Heading, HStack, Image, SimpleGrid,  Spacer, Spinner, Stack, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AiFillStar } from 'react-icons/ai';
import { UseAuthStore } from "../../Hooks/Zustand/store";
import axiosInstanceBarier from '../../Apis/axiosWithBarier';
import moment from 'moment';

function CommunityPage() {

  const [community, setCommunity] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading] = useState(false);


  const navigate = useNavigate()







  const globalState = UseAuthStore();

  const getDataCommunity = async (page) => {
    globalState.setIsLoading(true)
    try {
      const res = await axiosInstanceBarier.get(`/community/list?page=${currentPage}`);
      if (page === 1) {
        setCommunity(res?.data || []); // Set data keranjang belanja pada halaman pertama
      } else {
        setCommunity((prevData) => [...prevData, ...(res?.data || [])]); // Tambahkan data baru ke data keranjang belanja sebelumnya
      }

      if (res?.data?.page >= res?.data?.total_pages) {
        setHasMore(false); // Tidak ada lagi produk untuk dimuat
      }

    } catch (error) {
      console.log(error, ' ini error')
    }
    finally {
      globalState.setIsLoading(false)
    }
  }

  const handleLoadMore = () => {
    if (hasMore) {
      const nextPage = currentPage + 1;
      setCurrentPage(nextPage);
      getDataCommunity(nextPage);
    }
  }


  useEffect(() => {
    getDataCommunity()

    return () => {
      setCommunity([])
      setCurrentPage(1); // Reset currentPage ke halaman pertama saat drawer ditutup
      setHasMore(true);
    }
  }, [])

  const image_data = [
    { id: "6566c16dadcbf500398a32bc", image: "https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/WhatsApp%20Image%202023-11-29%20at%2014.57.45.jpeg?alt=media&token=d5898712-f326-4d34-9aa1-0d44870e4dde" },
    { id: "656484456b7d3e00380dd630", image: "https://sahitya.id/wp-content/uploads/2022/10/PLN-2.jpg" },
    { id: "654765c8b5d4d49a798b4f96", image: "https://ecsys.okoce.net/assets/logo/logo_ok-oce-forever.jpg" },
    { id: "654765bdb5d4d49a798b4f93", image: "https://yt3.googleusercontent.com/CDHjw4x1ZdzKipkfsRw2VHfgojxreI_IbnqKH_U7L5rMs3hU7xJuo0uaPjGy_pVnJ2lZYTvvfrQ=s176-c-k-c0x00ffffff-no-rj" },
    { id: "65476540b5d4d49a798b4f8d", image: "https://upload.wikimedia.org/wikipedia/id/d/d4/TDA.png" },
    { id: "65673583adcbf500398a3d44", image: "https://play-lh.googleusercontent.com/mD10aGgL6MU_-r3YmOYC_nEqc4ihe84tA3mAO3fOPDVMd6I56tYNULDCFqVmf8wDfw" },
    { id: "65476673b5d4d49a798b522c", image: "https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/Screen%20Shot%202023-09-22%20at%2000.55.33.png?alt=media&token=27a5756e-eb66-42e6-9bd6-9e6e8ca5a20b" },
    { id: "6564c60beccee55e687b02ff", image: "https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/SjXJnxm1_400x400.jpeg?alt=media&token=78daded4-ee7f-40dd-a207-746eea2459cf" },

]





  return (
    <Stack p={[1, 1, 5]} spacing={3}>
      <Heading size={'md'}>List Komunitas</Heading>

      <SimpleGrid columns={[1, 2, 4]} gap={5} width='100%' p={2}>
        {community?.length > 0 && (
          community
          .slice() // Duplikat array agar tidak mengubah array asli
          .sort((a, b) => {
              // Pindahkan item dengan id tertentu ke depan
              if (a._id === '6566c16dadcbf500398a32bc') return -1; // return -1 untuk memindahkan ke depan
              return 0;
          })
          .map((x, index) => {
            return (
              <Stack key={index} >
                <Stack shadow='md' w={['auto', "250px", "250px"]} borderRadius='xl' spacing={2} bgColor='white' _hover={{ transform: 'scale(1.05)' }} transition='0.3s ease-in-out'>
                  <Stack alignItems='center' justifyContent={'center'} cursor='pointer' onClick={() => console.log('product')}>
                    <Image
                      src={x.image || image_data.find((data) => data.id === x._id)?.image || 'https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/WhatsApp%20Image%202024-02-07%20at%2014.43.30.jpeg?alt=media&token=d23f664f-8996-4f0b-86de-cfc88422385a'}
                      alt='img'
                      borderRadius='md'
                      style={{ width: '100% ', objectFit: 'contain', height: '180px' }}
                    // onLoad={() => setIsLoading(false)}
                    // onError={() => setIsLoading(false)} 
                    />
                  </Stack>
                  <Spacer />

                  <Stack p={3}>
                    <HStack alignItems='center' justifyContent='flex-end'>
                      <Stack alignItems={'end'}>
                        <Text fontSize={'xs'} color='gray.500'>Update : {moment(x.created_at).format('YYYY')}</Text>

                      </Stack>

                    </HStack>
                    <Text textTransform='capitalize' fontWeight='bold' fontSize='sm' noOfLines={1}>
                      {x.name}
                    </Text>
                  </Stack>
                  <Stack flexDirection={["row", "row", "row"]} spacing={0} px={3}>

                    <HStack >
                      <HStack spacing={0}>
                        <AiFillStar size={10} color='orange' />
                        <AiFillStar size={10} color='orange' />
                        <AiFillStar size={10} color='orange' />
                        <AiFillStar size={10} color='orange' />
                        <AiFillStar size={10} color='orange' />
                      </HStack>
                      <Text fontSize={'xs'} color='gray.500'>(90)</Text>
                    </HStack>

                    <Spacer />


                    <Stack alignItems={"flex-end"} spacing={1} >
                      {x?.stores?.length > 0 ? (
                        <AvatarGroup
                          cursor="pointer"
                          size="xs"
                          max={3}
                          spacing={-2}
                        >
                          {x.stores
                            .map((user, i) => {
                              return (
                                <Avatar key={i} name={user?.name} src={user?.image} />
                              )
                            })}
                        </AvatarGroup>
                      ) : (
                        <AvatarGroup
                          cursor="pointer"
                          size="xs"
                          max={3}
                          spacing={-2}
                        >
                          <Avatar bgColor={'red.600'} name={'Admin'} src={'https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/default%2FWhatsApp%20Image%202024-02-13%20at%2023.06.48.jpeg?alt=media&token=065b4b7c-7f20-463f-8e17-ae0a1fd5a3b7'} />
                        </AvatarGroup>
                      )}
                      <Divider />
                      <Text fontSize={"xs"} color="gray.500">Anggota</Text>
                    </Stack>
                  </Stack>
                  <Stack p={3}>
                    <Button size='sm' bgColor='red.600' onClick={() => navigate(`/community/${x._id}`, { state: x })} >
                      <Text color='white'>Gabung Sekarang</Text>
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
            )
          })
        )}
      </SimpleGrid>

      <Stack py={5} alignItems='center' justifyContent={'center'}>
        {loading ? (
          <Stack h='100%' w='100%' alignItems={'center'} justifyContent='center'>
            <Spinner />
          </Stack>
        ) : (
          hasMore ? (
            <Button
              onClick={handleLoadMore}
              bgColor='red.500'
              color='white'
              _hover={{ bgColor: 'red.600' }}
              _active={{ bgColor: 'red.700' }}
            >
              Lihat lebih banyak
            </Button>
          ) : (
            <Text fontWeight='bold' fontSize='lg'>Semua produk telah dimuat.</Text>
          )
        )}
      </Stack>
    </Stack>
  )
}

export default CommunityPage