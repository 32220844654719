import { Divider, HStack, Image, SimpleGrid, Spacer, Stack, Tag, Text } from '@chakra-ui/react'
import React, { useState } from 'react'
import { AiFillStar } from 'react-icons/ai'
import { FcShipped } from 'react-icons/fc';
import { formatFrice } from "../../Utils/numberUtil";


function ProductStoreCard({ data, index, onClick }) {
    const [displayText, setDisplayText] = useState((data?.store?.address?.address?.city)?.toLowerCase() || "Jakarta Selatan");





    const handleMouseEnter = () => {
        // Ketika kursor masuk ke dalam teks, ganti teks yang akan ditampilkan
        setDisplayText(data?.store?.name || "UMKM Indonesia");
    };

    const handleMouseLeave = () => {
        // Ketika kursor meninggalkan teks, kembalikan teks yang akan ditampilkan
        setDisplayText((data?.store?.address?.address?.city)?.toLowerCase() || "jakarta selatan");
    };

    return (
        <Stack key={index} onClick={onClick}>
            <Stack shadow='md' w={['170px']} borderRadius='xl' h={'320px'} bgColor='white' _hover={{ transform: 'scale(1.05)' }} transition='0.3s ease-in-out'>
                {data && data?.variants && data?.variants[0] && data?.variants[0]?.images && data?.variants[0]?.images?.length > 0 ? (
                    <Stack alignItems='center' cursor='pointer' objectFit='cover'>
                        <Image
                            src={data?.variants[0]?.images[0] || ''}
                            alt='img'
                            borderRadius='md'
                            style={{ width: '100%', height: '150px', objectFit: 'cover' }}
                        />
                    </Stack>
                ) : (
                    <Stack alignItems='center' cursor='pointer' objectFit='cover' >
                        <Image
                            src={'https://picsum.photos/300/300'}
                            alt='img'
                            borderRadius='md'
                            style={{ width: '100%', height: '150px', objectFit: 'cover' }}
                        />
                    </Stack>
                )}


                <Stack px={2} spacing={1}>


                    <SimpleGrid columns={[1]} gap={1} alignItems={'start'} >

                        <Tag size='sm' fontSize={'xs'} textTransform={'capitalize'} textAlign={'center'} colorScheme='green'>{data?.reseller_commission ? data?.reseller_commission?.commission?.type === 'number' ? `Rp.${formatFrice(data?.reseller_commission?.commission?.value)}` : `${data?.reseller_commission?.commission?.value}%` : '0'}{' '}
                            komisi / item</Tag>

                    </SimpleGrid>
                    <Spacer />

                    <Stack h={'35px'}>
                        <Text textTransform='capitalize' fontWeight={500} fontSize='xs' noOfLines={2}>
                            {data?.title || ""}
                        </Text>
                    </Stack>


                    <Stack >
                        {data?.variants?.length > 0 && (
                            <Text fontSize={'sm'} fontWeight='bold'>Rp. {formatFrice(parseFloat(data?.variants[0]?.price || 0))}</Text>

                        )}

                    </Stack>



                    <HStack
                        alignItems={'center'}
                        spacing={2}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    >
                        <FcShipped />
                        <Text
                            fontSize={'xs'}
                            color='gray.400'
                            fontWeight={500}
                            textTransform='capitalize'
                        >
                            {displayText}
                        </Text>
                    </HStack>

                    <HStack  >
                        <HStack spacing={0}>
                            <AiFillStar size={10} color='orange' />
                        </HStack>
                        <Text fontSize={'xs'} color='gray.500'>{data?.rating}|</Text>
                        <Text fontSize={'xs'} color='gray.500'>{data?.sold || 246} ++</Text>


                    </HStack>

                    <Divider />
                    <Text fontSize={'xx-small'} color={'gray.400'} fontWeight={"bold"}>Masih Tersedia</Text>


                </Stack>

            </Stack>
        </Stack>
    )
}

export default ProductStoreCard