import { Flex, HStack, Image, Spacer, Spinner, Stack, Text, Button } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import axiosInstanceBarier from '../../Apis/axiosWithBarier';
import { UseAuthStore } from '../../Hooks/Zustand/store';
import { formatFrice } from '../../Utils/numberUtil'

function TransactionsListPage() {

    const globalState = UseAuthStore();
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [OrdersList, setOrderList] = useState([])

    const navigate = useNavigate()




    const getDataOrders = async (page) => {
        globalState.setIsLoading(true);
        try {
            const res = await axiosInstanceBarier.get(`/transactions?page=${currentPage}`)
            if (page === 1) {
                setOrderList(res?.data || []);
            } else {
                setOrderList((prevData) => [...prevData, ...(res?.data || [])]);
            }


            if (res?.data?.page >= res?.data?.total_pages) {
                setHasMore(false);
            }

        } catch (error) {
            console.log(error);
        } finally {
            globalState.setIsLoading(false);
        }
    }


    const handleLoadMore = () => {
        if (hasMore) {
            const nextPage = currentPage + 1;
            getDataOrders(nextPage);
            setCurrentPage(nextPage);
        }
    }

    useEffect(() => {
        getDataOrders()
    
        return () => {
          setOrderList([])
          setCurrentPage(1); // Reset currentPage ke halaman pertama saat drawer ditutup
          setHasMore(true); 
        }
      }, [])
    
    return (
        <Stack p={[1, 1, 5]}>
            <Stack spacing={5}>
                <Text fontWeight={'bold'}>List Transaksi</Text>
                <Stack>
                    {OrdersList.length > 0 && (
                        OrdersList.map((x, index) => {
                            return (
                                <HStack px={[1, 1, 5]} spacing={5} bgColor='white' shadow={'lg'} p={2} borderRadius='md' key={index} m={1} >
                                    <Flex flexDirection={['column', 'row', 'row']} gap={3}>
                                        <Image src={x?.variant?.images[0] || 'https://picsum.photos/300/200'} w={'150px'} h='100px' objectFit={'cover'} borderRadius='md' />
                                        <Stack>
                                            <Text fontWeight={500} fontSize='sm'>ID : {x?._id}</Text>
                                            <Text fontSize={'sm'} color='gray.700'>Jumlah : {x?.quantity || 0}</Text>
                                            <Text fontSize={'sm'} color='gray.700'>Status : {x?.status_seller}</Text>
                                            <Text fontWeight={'bold'}>Rp. {formatFrice(parseFloat(x?.variant?.price || 0))}</Text>
                                        </Stack>
                                    </Flex>
                                    <Spacer />
                                    <Stack alignItems={'center'} justifyContent='center'>
                                        <Button colorScheme={'red'} size='sm' onClick={() => navigate(`/transactions-list/${x._id}`, {state : x})}>Detail</Button>
                                    </Stack>
                                </HStack>
                            )
                        })
                    )}

                </Stack>
                <Stack py={5} alignItems='center' justifyContent={'center'}>
                    {loading ? (
                        <Stack h='100%' w='100%' alignItems={'center'} justifyContent='center'>
                            <Spinner />
                        </Stack>
                    ) : (
                        hasMore ? (
                            <Button
                                onClick={handleLoadMore}
                                bgColor='red.500'
                                color='white'
                                _hover={{ bgColor: 'red.600' }}
                                _active={{ bgColor: 'red.700' }}
                            >
                                Lihat lebih banyak
                            </Button>
                        ) : (
                            <Text fontWeight='bold' fontSize='lg'>Semua produk telah dimuat.</Text>
                        )
                    )}
                </Stack>
            </Stack>
        </Stack>
    )
}

export default TransactionsListPage