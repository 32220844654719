import React from 'react';
import Select from 'react-select';

const RegionSelect = ({ formData, setFormData, Provinsi }) => {
    const provinceOptions = Provinsi.map((province) => ({
        value: province.name,
        label: province.name,
    }));

    const handleProvinceChange = (selectedOption) => {
        setFormData({
            ...formData,
            address: {
                ...formData.address,
                city: selectedOption ? selectedOption.value : '',
            },
        });
    };

    return (
        <Select
            options={provinceOptions}
            placeholder="Tulis untuk mencari kota..."
            onChange={handleProvinceChange}
            value={provinceOptions.find((option) => option.value === formData.address.city)}
            isSearchable
        />
    );
};

export default RegionSelect;
