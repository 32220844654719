// Import the functions you need from the SDKs you need
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getDatabase } from "firebase/database";
// import { getMessaging, onMessage, getToken } from "firebase/messaging";

// import song from "../Assets/Sound/CoinDrop-Notification.mp3"


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: "AIzaSyAqOsJ6lVxlGaV6MGLEfkMMgv8oCJdaePY",
	authDomain: "komisi-f35b7.firebaseapp.com",
	projectId: "komisi-f35b7",
	storageBucket: "komisi-f35b7.appspot.com",
	messagingSenderId: "30455397133",
	appId: "1:30455397133:web:711f7ce5977c2d8c39ce44",
	measurementId: "G-Z405DP4SH6",
	token_option:
    'BNAHpJ5WX8zTusISU3LMxXO4BT2zhTurn6rdvF3MQje1MbWVFjknH0IrmWTgeh9Z-arCU_AQ5CVFw16sZD9GU0U', // your vapid key
  };
  

// Initialize Firebase

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app)
const storage = getStorage(app);
const database = getDatabase(app);
// const configMessage = getMessaging(app);
auth.languageCode = 'id';

export {app,analytics,auth,db, storage, database}
// export const fetchToken = async (setTokenId) => {
//   try {
//     const token = await getToken(configMessage, { vapidKey: firebaseConfig.token_option });
//     if (token) {
//       // console.log(token, "this is push notif token");
//       setTokenId(token);
//     } else {
//       console.log("no push notif token for now");
//     }
//   } catch (error) {}
// };

// export const onMessageListener = (toast) => {
// 	onMessage(configMessage, (payload) => {
// 		const notif = new Audio(song)
// 		notif.play();
// 		const { data } = payload
// 		const { title, description } = data
// 		toast({
// 			title: title,
// 			description: description,
// 			position: 'top-right',
// 			isClosable: true,
// 		})
// 	});
// };