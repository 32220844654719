import React from "react";
import AffiliatePage from "../Pages/Affiliate/AffiliatePage";


const AffiliateRouter = [
    {
        path: "/affiliate",
        element: <AffiliatePage />,
    },
];

export default AffiliateRouter;
