import OrdersPage from "../Pages/Orders/OrdersPage";
import TransactionsListPage from "../Pages/Transactions/TransactionsListPage";
import TransactionsListDetailPage from "../Pages/Transactions/TransactionsListDetailPage";

const TransactionsRouter = [

     {
          path: '/transactions-list',
          element: <TransactionsListPage />
     },
     {
          path: '/transactions-list/:id',
          element: <TransactionsListDetailPage />
     }

]

export default TransactionsRouter