import { Button, Divider, HStack, Image,  Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, SimpleGrid, Spacer, Stack, Text, Textarea, useToast } from '@chakra-ui/react'
import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import axiosInstanceBarier from '../../Apis/axiosWithBarier'
import CustomStepper from '../../Components/Stepper/CustomStepper'
import { UseAuthStore } from '../../Hooks/Zustand/store'
import { formatFrice } from '../../Utils/numberUtil'

function TransactionsListDetailPage() {

  const param = useLocation()
  const dataOrders = param.state

  const navigate = useNavigate()
  const globalState = UseAuthStore();

  const [loading, setLoading] = useState(false)


  const [currentStep] = useState(dataOrders?.status_seller || "")
  const [steps] = useState([])
  const toast = useToast()
  const [value, setValue] = useState(0)
  const [formData, setFormData] = useState({
    variant: "",
    description: ""
  })

  const handleChange = (value) => setValue(value)


  const [modalReview, setModalReview] = useState(false)


  const orderFinish = async () => {
    globalState.setIsLoading(true)
    const data = {
      transaction_id: dataOrders?._id,
    }
    try {
      const res = await axiosInstanceBarier.post("/order/confirm-order", data)
      if (res) {
        toast({
          title: 'dapatkomisi.com',
          description: 'Terimakasih, atas orderan nya.',
          status: 'success',
          position: 'top',
          duration: 4000
        });
      }

    } catch (error) {
      toast({
        title: 'dapatkomisi.com',
        description: error.message,
        status: 'error',
        position: 'top',
        duration: 4000
      });
    } finally {
      globalState.setIsLoading(false)
    }
  }

  const handleOpenModal = () => {
    setModalReview(true)
  }

  const handleCloseModal = () => {
    setModalReview(false)

  }

  const handleSubmit = async () => {

    setLoading(true)
    const dataInput = {
      variant: dataOrders?.variant?._id,
      description: formData.description,
      rating: value,
      transaction: dataOrders?._id,
    }

    try {
      await axiosInstanceBarier.post("/product/review", dataInput)
      toast({
        title: 'dapatkomisi.com',
        description: 'Terimakasih, sudah memberikan reviews.',
        status: 'success',
        position: 'top',
        duration: 4000
      });
      handleCloseModal()

    } catch (error) {
      console.log(error, 'ini error')
    } finally {
      setLoading(false)
    }
  }




  return (
    <Stack p={[1, 1, 5]} spacing={5}>

      <HStack alignItems={'flex-end'} justifyContent='flex-end'>
        {dataOrders?.status_seller !== "Barang Di Terima" && (
          <Button onClick={() => orderFinish()} size={'sm'} colorScheme='red'>Barang Sudah Sampai</Button>

        )}

        {dataOrders?.status_seller !== "Barang Sudah Sampai" && (
          <Button onClick={handleOpenModal} size={'sm'} colorScheme='red'>Review Product</Button>

        )}


      </HStack>
      <Stack shadow={'md'} p={[2, 2, 5]} bgColor={'white'} borderRadius='md' spacing={3}>
        <CustomStepper steps={steps} currentStep={currentStep} message={dataOrders?.shipping_provider?.resi || ""} />
      </Stack>




      <Stack gap={5}>
        <Stack shadow={'md'} p={[2, 2, 5]} borderRadius='md'>
          <Text fontSize={'lg'} fontWeight='bold'>Informasi Kontak</Text>
          <Divider />
          <Stack spacing={4}>
            <HStack>
              <Text fontSize={'sm'} color='gray.600'>Penerima: </Text>
              <Spacer />
              <Text fontSize={'sm'} color='gray.800' fontWeight={500}>{dataOrders?.order?.contact_information?.email} </Text>
            </HStack>

            <HStack>
              <Text fontSize={'sm'} color='gray.600'>Nomor Telepon: </Text>
              <Spacer />
              <Text fontSize={'sm'} color='gray.800' fontWeight={500}>{dataOrders?.order?.contact_information?.phone_number} </Text>
            </HStack>

            <HStack alignItems={'flex-start'}>
              <Text fontSize={'sm'} color='gray.600'>Alamat: </Text>
              <Spacer />
              <Stack>
                <Text fontSize={'sm'} color='gray.800' fontWeight={500} textAlign={'end'} textTransform={'capitalize'}>{dataOrders?.order?.contact_information?.address?.street},  {dataOrders?.order?.contact_information?.address?.city}, {dataOrders?.order?.contact_information?.address?.state} </Text>
                <Text fontSize={'sm'} color='gray.800' fontWeight={500} textAlign={'end'} textTransform={'capitalize'}>{dataOrders?.order?.contact_information?.address?.country},  {dataOrders?.order?.contact_information?.address?.postal_code}</Text>
              </Stack>
            </HStack>

          </Stack>
        </Stack>

        <Stack shadow={'md'} p={[2, 2, 5]} borderRadius='md'>
          <Text fontSize={'lg'} fontWeight='bold'>Items</Text>
          <Divider />
          <Stack spacing={4}>
            {dataOrders?.variant && (
              <Stack cursor={"pointer"} justifyContent='space-evenly' onClick={() => navigate(`/product/${dataOrders?.reseller_product}`)}>
                <HStack spacing={5} bgColor='white' shadow={'md'} p={2} borderRadius='md'>
                  <Image src={dataOrders?.variant?.images[0] || 'https://picsum.photos/300/200'} w={'200px'} h='100px' objectFit={'cover'} borderRadius='md' />
                  <Stack>
                    <Text fontWeight={500} fontSize='sm' textTransform={'capitalize'}>{dataOrders?.variant?.title}</Text>
                    <Text fontSize={'sm'} color='gray.700'>Toko : {dataOrders?.reseller_store}</Text>
                    <Text fontSize={'sm'} color='gray.700'>Pengiriman : {dataOrders?.shipping_provider?.service} - {dataOrders?.shipping_provider?.etd}</Text>
                    <Text fontSize={'sm'} color='gray.700'>Notes : {dataOrders?.shipping_provider?.note}</Text>
                  </Stack>
                  <Divider />
                  <Stack alignItems={'flex-end'}>
                    <Text fontSize={'sm'} color='gray.700'>Jumlah : {dataOrders?.quantity}</Text>
                    e
                    <Text fontWeight={500} fontSize='md'>Rp.{formatFrice(parseFloat(dataOrders?.variant?.price || 0))}</Text>
                  </Stack>

                </HStack>
              </Stack>
            )}
          </Stack>
        </Stack>

        <Stack shadow={'md'} p={[2, 2, 5]} borderRadius='md'>
          <Text fontSize={'lg'} fontWeight='bold'>Detail</Text>
          <Divider />
          <Stack spacing={5}>
            <HStack>
              <Text fontSize={'sm'} color='gray.600'>Status Pembayaran: </Text>
              <Spacer />
              <Text fontSize={'sm'} color='gray.800' fontWeight={500}>{dataOrders?.payment_status}</Text>
            </HStack>

            <HStack>
              <Text fontSize={'sm'} color='gray.600'>Jumlah Pembayaran: </Text>
              <Spacer />
              <Text fontSize={'sm'} color='gray.800' fontWeight={500}>Rp.{formatFrice(parseFloat(dataOrders?.total_amount || 0))} </Text>
            </HStack>

            {/* {linkRedirect !== "" && (
              <HStack>
                <Text color={'red'} fontSize='sm'>If you cannot redirect to payment, please </Text>
                <Text color='red' fontSize={"sm"} fontStyle='italic' fontWeight={"bold"} onClick={() => window.open(linkRedirect, "_blank")} cursor='pointer'>Click here.</Text>
              </HStack>
            )} */}



          </Stack>
        </Stack>


      </Stack>

      <Modal isOpen={modalReview} onClose={handleCloseModal} size={"xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Review Product</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack>
              <SimpleGrid columns={[1]} gap={3}>
                <Stack>
                  <Text fontWeight={500} fontSize="sm" color={"gray.700"}>Rating </Text>

                  <NumberInput
                    placeholder="Rating Product"
                    defaultValue={5} min={1} max={5}
                    value={value}
                    onChange={handleChange}
                  >
                    <NumberInputField
                      defaultValue={5} min={1} max={5}
                    />
                    <NumberInputStepper

                    >
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>

                </Stack>

                <Stack>
                  <Text fontWeight={500} fontSize="sm" color={"gray.700"}>Message</Text>
                  <Textarea
                    placeholder="Message"
                    value={formData?.description}
                    onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                  />
                </Stack>



              </SimpleGrid>

            </Stack>
          </ModalBody>
          <ModalFooter>
            <HStack>
              <Button colorScheme={"red"} onClick={handleCloseModal}>Cancel</Button>
              <Button
                colorScheme="green"
                onClick={handleSubmit}
                isLoading={loading}
                loadingText="Submitting"
              >
                Submit
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  )
}

export default TransactionsListDetailPage