import { Box, Button, HStack, Image, Spinner, Stack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import RegisterHome from '../../Components/Register/RegisterHome';
import RegisterBusiness from '../../Components/Register/RegisterBusiness';
import { useNavigate } from 'react-router-dom';
import logopanjang from '../../Assets/Image/logokomisi.png'
import { CarouselFixComponent } from '../../Components/Gallery/CarouselFixComponent';
import ListCommunity from '../../Components/Community/ListCommunity';
import axiosInstance from '../../Apis/axiosWithNoBarier';
import AppImageSlide from '../../Components/Carousel/AppImageSlide';


function HomePage() {


    const navigate = useNavigate()


    const [bannerFirst, setBannerFirst] = useState([])
    const [loading, setLoading] = useState(false)

    const imageCarouselDashboard = [
        { data: "xxxx", image: "https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/banner_web%2FScreen%20Shot%202024-02-10%20at%2019.22.49.png?alt=media&token=d0c7dd26-538c-4777-848d-4e0332827d2f" },
        { data: "xxxx", image: "https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/banner_web%2FScreen%20Shot%202024-02-10%20at%2019.22.58.png?alt=media&token=03927b1c-9108-4e2b-9773-6bf3161e1767" },
        { data: "xxxx", image: "https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/banner_web%2FScreen%20Shot%202024-02-10%20at%2019.23.11.png?alt=media&token=48f1c0c2-b3f3-4192-85c5-358d877bf6ce" },
        { data: "xxxx", image: "https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/banner_web%2FScreen%20Shot%202024-02-10%20at%2019.23.19.png?alt=media&token=fd1917d2-3b5b-4888-b821-4ca081a4636f" },
        { data: "xxxx", image: "https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/banner_web%2FScreen%20Shot%202024-02-10%20at%2019.23.26.png?alt=media&token=d15b7be8-e713-4b83-a857-0238fcf61c79" },
    ]



    const getDataBanner = async () => {
        setLoading(true)
        try {
            const res = await axiosInstance.get("/banner")
            setBannerFirst(res.banner.value)
        } catch (error) {
            console.log(error, 'ini error')
        }finally{
            setLoading(false)
        }
    }

    useEffect(() => {
        getDataBanner()

        return () => {
        }
    }, [])





    return (
        <Stack spacing={5}>

            <Stack alignItems={'center'} justifyContent='center' display={['none', 'flex', 'flex']} pt={5} h={['30px', '50px', '80px']}>

                <Image onClick={() => navigate('/')} w={['100px', '150px', '250px']} cursor='pointer' src={logopanjang} />
            </Stack>

            <Stack alignItems={'center'}  display={['none', 'flex', 'flex']} justifyContent='center'>
                        <Box
                            width={['full', 'full']}
                            height={['full', 'full']}

                        >
                            {loading ? (
                                <Stack h='100%' w='100%' alignItems={'center'} justifyContent='center'>
                                    <Spinner />
                                </Stack>
                            ) : (
                                imageCarouselDashboard && (
                                    <Stack alignItems={'center'} justifyContent='center' >
                                        <Stack w={'100%'} position='relative' >
                                            <AppImageSlide images={imageCarouselDashboard && imageCarouselDashboard} />
                                        </Stack>
                                    </Stack>
                                )
                            )}
                        </Box>
                    </Stack>



            <Stack display={['flex', 'none', 'none']} pt={5} spacing={3}>
                <CarouselFixComponent images={null} aspectRatio={1} />
                <Stack  flex={1}> 
                    <ListCommunity />
                </Stack>

                <HStack w={'full'} alignItems={'center'} justifyContent={'center'}>
                    <Button size={'sm'}  w={'100%'} colorScheme='red'>Jadi Produsen</Button>
                    <Button size={'sm'}  w={'100%'} colorScheme='red'>Jadi Reseller</Button>
                </HStack>
                <HStack>
                    <Button size={'sm'}  onClick={() => navigate('/signup')} w={'100%'} colorScheme='red'>Gabung lewat komunitas</Button>

                </HStack>




            </Stack>





            <Stack spacing={5} overflow='auto'>

                <RegisterHome />


              


                {/* <TokoHome /> */}

                <RegisterBusiness />





            </Stack>
        </Stack>
    )
}

export default HomePage