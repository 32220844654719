import React from 'react'
import {
	Breadcrumb,
	BreadcrumbItem,
	BreadcrumbLink,
	BreadcrumbSeparator,
} from '@chakra-ui/react'
import { Link, useParams } from 'react-router-dom'

function BreadCrumbComponent(props) {
	const param = useParams();
	let data = [];

	if (props.dataParam) {
		data = props.dataParam;
	} else {
		data = [
			{ title: 'category', link: '/category' },
		];

		if (param.category) {
			// If category is present, add it to breadcrumbs
			data.push({ title: param.category, link: `/category/${param.category}` });

			if (param.subcategory) {
				// If subcategory is present, add it to breadcrumbs
				data.push({ title: param.subcategory, link: `/subcategory/${param.subcategory}` });
			}
		}
	}

	return (
		<Breadcrumb fontWeight='medium' fontSize='md' color={"gray.500"} textTransform="capitalize">
			{data.map((x, i) =>
				<BreadcrumbItem key={i} >
					<Link to={x.link} >
						{x.title}
					</Link>
				</BreadcrumbItem>
			)}
		</Breadcrumb>
	);
}

export default BreadCrumbComponent;





