import { DeleteIcon } from '@chakra-ui/icons';
import { Button, Checkbox, Flex, Heading, HStack, Image, SimpleGrid, Spacer, Spinner, Stack, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import axiosInstanceBarier from '../../Apis/axiosWithBarier';
import { UseAuthStore } from '../../Hooks/Zustand/store';
import { formatFrice } from '../../Utils/numberUtil';

function CartPage() {

    const param = useParams()

    const globalState = UseAuthStore();


    const [productData, setProductData] = useState({})
    const [cartsItem, setCartsItem] = useState([])

    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);

    const getDataCart = async () => {
        globalState.setIsLoading(true)
        try {
            const res = await axiosInstanceBarier.get(`/cart?page=${currentPage}`)

            if (res?.data?.length > 0) {
                setCartsItem((prevProduct) => [...prevProduct, ...res.data]);
                setCurrentPage((prevPage) => prevPage + 1);
            } else {
                setHasMore(false); // Tidak ada lagi produk untuk dimuat
            }
        } catch (error) {
            console.log(error, 'ini error')
        }
        finally {
            globalState.setIsLoading(false)
        }
    }

    useEffect(() => {
        getDataCart()

        return () => {
        }
    }, [])



    return (
        <Flex direction={["column", "row", "row"]} gap={5} justifyContent="space-between" my={5}
        >
            <Stack
                width={'full'}
                overflowY="auto"
                minH={'100vh'}
            >
                <Stack spacing={5} w='auto'>
                    <Text fontWeight={'bold'}>List Product</Text>
                    <Stack>
                        {cartsItem.length > 0 && (
                            cartsItem.map((x, index) => {
                                return (
                                    <HStack key={index} spacing={5} bgColor='white' shadow={'lg'} p={2} borderRadius='md' m={1}>
                                        <Stack>
                                            <Checkbox />
                                        </Stack>
                                        <Flex flexDirection={['column', 'row', 'row']} gap={3}>
                                            <Image src='https://picsum.photos/300/200' w={'150px'} h='100px' objectFit={'cover'} borderRadius='md' />
                                            <Stack>
                                                <Text fontWeight={500} fontSize='sm' textTransform={'capitalize'}>{x?.product?.title}</Text>
                                                <Text fontSize={'sm'} color='gray.700'>Quantity : {x?.quantity}</Text>
                                                <Text fontWeight={'bold'}>Rp. {formatFrice(parseFloat(x?.product?.price) || 0)}</Text>
                                            </Stack>
                                        </Flex>
                                        <Spacer />
                                        <Stack>
                                            <DeleteIcon cursor={'pointer'} />
                                        </Stack>
                                    </HStack>
                                )
                            })
                        )}

                    </Stack>

                    <Stack py={5} alignItems='center' justifyContent={'center'}>
                        {loading ? (
                            <Stack h='100%' w='100%' alignItems={'center'} justifyContent='center'>
                                <Spinner />
                            </Stack>
                        ) : (
                            hasMore ? (
                                <Button
                                    onClick={getDataCart}
                                    bgColor='red.500'
                                    color='white'
                                    _hover={{ bgColor: 'red.600' }}
                                    _active={{ bgColor: 'red.700' }}
                                >
                                    Lihat lebih banyak
                                </Button>
                            ) : (
                                <Text fontWeight='bold' fontSize='lg'>Semua produk telah dimuat.</Text>
                            )
                        )}
                    </Stack>

                </Stack>
            </Stack>

            <Stack
                minW={["100%", "30%", "30%"]}
                height="auto"
                maxH={300}
                overflowY="auto"
                p="5"
                backgroundColor="white"
                shadow="lg"
                borderWidth="1px"
                borderRadius="xl"
                borderColor="gray.200"
                position="sticky"
                top={100}
                spacing={5}
            >
                <Stack>
                    <Button variant={'outline'}>Makin hemat pakai promo</Button>
                </Stack>
                <Heading size={"sm"} textAlign="start">
                    Ringkasan Belanja
                </Heading>
                <HStack>
                    <Text>Total Harga</Text>
                    <Text >(6 Barang)</Text>
                    <Spacer />
                    <Text fontWeight={'bold'}>Rp.{formatFrice(parseFloat(7003.000))}</Text>
                </HStack>

                <hr />

                <HStack>
                    <Text fontWeight={'bold'} fontSize='lg'>Total Harga</Text>
                    <Spacer />
                    <Text fontWeight={'bold'} fontSize='lg'>Rp.{formatFrice(parseFloat(7003.000))}</Text>
                </HStack>

                <Stack>
                    <Button colorScheme={'red'}>Beli (6)</Button>
                </Stack>

                {/* <Button onClick={handleCloseBill}>Close Bill</Button> */}
            </Stack>
            {/* )} */}
        </Flex>
    )
}

export default CartPage