import {
  AiOutlineCustomerService, AiOutlineUnorderedList
} from "react-icons/ai";

import {
  IoIosHelpCircleOutline
} from "react-icons/io";

// import { MdOutlineQueryBuilder } from "react-icons/md";
import { CgProfile } from "react-icons/cg";

export const data = [
  {
    name: "Beranda",
    icon: AiOutlineCustomerService,
    link: "/"
  },
  // {
  //   name: "Promo",
  //   icon: MdOutlineQueryBuilder,
  //   link: "/promo"
  // },
  {
    name: "Produsen",
    icon: AiOutlineUnorderedList,
    link: "/produsen"
  },
  {
    name: "Reseller",
    icon: AiOutlineUnorderedList,
    link: "/store"
  },
  {
    name: "Komunitas",
    icon: IoIosHelpCircleOutline,
    link: "/community"
  },


  {
    name: "Order",
    icon: CgProfile,
    // submenu: [
    //   { name: "Create", icon: FcEditImage, link: "/create-aimarketer" },
    //   { name: "Folder", icon: FcSettings, link: "/folder-aimarketer" },
    // ],
    link: "/orders-list"
  },

  // {
  //   name: "Blog",
  //   icon: CgProfile,
  //   submenu: [
  //     { name: "Create", icon: FcEditImage, link: "/create-aimarketer" },
  //     { name: "Folder", icon: FcSettings, link: "/folder-aimarketer" },
  //   ],
  // },
];
