import { Avatar, Box, Button, Grid, Heading, HStack, Image, Input, Stack, Tab, TabList, TabPanel, TabPanels, Tabs, Text, useToast, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useDisclosure, Td, OrderedList, ListItem, Divider, Spacer, Link } from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import { MMdOutlinePermMedia, MdSmartphone, MdOutlinePermMedia } from 'react-icons/md'
import { useLocation, useNavigate } from 'react-router-dom'
import FormChangePassword from '../../Components/Profile/FormChangePassword'
import FormBiodataProfile from '../../Components/Profile/FormBiodataProfile'
import FormInputRekening from '../../Components/Profile/FormInputRekening'

import { UseAuthStore } from '../../Hooks/Zustand/store'
import axiosInstanceBarier from '../../Apis/axiosWithBarier'

function ProfilePage() {

  const path = window.location.href
  const height = window.innerHeight

  const globalState = UseAuthStore();



  const [dataUser, setDataUser] = useState('')
  const [dataAddress, setDataAddress] = useState([])
  const [editPhoto, setEditPhoto] = useState(false)
  const [filesImage, setFilesImage] = useState([]);
  const [files, setFiles] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [modalPromo, setModalPromo] = useState(false)
  const [activeTab, setActiveTab] = useState(0);
  const location = useLocation();
  const navigate = useNavigate()

  const getData = async () => {
    try {
      setDataUser({

      })

    } catch (error) {
      console.log(error, 'ini error')
    }
  }

  const toast = useToast({
    position:'top',
    align: "center",
  });

  const handleCopy = (id) => {
    navigator.clipboard.writeText(id)
    toast({
      title: 'dapatkomisi.com',
      description: 'Copy to clipboard.',
      status: 'success',
      position:'top'

    })
  }




  // useEffect(() => {
  //   getData()
  //   const searchParams = new URLSearchParams(location.search);
  //   const accountNumberParam = searchParams.get("account_number");
  //   const editNpwpParam = searchParams.get("edit_npwp");

  //   if (accountNumberParam === "true") {
  //     setActiveTab(3);
  //   } else if (editNpwpParam === "true") {
  //     setActiveTab(1);
  //   } else {
  //     setActiveTab(0);
  //   }
  //   return () => {
  //   }
  // }, [location.search])


  const handleInputData = async () => {
    try {
      const params = new FormData();
      params.append('file', filesImage[0]);

      //   const response = await _axios.post('api/storage?directory=profile-user', params);

      //   if (response.status === true) {
      //     const resUpdate = await _axios.put('api/update-me', {
      //       profile_image: response.data.toString()
      //     });

      //     if (resUpdate.status === true) {
      //       toast({
      //         title: 'importir.com',
      //         description: 'Success add new photo profile',
      //         status: 'success',
      //       });
      //       setFilesImage([]);
      //       setFiles([]);
      //       setEditPhoto(false);
      //       getData()
      //     }
      //   } else {
      //     console.log(error, 'ini error ')
      //   }
    } catch (error) {
      if (error.response && error.response.status === 413) {
        toast({
          title: 'Dapatkomisi.com',
          description: 'File size is too large',
          status: 'error',
          position:'top'

        });
      } else {
        console.log(error);
        toast({
          title: 'Dapatkomisi.com',
          description: error.message,
          status: 'error',
          position:'top'

        });
        console.log(error)
      }
      throw error;
    }
  };


  const handleFileInputChange = (event) => {
    const { files: newFiles } = event.target;
    if (newFiles.length) {
      const newFileArray = [...files];
      for (let i = 0; i < newFiles.length; i++) {
        const reader = new FileReader();
        reader.readAsDataURL(newFiles[i]);
        reader.onload = () => {
          newFileArray.push({
            file: reader.result,
            fileName: newFiles[i].name,
            description: newFiles[i].type,
          });
          setFiles(newFileArray);
        };
      }
      setFilesImage(newFiles); // Mengubah state filesImage menjadi array baru dari selectedFiles
    }
  }

  const handleCloseModalPromo = () => {
    setModalPromo(false)
  }


  return (
    <Stack p={[1, 1, 5]}>

      <Grid templateColumns={{ base: "1fr", md: "1fr 3fr" }} overflow="hidden" gap={3}>
        <Stack bgColor={'white'} borderRadius='md' shadow={'md'} w='100%'>
          <Stack spacing={5} p={5} m={5}>
            <HStack >
              <Heading size={'lg'}>Profile</Heading>
            </HStack>
            <Stack>
              <Avatar size='xl' src={`https://api.dicebear.com/7.x/adventurer/svg?seed=${globalState.user.email}`} colorScheme="red" />
            </Stack>



            <Stack>
              <Button onClick={onOpen} bgColor={'green.400'} textColor={'white'} _hover={'green.300'}>Terms & Condition</Button>

              <Modal isOpen={isOpen} onClose={onClose} size={'lg'}>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>Terms & Condition</ModalHeader>
                  <ModalCloseButton />
                  <Divider />
                  <ModalBody>
                    <OrderedList>
                      <ListItem>Kuota free shipping gratis ongkos kirim </ListItem>
                      <Divider />
                      <ListItem>Berlaku untuk platform Dapatkomisi.com</ListItem>
                      <Divider />               
                      <ListItem>Tidak termasuk biaya ongkir lokal Indonesia</ListItem>
                      <Divider />
                      <ListItem>Pembayaran barang harus melalui platform Dapatkomisi </ListItem>
                      <Divider />
                      <ListItem>Kuota free shipping tidak dapat dicairkan dalam bentuk dana, namun hanya dpat digunakan sebagai kouta free shipping selama membership berlaku</ListItem>
                      <Divider />
                      <ListItem>Dapatkomisi.com berhak membatalkan penggunaan kuota free shipping apabila di temukan kecurangan atau transaksi yang tidak memenuhi Terms and Conditions</ListItem>
                    </OrderedList>
                  </ModalBody>
                  <Divider />
                  <ModalFooter>
                    <Button colorScheme='blue' mr={3} onClick={onClose}>
                      Close
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            </Stack>
          </Stack>
        </Stack>
        <Stack bgColor={'white'} borderRadius='md' shadow={'md'} >
          <Tabs variant='enclosed' index={activeTab} onChange={(index) => setActiveTab(index)}>
            <TabList>
              <Tab>
                <Stack p={3}>
                  <Text>Profile</Text>
                </Stack>
              </Tab>
              {/* <Tab>
                <Stack p={3}>
                  <Text>Edit Profile</Text>
                </Stack>
              </Tab> */}
              {/* <Tab>
                <Stack p={3}>
                  <Text>Ganti Password</Text>
                </Stack>
              </Tab> */}
              {/* <Tab>
                <Stack p={3}>
                  <Text>Account Number</Text>
                </Stack>
              </Tab> */}
            </TabList>
            <TabPanels>
              <TabPanel>
                <Stack p={5}>
                  <FormBiodataProfile view={true} dataUser={globalState.user} />
                </Stack>
              </TabPanel>
              {/* <TabPanel>
                <Stack p={5}>
                  <FormBiodataProfile />
                </Stack>
              </TabPanel> */}
              {/* <TabPanel>
                <Stack p={5}>
                  <FormChangePassword />
                </Stack>
              </TabPanel> */}
              {/* <TabPanel>
                <Stack p={5}>
                  <FormInputRekening />
                </Stack>
              </TabPanel> */}
            </TabPanels>
          </Tabs>

        </Stack>

      </Grid>

      <Modal isOpen={modalPromo} onClose={() => handleCloseModalPromo()}>
        <ModalOverlay />
        <ModalContent w={'100%'}>
          <ModalHeader>Promo Code</ModalHeader>
          <ModalCloseButton />
          <ModalBody >
            {dataUser?.promo_users?.length > 0 ? (
              <Stack spacing={2}>
                {dataUser?.promo_users?.map((x, i) => {
                  return (
                    <Stack key={i} py={1}>
                      <HStack>
                        <Text fontSize={'sm'} fontWeight={500}>{x?.promo_code?.code}</Text>
                        <Spacer />
                        <Text color="blue.600" cursor={'pointer'} fontSize='xs' onClick={() => handleCopy(x?.promo_code?.code)}>COPY</Text>
                      </HStack>
                      <Stack fontSize={'sm'} color='gray.600'>
                        <div dangerouslySetInnerHTML={{ __html: x?.promo_code?.description }} />

                      </Stack>

                      {/* <Text textAlign={'end'} color={'red'} fontSize='xx-small' fontWeight={500}> Expired: </Text> */}
                      <Divider />
                    </Stack>
                  )
                })}
              </Stack>
            ) : (<></>)}

          </ModalBody>
          <ModalFooter>
            <Button colorScheme='red' mr={3} onClick={() => handleCloseModalPromo()}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  )
}

export default ProfilePage