import React from "react";
import SearchPage from "../Pages/Search/SearchPage";

const SearchRouter = [
  {
    path: "/search",
    element: (
      <SearchPage />
    ),
  },
];

export default SearchRouter;
