import { Box, Grid, SimpleGrid, Spinner, Stack } from '@chakra-ui/react'
import AppImageSlide from '../../Components/Carousel/AppImageSlide';

import React, { useEffect, useState } from 'react'
import axiosInstance from '../../Apis/axiosWithNoBarier';

function RegisterBusiness() {

        // const imageCarouselDashboard = [
    //     { data: "xxxx", image: "https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/Screen%20Shot%202023-09-17%20at%2022.44.40.png?alt=media&token=72574c97-b4d9-4092-bbe4-afdbe713e18d" },
    //     { data: "xxxx", image: "https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/Screen%20Shot%202023-09-17%20at%2022.44.51.png?alt=media&token=1c0b46e1-7a41-41ce-ad50-238fedc4ea2d" },
    //     { data: "xxxx", image: "https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/Screen%20Shot%202023-09-17%20at%2022.45.23.png?alt=media&token=984634d4-7108-4e5a-8a8a-d71d15afa5c9" },
    // ]


    const loading = false

    const [bannerFirst, setBannerFirst] = useState([])




    const getDataBanner = async () => {
        try {
            const res = await axiosInstance.get("/banner")
            setBannerFirst(res.banner.value)
        } catch (error) {
            console.log(error, 'ini error')
        }
    }

    useEffect(() => {
        getDataBanner()

        return () => {
        }
    }, [])



    return (
        <Stack  spacing={5}>
            <Stack alignItems={'center'} justifyContent='center'>
                <Grid templateColumns={{ base: '1fr', md: '1fr 2fr' }} gap={5}>
                    <SimpleGrid columns={[2]} spacing={4}>
                        <Box width={['150px', 'auto']} height={['150px', 'auto']} bgColor={'red.200'}
                            backgroundImage="url('https://marketplace.canva.com/EAEyq9GvEyU/1/0/1600w/canva-brown-warm-modern-minimalist-eyeglasses-promotion-facebook-post-xFw8mwxhO6w.jpg')"
                            backgroundSize="cover"
                            backgroundPosition="center"
                        ></Box>
                        <Box width={['150px', 'auto']} height={['150px', 'auto']} bgColor={'red.300'}

                            backgroundImage="url('https://marketplace.canva.com/EAE-iG5WSh0/1/0/1600w/canva-modern-watch-instagram-post-EzO5SNNLGsw.jpg')"
                            backgroundSize="cover"
                            backgroundPosition="center"
                        ></Box>
                        <Box width={['150px', 'auto']} height={['150px', 'auto']} bgColor={'red.400'}
                            backgroundImage="url('https://marketplace.canva.com/EAFVeFaukZE/1/0/1600w/canva-white-simple-new-arrival-instagram-post-KUYAnOL0xgY.jpg')"
                            backgroundSize="cover"
                            backgroundPosition="center"></Box>
                        <Box width={['150px', 'auto']} height={['150px', 'auto']} bgColor={'red.500'}
                            backgroundImage="url('https://marketplace.canva.com/EAFK11FOhos/2/0/1600w/canva-white-modern-sneakers-product-promotion-instagram-post-0V01ZD-Pzow.jpg')"
                            backgroundSize="cover"
                            backgroundPosition="center"></Box>
                    </SimpleGrid>

                    <Stack alignItems={'center'} justifyContent='center'>
                        <Box
                            width={['full', 'full']}
                            height={['full', 'full']}
                            // backgroundImage="url('https://marketplace.canva.com/EAEkUbcgaJE/2/0/1600w/canva-pastel-colorful-blocks-fashion-e-commerce-website-H_UXn5jX8XE.jpg')"
                            // backgroundSize="cover"
                        >
                            {loading ? (
                                <Stack h='100%' w='100%' alignItems={'center'} justifyContent='center'>
                                    <Spinner />
                                </Stack>
                            ) : (
                                bannerFirst && (
                                    <Stack alignItems={'center'} justifyContent='center' h={'300px'}>
                                        <Stack w={'100%'} position='relative' >
                                            <AppImageSlide images={bannerFirst && bannerFirst} />
                                        </Stack>
                                    </Stack>
                                )
                            )}
                        </Box>
                    </Stack>
                </Grid>
            </Stack>
        </Stack>

    )
}

export default RegisterBusiness