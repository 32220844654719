/* eslint-disable react-hooks/exhaustive-deps */
import { Avatar, AvatarGroup, Button, Divider, Grid, Heading, HStack, Image, Spacer, Stack, Text, Tooltip } from '@chakra-ui/react'

import React, { useEffect, useState } from 'react'
import { AiFillStar } from 'react-icons/ai';

import { UseAuthStore } from "../../Hooks/Zustand/store";
import axiosInstanceBarier from '../../Apis/axiosWithBarier';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../Apis/axiosWithNoBarier';
import { FiArrowRightCircle } from "react-icons/fi"
import moment from 'moment';

import logo from '../../Assets/Image/logopngkomisi.png'
import { FaSearch } from 'react-icons/fa';
import StoreHome from '../Toko/StoreHome';




function RegisterHome() {

    const [community, setCommunity] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);

    const [store, setStore] = useState([])
    const [adminStore, setAdminStore] = useState('')

    const navigate = useNavigate()

    const globalState = UseAuthStore();

    const image_data = [
        { id: "6566c16dadcbf500398a32bc", image: "https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/WhatsApp%20Image%202023-11-29%20at%2014.57.45.jpeg?alt=media&token=d5898712-f326-4d34-9aa1-0d44870e4dde" },
        { id: "656484456b7d3e00380dd630", image: "https://sahitya.id/wp-content/uploads/2022/10/PLN-2.jpg" },
        { id: "654765c8b5d4d49a798b4f96", image: "https://ecsys.okoce.net/assets/logo/logo_ok-oce-forever.jpg" },
        { id: "654765bdb5d4d49a798b4f93", image: "https://yt3.googleusercontent.com/CDHjw4x1ZdzKipkfsRw2VHfgojxreI_IbnqKH_U7L5rMs3hU7xJuo0uaPjGy_pVnJ2lZYTvvfrQ=s176-c-k-c0x00ffffff-no-rj" },
        { id: "65476540b5d4d49a798b4f8d", image: "https://upload.wikimedia.org/wikipedia/id/d/d4/TDA.png" },
        { id: "65673583adcbf500398a3d44", image: "https://play-lh.googleusercontent.com/mD10aGgL6MU_-r3YmOYC_nEqc4ihe84tA3mAO3fOPDVMd6I56tYNULDCFqVmf8wDfw" },
        { id: "65476673b5d4d49a798b522c", image: "https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/Screen%20Shot%202023-09-22%20at%2000.55.33.png?alt=media&token=27a5756e-eb66-42e6-9bd6-9e6e8ca5a20b" },
        { id: "6564c60beccee55e687b02ff", image: "https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/SjXJnxm1_400x400.jpeg?alt=media&token=78daded4-ee7f-40dd-a207-746eea2459cf" },

    ]

    const image_profile = [
        { id: "6566c368adcbf500398a32df", image: "https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/default%2FWhatsApp%20Image%202024-02-13%20at%2023.06.48.jpeg?alt=media&token=065b4b7c-7f20-463f-8e17-ae0a1fd5a3b7" },
        { id: "6581483c5ed71200b3c7a56d", image: "https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/WhatsApp%20Image%202023-12-19%20at%2014.58.21.jpeg?alt=media&token=2b76d43a-6f26-4c84-a5a7-95331958ed5a" },
    ]

    const getCommunity = async () => {
        globalState.setIsLoading(true)
        try {
            const res = await axiosInstance.get(`/community/list?page=${currentPage}`);
            if (res?.data?.length > 0) {
                setCommunity((prevProduct) => [...prevProduct, ...res.data]);
                setCurrentPage((prevPage) => prevPage + 1);
            }
        } catch (error) {
            console.log(error, ' ini error')
        }
        finally {
            globalState.setIsLoading(false)
        }
    }




    const getDataStore = async () => {
        globalState.setIsLoading(true)
        try {
            const res = await axiosInstanceBarier.get(`/store?&page=1&reseller=true`);
            if (res?.data?.length > 0) {
                setAdminStore(res.admin_store)
                setStore(res?.data);
            }
        } catch (error) {
            console.log(error, ' ini error')
        }
        finally {
            globalState.setIsLoading(false)
        }
    }


    useEffect(() => {
        getCommunity()
        getDataStore()

        return () => {
        }
    }, [])



    return (
        <Stack spacing={8}>
            <Stack>
                <Stack spacing={5} >
                    <HStack>
                        <Heading size={['sm', 'sm', 'md']}>Silahkan pilih komunitas anda</Heading>
                        <Spacer />
                        <Text fontWeight={500} fontStyle='italic' color={'red'} fontSize={['sm', 'sm', 'md']} cursor='pointer' onClick={() => navigate("/community")}>Lihat semua</Text>
                    </HStack>
                    <Stack>
                        <Grid templateColumns={{ base: '1fr', md: '3fr 0.1fr' }} gap={3}>
                            {/* <Stack cursor={'pointer'} display={["none", "flex"]} alignItems='start' justifyContent={'start'}>
                                <Image borderRadius='md' w={'full'} height='auto' src={'https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/WhatsApp%20Image%202024-02-07%20at%2014.43.30.jpeg?alt=media&token=d23f664f-8996-4f0b-86de-cfc88422385a'} />
                            </Stack> */}
                            <HStack gap={3} overflowX='scroll' width='100%' >
                                {community?.length > 0 && (
                                    community?.slice().sort((a, b) => {
                                        // Pindahkan item dengan id tertentu ke depan
                                        if (a._id === '6566c16dadcbf500398a32bc') return -1; // return -1 untuk memindahkan ke depan
                                        return 0;
                                    })
                                        .map((x, index) => {
                                            return (
                                                <Stack key={index}  >
                                                    <Stack shadow='md' w={['200px']} h='350px' borderRadius='xl' spacing={2} bgColor='white' onClick={() => navigate(`community/${x._id}`, { state: x })}  >
                                                        <Stack position={'relative'} alignItems='center' justifyContent={'center'} cursor='pointer'  >
                                                            <Image
                                                                src={x.image || image_data.find((data) => data.id === x._id)?.image || 'https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/WhatsApp%20Image%202024-02-07%20at%2014.43.30.jpeg?alt=media&token=d23f664f-8996-4f0b-86de-cfc88422385a'}
                                                                alt='img'
                                                                borderRadius='md'
                                                                style={{ width: '100% ', objectFit: 'contain', height: '180px' }}
                                                            // onLoad={() => setIsLoading(false)}
                                                            // onError={() => setIsLoading(false)} 
                                                            />


                                                        </Stack>
                                                        <Spacer />

                                                        <Stack px={3}>

                                                            <Text textTransform='capitalize' fontWeight='bold' fontSize='sm' noOfLines={1}>
                                                                {x?.name}
                                                            </Text>
                                                        </Stack>
                                                        <Stack flexDirection={["row", "row", "row"]} spacing={0} px={3}>

                                                            <HStack alignItems='center' justifyContent='flex-end'>
                                                                <Stack alignItems={'end'}>
                                                                    <Text fontSize={'xs'} color='gray.500'>Update : {moment(x.created_at).format('YYYY')}</Text>

                                                                </Stack>

                                                            </HStack>
                                                            <Spacer />


                                                            <Stack alignItems={"flex-end"} spacing={1} >
                                                                {x?.stores?.length > 0 ? (
                                                                    <AvatarGroup
                                                                        cursor="pointer"
                                                                        size="xs"
                                                                        max={3}
                                                                        spacing={-2}
                                                                    >
                                                                        {x?.stores
                                                                            .map((user, i) => {
                                                                                return (
                                                                                    <Tooltip key={i} label={user?.name} aria-label="Name">
                                                                                        <Avatar size={'xs'} name={user?.name} src={user?.image || user?.name} />
                                                                                    </Tooltip>
                                                                                )
                                                                            })}
                                                                    </AvatarGroup>
                                                                ) :
                                                                    <AvatarGroup
                                                                        cursor="pointer"
                                                                        size="xs"
                                                                        max={3}
                                                                        spacing={-2}
                                                                    >
                                                                        <Tooltip label={'Admin'} aria-label="Name">
                                                                            <Avatar size={'xs'} name={'Admin'} src={'https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/default%2FWhatsApp%20Image%202024-02-13%20at%2023.06.48.jpeg?alt=media&token=065b4b7c-7f20-463f-8e17-ae0a1fd5a3b7'} />
                                                                        </Tooltip>
                                                                    </AvatarGroup>
                                                                }
                                                                <Divider />
                                                                <Text fontSize={"xs"} color="gray.500">Anggota</Text>
                                                            </Stack>
                                                        </Stack>
                                                        <Stack px={3} spacing={1} py={3}>

                                                            <Button size='sm' fontSize={'xs'} bgColor='red.600' >
                                                                <Text color='white' >Gabung lewat Komunitas</Text>
                                                            </Button>

                                                        </Stack>
                                                    </Stack>
                                                </Stack>
                                            )
                                        })
                                )}
                            </HStack>
                            <Stack alignItems={"center"} py={2} justifyContent="center" bgColor={'red.600'} h={"auto"} display={["none", "flex", "flex"]}>
                                <FiArrowRightCircle size={17} color='white' />
                            </Stack>
                        </Grid>
                    </Stack>
                </Stack>
            </Stack>

            <Stack>
                <StoreHome />
            </Stack>

            <Stack >
                <Stack spacing={5} >
                    <HStack>
                        <Heading size={['sm', 'sm', 'md']}>Toko Terkini</Heading>
                        <Spacer />
                        <Text fontWeight={500} fontStyle='italic' fontSize={['sm', 'sm', 'md']} color={'red'} cursor='pointer' onClick={() => navigate("/store")}>Lihat semua</Text>
                    </HStack>
                    <Stack>
                        <Grid templateColumns={{ base: '1fr', md: '3fr 0.1fr' }} gap={3}>
                            {/* <Stack cursor={'pointer'} display={["none", "flex"]} alignItems='start' justifyContent={'start'}>
                                <Image borderRadius='md' w={'full'} height='auto' src={logo} />
                            </Stack> */}
                            <HStack gap={3} overflowX='scroll' width='100%' >
                                <Stack >
                                    <Stack shadow='md' w={['200px']} h='350px' borderRadius='xl' spacing={2} bgColor='white' _hover={{ transform: 'scale(1.05)' }} transition='0.3s ease-in-out' onClick={() => navigate(`/produsen`)}  >
                                        <Stack alignItems='center' cursor='pointer' >
                                            <Stack alignItems='center' cursor='pointer' objectFit='cover' onClick={() => console.log('product')}>
                                                <Image
                                                    src={adminStore?.image || 'https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/default%2FWhatsApp%20Image%202024-02-13%20at%2023.07.09.jpeg?alt=media&token=8ac65665-3b62-43e4-9179-2c3541ed4a14'}
                                                    alt='img'
                                                    borderRadius='md'
                                                    style={{ width: '100%', height: '180px', objectFit: 'cover' }}
                                                />
                                            </Stack>

                                        </Stack>
                                        <Spacer />

                                        <Stack px={3}>

                                            <Text textTransform='capitalize' fontWeight='bold' fontSize='sm' noOfLines={1}>
                                                {adminStore?.name}
                                            </Text>
                                        </Stack>

                                        <Stack flexDirection={["row"]} spacing={0} px={2}>
                                            <Stack spacing={1} alignItems={"flex-end"} justifyContent={'flex-end'}>

                                                <HStack >
                                                    <HStack spacing={0}>
                                                        <AiFillStar size={10} color='orange' />
                                                        <AiFillStar size={10} color='orange' />
                                                        <AiFillStar size={10} color='orange' />
                                                        <AiFillStar size={10} color='orange' />
                                                        <AiFillStar size={10} color='orange' />
                                                    </HStack>
                                                    <Text fontSize={'xs'} color='gray.500'>({adminStore.rating})</Text>
                                                </HStack>
                                                <Divider />
                                                <Text fontSize={'xs'} color='gray.500'>Update : {moment(adminStore.created_at).format('YYYY')}</Text>
                                            </Stack>

                                            <Spacer />

                                            <Stack alignItems={"flex-end"} spacing={1} >
                                                {adminStore?.community?.length > 0 ? (
                                                    <AvatarGroup
                                                        cursor="pointer"
                                                        size="xs"
                                                        max={3}
                                                        spacing={-2}
                                                    >
                                                        {adminStore?.community?.map((user, i) => {

                                                            return (
                                                                <Tooltip key={i} label={user?.name} aria-label="Name">
                                                                    <Avatar size={'xs'} name={user?.name} src={user?.image || user?.name} />
                                                                </Tooltip>
                                                            );
                                                        })}
                                                    </AvatarGroup>
                                                ) : (
                                                    <AvatarGroup
                                                        cursor="pointer"
                                                        size="xs"
                                                        max={3}
                                                        spacing={-2}
                                                    >
                                                        <Tooltip label={'Admin'} aria-label="Name">
                                                            <Avatar size={'xs'} name={'Admin'} src={'https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/default%2FWhatsApp%20Image%202024-02-13%20at%2022.59.30.jpeg?alt=media&token=30790f44-f8c6-4ef4-b7ad-e97749c5d6d3'} />
                                                        </Tooltip>
                                                    </AvatarGroup>
                                                )}

                                                <Divider />
                                                <Text fontSize={"xs"} color="gray.500">Komunitas</Text>
                                            </Stack>
                                        </Stack>
                                        <Stack p={3}>
                                            <Button size='sm' fontSize={'xs'} bgColor='red.600' >
                                                <Text color='white'>lihat semua produsen</Text>
                                            </Button>
                                        </Stack>
                                    </Stack>
                                </Stack>

                                {store?.length > 0 && (
                                    store.map((x, index) => {
                                        return (
                                            <Stack key={index}>
                                                <Stack shadow='md' h='350px' w={['200px']} borderRadius='xl' onClick={() => navigate(`/store/${x?._id}`, { state: x })} spacing={2} bgColor='white' _hover={{ transform: 'scale(1.05)' }} transition='0.3s ease-in-out'>
                                                    <Stack alignItems='center' cursor='pointer' objectFit={'cover'} >
                                                        <Image
                                                            src={x.image || image_profile.find((data) => data.id === x._id)?.image || 'https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/WhatsApp%20Image%202024-02-07%20at%2014.43.30.jpeg?alt=media&token=d23f664f-8996-4f0b-86de-cfc88422385a'}
                                                            alt='img'
                                                            borderRadius='md'
                                                            style={{ width: '100%', height: '180px', objectFit: 'cover' }}
                                                        // onLoad={() => setIsLoading(false)}
                                                        // onError={() => setIsLoading(false)} 
                                                        />

                                                    </Stack>
                                                    <Spacer />

                                                    <Stack px={3}>

                                                        <Text textTransform='capitalize' fontWeight='bold' fontSize='sm' noOfLines={1}>
                                                            {x?.name}
                                                        </Text>
                                                    </Stack>

                                                    <Stack flexDirection={["row"]} spacing={0} px={3}>
                                                        <Stack spacing={1} alignItems={"flex-end"} justifyContent={'flex-end'}>

                                                            <HStack >
                                                                <HStack spacing={0}>
                                                                    <AiFillStar size={10} color='orange' />
                                                                    <AiFillStar size={10} color='orange' />
                                                                    <AiFillStar size={10} color='orange' />
                                                                    <AiFillStar size={10} color='orange' />
                                                                    <AiFillStar size={10} color='orange' />
                                                                </HStack>
                                                                <Text fontSize={'xs'} color='gray.500'>({x.rating})</Text>
                                                            </HStack>
                                                            <Divider />
                                                            <Text fontSize={'xs'} color='gray.500'>Update : {moment(x.created_at).format('YYYY')}</Text>
                                                        </Stack>

                                                        <Spacer />

                                                        <Stack alignItems={"flex-end"} spacing={1} >
                                                            {x?.community?.length > 0 ? (
                                                                <AvatarGroup
                                                                    cursor="pointer"
                                                                    size="xs"
                                                                    max={3}
                                                                    spacing={-2}
                                                                >
                                                                    {x?.community?.map((user, i) => {

                                                                        return (
                                                                            <Tooltip key={i} label={user?.name} aria-label="Name">
                                                                                <Avatar size={'xs'} name={user?.name} src={user?.image || user?.name} />
                                                                            </Tooltip>
                                                                        );
                                                                    })}
                                                                </AvatarGroup>
                                                            ) : (
                                                                <AvatarGroup
                                                                    cursor="pointer"
                                                                    size="xs"
                                                                    max={3}
                                                                    spacing={-2}
                                                                >
                                                                    <Tooltip label={'Admin'} aria-label="Name">
                                                                        <Avatar size={'xs'} name={'Admin'} src={'https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/default%2FWhatsApp%20Image%202024-02-13%20at%2022.59.30.jpeg?alt=media&token=30790f44-f8c6-4ef4-b7ad-e97749c5d6d3'} />
                                                                    </Tooltip>
                                                                </AvatarGroup>
                                                            )}

                                                            <Divider />
                                                            <Text fontSize={"xs"} color="gray.500">Komunitas</Text>
                                                        </Stack>
                                                    </Stack>
                                                    <Stack p={3}>
                                                        <Button size='sm' bgColor='red.600' onClick={() => navigate(`/store/${x?._id}`, { state: x })} >
                                                            <Text color='white'>lihat product</Text>
                                                        </Button>
                                                    </Stack>
                                                </Stack>
                                            </Stack>
                                        )
                                    })
                                )}
                            </HStack>

                            <Stack alignItems={"center"} py={2} justifyContent="center" bgColor={'red.600'} h={"auto"} display={["none", "flex", "flex"]}>
                                <FiArrowRightCircle size={17} color='white' />
                            </Stack>
                        </Grid>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    )
}

export default RegisterHome