import {
	Box,
	Button,
	Center,
	HStack,
	Image,
	Input,
	InputGroup,
	InputLeftAddon,
	InputRightElement,
	Spacer,
	Spinner,
	Stack,
	Text,
	useToast,
} from '@chakra-ui/react'
import { sendPasswordResetEmail } from 'firebase/auth';
import { useRef, useState } from 'react';
import { MdEmail, MdVisibility, MdVisibilityOff, MdVpnKey } from 'react-icons/md';
import logo from "../../Assets/Image/logo2.png";

import { useNavigate, useSearchParams } from 'react-router-dom';
import { auth } from '../../Config/firebase';
import { UseAuthStore } from '../../Hooks/Zustand/store';
import axiosInstance from '../../Apis/axiosWithNoBarier';
// import { OAuthButtonGroup } from './OAuthButtonGroup'
// import { PasswordField } from './PasswordField'

function ForgotPasswordPage() {

	const [email, setEmail] = useState("");
	const [loadingConfirm, setLoadingconfirm] = useState(false)
	const [dataPassword, setDataPassword] = useState({
		password: "",
		confirm_password: ""
	})

	const [loading, setLoading] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const navigate = useNavigate();

	const tokenRef = useRef("")

	const globalState = UseAuthStore();

	let [searchParams] = useSearchParams()

	const token = searchParams.get("token")
	tokenRef.current = token

	if (globalState?.uid) {
		navigate("/");
	}

	const toast = useToast();

	const sendPasswordReset = (email) => {
		const data = {
			email
		}
		try {
			const res = axiosInstance.post('/auth/forgot-password', data)
			return res
		} catch (error) {
			throw error
		}
	}

	const handleUpdatePassword = () => {
		setLoading(true);
		if (email === null || email === "") {
			toast({
				title: "Error",
				description: "Input email",
				status: "error",
				duration: 3000,
				isClosable: true,
				position: 'top'

			});
		} else {
			sendPasswordReset(email)
				.then(() => {
					toast({
						title: "Success",
						description:
							"Reset password request has been sent, kindly check your email!",
						status: "success",
						duration: 3000,
						isClosable: true,
						position: 'top'

					});
					setLoading(false);
					navigate("/login");
				})
				.catch((error) => {
					toast({
						title: error.code,
						description: error.message,
						status: "error",
						duration: 9000,
						isClosable: true,
						position: 'top'

					});
					setLoading(false);
				});
		}
	};

	const handleAddData = (e) => {
		const { id, value } = e.target
		setDataPassword({ ...dataPassword, [id]: value })
	}

	const handleSend = async () => {
		setLoadingconfirm(true)

		if (dataPassword.password !== dataPassword.confirm_password) {
			return toast({
				title: "Warning",
				description:
					"your password and confirm password is not same !",
				status: "success",
				duration: 3000,
				isClosable: true,
				position: 'top'

			});
		}

		if (tokenRef.current === "") {
			return toast({
				title: "Warning",
				description:
					"Please, check your email to confirm reset password",
				status: "success",
				duration: 3000,
				isClosable: true,
				position: 'top'

			});
		}


		try {
			await axiosInstance.post(`/auth/reset-password?token=${tokenRef.current}`, dataPassword)
			toast({
				title: "Success",
				description:
					"Success reset password!",
				status: "success",
				duration: 3000,
				isClosable: true,
				position: 'top'

			});
		} catch (error) {
			toast({
				title: "Error",
				description:
					error.message,
				status: "error",
				duration: 3000,
				isClosable: true,
				position: 'top'

			});
		} finally {
			setLoadingconfirm(false)

		}
	}


	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};



	const height = window.innerHeight;
	const width = window.innerWidth;

	return (
		<>
			<Stack
				spacing={10}
				pt={20}
				minH={height}
				bgColor='red.500'
				bgSize="cover"
				alignItems={"center"}
				justifyContent="center"
			>
				<Stack>
					<Stack alignItems={"center"} justifyContent="center">
						<Stack
							justifyContent="center"
							alignItems="center"
							spacing={5}
							pb={10}
							bg={'white'}
							p={10}
							borderRadius="xl"
						>
							<Box>
								<Image
									w="200px"
									borderRadius={20}
									src={logo}
									alt="Alternate Text"
								/>
							</Box>
							<Spacer />
							{tokenRef.current === "" || tokenRef.current === null ? (
								<Stack>
									<Stack alignItems="center">
										<InputGroup
											w={{
												base: "100%",
												md: "285",
											}}
										>
											<InputLeftAddon
												shadow={"md"}
												children={<MdEmail name="email" size={24} color="black" />}
											/>
											<Input
												w={{
													base: "100%",
													md: "100%",
												}}
												placeholder="email"
												fontSize={"md"}
												shadow={3}
												bgColor={"white"}
												color={"black"}
												id='email'
												onChange={
													(e) => setEmail(e.target.value)
												}
											/>
										</InputGroup>
									</Stack>


									<Stack pt={5}>
										{loading ? (
											<Spinner size={"sm"} />
										) : (
											<HStack>
												<Button
													fontFamily={'heading'}
													mt={8}
													w={'full'}
													bgGradient="linear(to-r, red.400,pink.400)"
													color={'white'}
													_hover={{
														bgGradient: 'linear(to-r, red.400,pink.400)',
														boxShadow: 'xl',
													}}
													onClick={() => navigate("/login")}
												>
													<Text color={"white"} fontSize="xs" fontWeight="bold">
														KEMBALI
													</Text>
												</Button>
												<Button
													fontFamily={'heading'}
													mt={8}
													w={'full'}
													bgGradient="linear(to-r, red.400,pink.400)"
													color={'white'}
													_hover={{
														bgGradient: 'linear(to-r, red.400,pink.400)',
														boxShadow: 'xl',
													}}
													onClick={() => handleUpdatePassword()}
													onKeyDown={(e) => {
														if (e.key === "Enter") {
															handleUpdatePassword();
														}
													}}
												>
													<Text color={"white"} fontSize="xs" fontWeight="bold">
														KIRIM RESET
													</Text>
												</Button>
											</HStack>
										)}
									</Stack>
								</Stack>
							) : (
								<Stack>
									<Stack alignItems="center">
										<InputGroup
											w={{
												base: "92%",
												md: "92%",
											}}
										>
											<InputLeftAddon
												shadow={"md"}
												children={<MdVpnKey size={24} color="black" />}
											/>
											<Input
												w={{
													base: "100%",
													md: "100%",
												}}
												placeholder="password"
												fontSize={"md"}
												shadow={"md"}
												type={showPassword ? "text" : "password"}
												bgColor={"white"}
												color={"black"}
												id='password'
												onChange={(e) => handleAddData(e)}
												onKeyDown={(e) => {
													if (e.key === "Enter") {
														handleSend();
													}
												}}
											/>
											<InputRightElement>
												{showPassword ? (
													<MdVisibilityOff
														size={20}
														color="black"
														onClick={togglePasswordVisibility}
														style={{ cursor: "pointer" }}
													/>
												) : (
													<MdVisibility
														size={20}
														color="black"
														onClick={togglePasswordVisibility}
														style={{ cursor: "pointer" }}
													/>
												)}
											</InputRightElement>



										</InputGroup>
									</Stack>

									<Stack alignItems="center">
										<InputGroup
											w={{
												base: "92%",
												md: "92%",
											}}
										>
											<InputLeftAddon
												shadow={"md"}
												children={<MdVpnKey size={24} color="black" />}
											/>
											<Input
												w={{
													base: "100%",
													md: "100%",
												}}
												placeholder="Confirm password"
												fontSize={"md"}
												shadow={"md"}
												type={showPassword ? "text" : "password"}
												bgColor={"white"}
												color={"black"}
												id='confirm_password'
												onChange={(e) => handleAddData(e)}
												onKeyDown={(e) => {
													if (e.key === "Enter") {
														handleSend();
													}
												}}
											/>
											<InputRightElement>
												{showPassword ? (
													<MdVisibilityOff
														size={20}
														color="black"
														onClick={togglePasswordVisibility}
														style={{ cursor: "pointer" }}
													/>
												) : (
													<MdVisibility
														size={20}
														color="black"
														onClick={togglePasswordVisibility}
														style={{ cursor: "pointer" }}
													/>
												)}
											</InputRightElement>
										</InputGroup>
									</Stack>
									<Center pt={5}>
										<Button
											isLoading={loadingConfirm}
											alignItems={"center"}
											justifyContent="center"
											size={"sm"}
											bgColor={"white"}
											onClick={() => handleSend()}
											onKeyDown={(e) => {
												if (e.key === "Enter") {
													handleSend();
												}
											}}
										>
											<Text color={"black"} fontSize="xs" fontWeight="bold">
												CONFIRM
											</Text>
										</Button>
									</Center>
								</Stack>
							)}




						</Stack>
					</Stack>
					<Spacer />

				</Stack>
			</Stack>
		</>
	)
}






export default ForgotPasswordPage