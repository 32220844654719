import { Button, HStack, Input, Stack, Text, useToast } from '@chakra-ui/react'
import React, { useState } from 'react'
import { BiSearchAlt2 } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom';
import { UseAuthStore } from '../../Hooks/Zustand/store';

function AppSearchInput() {

    const [search, setSearch] = useState('');
    const navigate = useNavigate();
    const toast = useToast();

    const globalState = UseAuthStore();

    const [loading, setLoading] = useState(false)




    const handleKeyDown = async (event) => {

        if (event.key === 'Enter') {
            setLoading(true)

            if (search === undefined || search === "") {
                return toast({
                    title: 'DapetKomisi',
                    description: 'Please type in your search again',
                    status: 'warning',
                    position:'top'
                });
            }

            navigate(`/search?detail=${search}`, { state: search });
            setLoading(false)

        }
    };

    const handleSearch = async () => {
        setLoading(true)
        if (search === undefined || search === "") {
            setLoading(false)

            return toast({
                title: 'DapetKomisi',
                description: 'Please type in your search again',
                status: 'warning',
                position:'top'
            });
        }
        navigate(`/search?detail=${search}`, { state: search });
        setLoading(false)
    }




    return (
        <Stack flexDirection={['column', 'row', 'row']} w={['auto', 'auto', '80%']}>
            <Input
                borderRadius='2xl'
                bgColor={'white'}
                size={'sm'}
                placeholder='Cari disini produkmu...'
                onChange={(e) => setSearch(e.target.value)}
                onKeyDown={handleKeyDown}
            />
            <Button isLoading={loading} onClick={handleSearch} bgColor={'black'} size={'sm'} borderRadius='2xl' color={'white'} w={['auto', 'auto', '200px']}>
                <HStack alignItems={'center'} justifyContent='center'>
                    <BiSearchAlt2 size={10} />
                    <Text fontSize={'xs'}>Cari</Text>
                </HStack>
            </Button>
        </Stack>
    )
}

export default AppSearchInput