import { CheckIcon, PhoneIcon } from '@chakra-ui/icons'
import { Button, Divider, Heading, HStack, Input, InputGroup, InputLeftElement, InputRightElement, SimpleGrid, Stack, Text } from '@chakra-ui/react'
import React from 'react'
import CopyToClipboardUtil from '../../Utils/CopyToClipboardUtil'

function PromoPage() {

  const promoDummy = [
    { name: "Diskon 12.12", promo_code: "haloworld", description: "diskon 12.12 dapatkan gratis ongkir 10%" },
    { name: "Diskon Gratis Ongkir", promo_code: "haloworld2", description: "diskon  gratis ongkir 50%" },
  ]
  return (
    <Stack spacing={5} p={[1, 1, 5]}>
      <Heading size={'md'}>List Promo</Heading>
      <Stack>
        {promoDummy?.length > 0 && (
          <SimpleGrid columns={[1, 1, 2]} gap={5}>
            {promoDummy?.map((x, index) => {
              return (
                <Stack key={index} p={3} borderRadius="md" shadow={"md"} bgColor="red.600">
                  <Heading size={"lg"} color="white">{x.name}</Heading>
                  <Divider />
                  <Text color={"white"}>{x.description}</Text>

                  <InputGroup bgColor={"white"}>
                    <InputLeftElement
                      pointerEvents='none'
                      color='gray.300'
                      fontSize='1.2em'
                      children='$'
                    >
                    </InputLeftElement>
                    <Input bgColor={"white"} fontWeight={500} value={x.promo_code} />
                    <InputRightElement w={"100px"}>
                      <CopyToClipboardUtil text={x.promo_code} />
                    </InputRightElement>
                  </InputGroup>

                </Stack>
              )
            })}

          </SimpleGrid>
        )}

      </Stack>

    </Stack>
  )
}

export default PromoPage