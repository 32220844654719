import { Box, Flex, Grid, Heading, SimpleGrid, Spinner, Stack, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { FcLock } from 'react-icons/fc';
import { useNavigate } from 'react-router-dom';
import axiosInstanceBarier from '../../Apis/axiosWithBarier';
import AppImageSlide from '../../Components/Carousel/AppImageSlide';
import { UseAuthStore } from "../../Hooks/Zustand/store";



function CategoryPage() {


    const [product, setProduct] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);

    const [categoryType, setCategoryType] = useState("")

    const [categoryData, setCategoryData] = useState([])

    const globalState = UseAuthStore();

    const navigate = useNavigate()


    const getDataCategory = async () => {
        try {
            const res = await axiosInstanceBarier.get(`/category?type=${categoryType}`)
            if (res.data.length > 0) {
                setCategoryData(res?.data)
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getDataCategory()

        return () => {
            setProduct([])
        }
    }, [categoryType])



    const imageCarouselDashboard = [
        { data: "xxxx", image: "https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/banner_web%2FScreen%20Shot%202024-02-10%20at%2019.22.49.png?alt=media&token=d0c7dd26-538c-4777-848d-4e0332827d2f" },
        { data: "xxxx", image: "https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/banner_web%2FScreen%20Shot%202024-02-10%20at%2019.22.58.png?alt=media&token=03927b1c-9108-4e2b-9773-6bf3161e1767" },
        { data: "xxxx", image: "https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/banner_web%2FScreen%20Shot%202024-02-10%20at%2019.23.11.png?alt=media&token=48f1c0c2-b3f3-4192-85c5-358d877bf6ce" },
        { data: "xxxx", image: "https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/banner_web%2FScreen%20Shot%202024-02-10%20at%2019.23.19.png?alt=media&token=fd1917d2-3b5b-4888-b821-4ca081a4636f" },
        { data: "xxxx", image: "https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/banner_web%2FScreen%20Shot%202024-02-10%20at%2019.23.26.png?alt=media&token=d15b7be8-e713-4b83-a857-0238fcf61c79" },
    ]


    const getProduct = async () => {
        globalState.setIsLoading(true)
        try {
            const res = await axiosInstanceBarier.get(`/product?page=${currentPage}`);
            if (res?.data?.length > 0) {
                setProduct((prevProduct) => [...prevProduct, ...res.data]);
                setCurrentPage((prevPage) => prevPage + 1);
            } else {
                setHasMore(false); // Tidak ada lagi produk untuk dimuat
            }
        } catch (error) {
            console.log(error, ' ini error')
        }
        finally {
            globalState.setIsLoading(false)
        }
    }

    useEffect(() => {
        getProduct()

        return () => {
        }
    }, [])


    const imageList = [
        { title: "netflix", image: "https://img.alicdn.com/tfs/TB1eajwCIbpK1RjSZFyXXX_qFXa-130-130.png" },
        { title: "men", image: "https://down-id.img.susercontent.com/file/04dba508f1ad19629518defb94999ef9_tn" },
        { title: "underware", image: "https://img.alicdn.com/tfs/TB1qpmXDcbpK1RjSZFyXXX_qFXa-130-130.png" },
        { title: "women", image: "https://down-id.img.susercontent.com/file/6d63cca7351ba54a2e21c6be1721fa3a_tn" },
        { title: "accessories", image: "https://img.alicdn.com/tfs/TB18dUUrhTpK1RjSZFKXXa2wXXa-130-130.jpg" },
        { title: "shoes", image: "https://down-id.img.susercontent.com/file/3c8ff51aab1692a80c5883972a679168_tn" },
        { title: "e-book", image: "https://img.alicdn.com/imgextra/i4/O1CN01sU4ZnR1YD2NfD7IoO_!!6000000003024-0-tps-240-240.jpg" },
        { title: "course", image: "https://img.alicdn.com/tfs/TB15jDzCFzqK1RjSZSgXXcpAVXa-130-130.png" },
        { title: "herbal", image: "https://img.alicdn.com/imgextra/i4/O1CN01S7pEZ225FDfDmiTw8_!!6000000007496-0-tps-240-240.jpg" },
        { title: "buku", image: "https://img.alicdn.com/imgextra/i2/O1CN01JZdJ8C1FPspVlNzlS_!!6000000000480-2-tps-240-240.png" },
        { title: "kuliner", image: "https://img.alicdn.com/tfs/TB1MyLRh4naK1RjSZFtXXbC2VXa-130-130.jpg" },
    ]



    return (
        <Stack>
            <Stack spacing={5}>
                <Box bgColor={'red.600'} w='full' h={'400px'}>
                    <SimpleGrid columns={[1, 1, 2]}>
                        <Stack alignItems={'center'} justifyContent='center' h={'400px'} color='white' p={5}>
                            <Text fontSize={'4xl'} fontWeight='bold' textAlign={'start'} w='300px'>Kamu bisa dapatin komisi</Text>
                            <Text fontSize={'lg'} fontWeight={500} textAlign={'start'} w='300px'>Dengan bergabung bersama kami</Text>
                            <Stack p={1} w='300px' bgColor='black' borderRadius={'md'}>
                                <Text fontSize={'sm'} fontWeight={500} textAlign='center'>Daftar</Text>
                            </Stack>
                        </Stack>

                        <Stack></Stack>


                    </SimpleGrid>

                </Box>

                <Stack p={[1, 5, 5]} spacing={8}>

                    <SimpleGrid columns={[1, 1, 2]} gap={3}>
                        <Stack color={categoryType === "digital" ? "red.100" : "white"}
                            width={['auto', 'full']}
                            backgroundImage="url('https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/Black%20White%20Modern%20Photography%20Tips%20YouTube%20Thumbnail.png?alt=media&token=7780fdee-5d7d-4698-8322-a03370ea98df')"
                            backgroundSize="cover"
                            backgroundPosition="center"
                            h="200px" cursor={"pointer"} onClick={() => setCategoryType("digital")} p={5} borderRadius="md">
                            <Heading >Digital</Heading>
                        </Stack>
                        <Stack color={categoryType === "non-digital" ? "red.100" : "white"}
                            width={['auto', 'full']}
                            backgroundImage="url('https://firebasestorage.googleapis.com/v0/b/komisi-f35b7.appspot.com/o/Promotion%20Fashion%20Boutique%20Instagram%20Post.png?alt=media&token=109b7906-95a7-4865-b07c-851aabceec69')"
                            backgroundSize="cover"
                            backgroundPosition="center"
                            h="200px" cursor={"pointer"} onClick={() => setCategoryType("non-digital")} p={3} borderRadius="md">
                            <Heading >Non-digital</Heading>
                        </Stack>
                    </SimpleGrid>


                    <Grid templateColumns={{ base: '1fr', md: '1fr' }} gap={5}>
                        <Stack overflowX="scroll" width="100%" p={2} alignItems='center'>
                            <Flex flexDirection="row" flexWrap="wrap" gap={3} >
                                {categoryData.length > 0 &&
                                    categoryData.map((x, index) => {
                                        // Cari gambar yang sesuai berdasarkan judul (title)
                                        const foundImage = imageList.find(item => item.title.toLowerCase() === x.name.toLowerCase());

                                        return (
                                            <Stack
                                                key={index}
                                                borderRadius={'md'}
                                                p={2}
                                                borderWidth={0.5}
                                                borderColor='gray.300'
                                                bgColor={'white'}
                                                w='80px'
                                                h={'auto'}
                                                shadow='md'
                                                alignItems={"center"}
                                                justifyContent='flex-start'
                                                mr={2}
                                                cursor='pointer'
                                                mb={2}
                                                onClick={() => navigate(`/category/${x.name}`, { state: x })}
                                            >
                                                {foundImage ? (
                                                    <Box
                                                        as="img"
                                                        src={foundImage.image}
                                                        alt={x.name}
                                                        width={50}
                                                        height={50}
                                                    />
                                                ) : (
                                                    <FcLock size={50} />
                                                )}
                                                <Text fontSize={'xx-small'} textAlign='center' fontWeight={500} textTransform='capitalize'>{x.name}</Text>
                                            </Stack>
                                        );
                                    })}
                            </Flex>
                        </Stack>
                    </Grid>

                    {/* <HStack alignItems={'center'} maxW='100vw' overflowX={'auto'} justifyContent='space-between' overflow={'auto'}>
                        {categoryData.length > 0 && (
                            categoryData.map((x, index) => {
                                return (
                                    <Stack cursor={'pointer'} onClick={() => navigate(`${x.name}`, { state: x })} alignItems={'center'} justifyContent='center' px={5} key={index}>
                                        <Stack alignItems={'center'} justifyContent='center' bgColor={'gray.300'} w={'50px'} h='50px' borderRadius={'full'}>
                                            <FcLock size={30} />
                                        </Stack>
                                        <Text fontSize={'sm'} fontWeight={500} textTransform='capitalize' noOfLines={1}>{x.name}</Text>
                                    </Stack>
                                )
                            })
                        )}
                    </HStack> */}
                </Stack>

                <Stack p={5}>
                    {loading ? (
                        <Stack h='100%' w='100%' alignItems={'center'} justifyContent='center'>
                            <Spinner />
                        </Stack>
                    ) : (
                        imageCarouselDashboard && (
                            <Stack alignItems={'center'} justifyContent='center'>
                                <Stack w={'100%'} position='relative' >
                                    <AppImageSlide images={imageCarouselDashboard && imageCarouselDashboard} />
                                </Stack>

                            </Stack>
                        )
                    )}
                </Stack>

                {/* 
                <Stack py={10}>
                    <Text fontWeight={'bold'} fontSize='xl'> We've been use by</Text>
                    <HStack alignItems={'center'} justifyContent='space-between' overflow={'auto'} p={5}>
                        <Stack alignItems={'center'} justifyContent='center'>
                            <Box bgColor={'gray.300'} w={'80px'} h='80px' borderRadius={'full'}>
                            </Box>
                        </Stack>

                        <Stack alignItems={'center'} justifyContent='center'>
                            <Box bgColor={'gray.300'} w={'80px'} h='80px' borderRadius={'full'}>
                            </Box>
                        </Stack>

                        <Stack alignItems={'center'} justifyContent='center'>
                            <Box bgColor={'gray.300'} w={'80px'} h='80px' borderRadius={'full'}>
                            </Box>
                        </Stack>

                        <Stack alignItems={'center'} justifyContent='center'>
                            <Box bgColor={'gray.300'} w={'80px'} h='80px' borderRadius={'full'}>
                            </Box>
                        </Stack>

                    </HStack>
                </Stack> */}
            </Stack>
        </Stack>
    )
}

export default CategoryPage