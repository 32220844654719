import React from "react";
import ProfilePage from "../Pages/Profile/ProfilePage";

const ProfileRouter = [
  {
    path: "/profile",
    element: (
      <ProfilePage />
    ),
  },
];

export default ProfileRouter;
